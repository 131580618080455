import {ActionType} from "../types";

import SelectedProduct from "../../model/SelectedProduct";

export interface Consultation {
	date: Date;
	time: string;
}

export interface CartState {
	selectedProducts: SelectedProduct[];
	makingOrder: boolean;
	consultation: Consultation | null;
	lastSuccessSubscription: {
		selectedProducts: SelectedProduct[];
		subscriptionIds: number[];
		consultation: Consultation | null;
	};
}

const getInitialCart = () => {
	const cart = localStorage.getItem("cartProducts");

	if (cart) {
		try{
			const result = JSON.parse(cart);
			if(result instanceof Array){
				return result;
			}
		}catch(e){
			return [];
		}
	}

	return [];
};

const initialState: CartState = {
	selectedProducts: getInitialCart(),
	makingOrder: false,
	consultation: null,
	lastSuccessSubscription: {
		selectedProducts: [],
		subscriptionIds: [],
		consultation: null,
	}
};

export const getCartRequest = (state: CartState) => ({
	...state
});

export const getCartSuccess = (state: CartState, {payload}: ActionType) => ({
	...state,
	selectedProducts: payload
});

export const getCartFailure = (state: CartState) => ({
	...state
});

export const saveCartRequest = (state: CartState) => ({
	...state
});

export const saveCartSuccess = (state: CartState, {payload}: ActionType) => {
	localStorage.setItem("cartProducts", JSON.stringify(payload));
	return {
		...state,
		selectedProducts: payload
	};
};

export const saveCartFailure = (state: CartState) => ({
	...state
});

export const makeSubscriptionRequest = (state: CartState) => ({
	...state,
	makingOrder: true
});

export const makeSubscriptionSuccess = (state: CartState, {payload}: ActionType) => ({
	...state,
	makingOrder: false,
	selectedProducts: [],
	consultation: null,
	lastSuccessSubscription: {
		selectedProducts: [...state.selectedProducts],
		subscriptionIds: payload,
		consultation: Object.assign({}, state.consultation),
	},
});

export const makeSubscriptionFailure = (state: CartState) => ({
	...state,
	makingOrder: false
});

export const changeProductDosage = (state: CartState, {payload}: ActionType) => {
	const newProducts = [...state.selectedProducts];
	const {product, dosageId} = payload;
	const productIndex = newProducts.findIndex((item) => item.product.id === product.id);
	const dosage = newProducts[productIndex].product.quantities.find((dos) => dos.id === dosageId);

	if (dosage) {
		newProducts[productIndex].quantity = dosage;
	}

	return {
		...state,
		selectedProducts: [...newProducts]
	};
};

export const addProductRequest = (state: CartState) => ({
	...state
});

export const addProductSuccess = (state: CartState, {payload}: ActionType) => {
	const newProducts = [...state.selectedProducts];
	newProducts.push(payload);
	localStorage.setItem("cartProducts", JSON.stringify(newProducts));

	return {
		...state,
		selectedProducts: [...newProducts]
	};
};

export const addProductFailure = (state: CartState) => ({
	...state
});

export const deleteProductRequest = (state: CartState) => ({
	...state
});

export const deleteProductSuccess = (state: CartState, {payload}: ActionType) => {
	const index = state.selectedProducts.findIndex((item) => item.product.id === payload.product.id);
	const newSelectedProducts = [...state.selectedProducts];

	if (index !== -1) {
		newSelectedProducts.splice(index, 1);
		localStorage.setItem("cartProducts", JSON.stringify(newSelectedProducts));
	}

	return {
		...state,
		selectedProducts: [...newSelectedProducts]
	};
};

export const deleteProductFailure = (state: CartState) => ({
	...state
});

export const updateProductRequest = (state: CartState) => ({
	...state
});

export const updateProductSuccess = (state: CartState, {payload}: ActionType) => {
	const index = state.selectedProducts.findIndex((item) => item.product.id === payload.product.id);
	const newSelectedProducts = [...state.selectedProducts];

	if (index !== -1) {
		newSelectedProducts[index] = payload;
		localStorage.setItem("cartProducts", JSON.stringify(newSelectedProducts));
	}

	return {
		...state,
		selectedProducts: [...newSelectedProducts]
	};
};

export const updateProductFailure = (state: CartState) => ({
	...state
});

export const addConsultationDateRequest = (state: CartState, {payload}: ActionType) => ({
	...state,
	consultation: payload,
});

export const eraseCart = (state: CartState) => ({
	...state,
	selectedProducts: [],
	consultation: null,
});

export default initialState;
