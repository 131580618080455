import {handleActions, createActions} from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
	GET_PRODUCTS_REQUEST: undefined,
	GET_PRODUCTS_SUCCESS: undefined,
	GET_PRODUCTS_FAILURE: undefined,

	CHANGE_PRODUCT_QUANTITY: undefined
});

const reducer = handleActions(
	{
		[`${actions.getProductsRequest}`]: handlers.getProductsRequest,
		[`${actions.getProductsSuccess}`]: handlers.getProductsSuccess,
		[`${actions.getProductsFailure}`]: handlers.getProductsFailure,

		[`${actions.changeProductQuantity}`]: handlers.changeProductQuantity
	},
	initialState
);
export default reducer;
