import React, {useState, ChangeEvent} from "react";

import {setPackagingOrder} from "../../../../http/orders";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import PopUp from "../../../PopUp";
import Order from "../../../../model/Order";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";

import "./styles.scss";

interface ApproveOrderPopUpProps {
	order: Order;
	onClose: () => void;
	notify: (msg: string) => void;
	loadOrders: () => void;
}

const ApproveOrderPopUp = ({order, onClose, notify, loadOrders}: ApproveOrderPopUpProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [rxNumber, setRxNumber] = useState<string>("");
	const [amount, setAmount] = useState<string>("");
	const [validationMessage, setValidationMessage] = useState<string>("");

	const handleChangeRxNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const rxNumber = ValidationService.numberConverter(e.target.value);

		setRxNumber(rxNumber);
	};

	const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
		const amount = e.target.value;
		if (/^[0-9]*\.?[0-9]*$/.test(amount)) {
			setAmount(amount);
		}
	};

	const handleSubmit = async () => {
		if (!rxNumber) {
			setValidationMessage(MessageConst.validation.rxNumberError);

			return;
		}

		if (!!order.user?.insuranceDocument && !amount) {
			setValidationMessage(MessageConst.validation.coPayAmountError);

			return;
		}

		const requestObject = {
			text: rxNumber,
			amount: order.cartPrice
		};

		if (amount) {
			requestObject.amount = +(+amount * 100).toFixed(0);
		}

		try {
			setLoading(true);
			const result = await setPackagingOrder(order.id, requestObject);
			const status = result.data;

			if (status === "OK") {
				notify("Order has been successfuly approved");
			} else if (status === "PAUSED") {
				notify("Payment information is not valid. The order has been paused");
			}
		} catch (err) {
			if ([400, 401, 403].includes(err.response.status)) {
				const res = err.response.data;
				notify(res.message);
			} else {
				notify(MessageConst.error.unknownError);
			}
		} finally {
			onClose();
			loadOrders();
		}
	};

	return (
		<PopUp>
			{loading && <LoaderComponent showShadow />}
			<div className="approve-order-wrapper">
				<div className="title">{`Fill the fields for order #${
					order.number
				}-${order.subNumber.toString().padStart(2, "0")}`}</div>
				<CustomInput label="Rx number" value={rxNumber} onChange={handleChangeRxNumber} />
				{!!order.user?.insuranceDocument && (
					<CustomInput label="Co-Pay amount" value={amount} onChange={handleChangeAmount} />
				)}
				{validationMessage && <div className="validation-error">{validationMessage}</div>}
				<div className="action-buttons">
					<button onClick={onClose}>Cancel</button>
					<button onClick={handleSubmit}>Submit</button>
				</div>
			</div>
		</PopUp>
	);
};

export default ApproveOrderPopUp;
