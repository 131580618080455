import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useGoogleLogout } from "react-google-login";
import { actions as authActions } from "../../redux/auth";
import { actions as appActions } from "../../redux/app";
import { StateType } from "../../redux/types";
import shopping from "../../static/images/shopping-icon.png";
import account from "../../static/images/account-icon.png";
import logo_small from "../../static/images/winston-logo-small.png";
import { SidebarMode } from "../Sidebar";
import "./styles.scss";
import { Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";

interface HeaderProps {
	cartNumber?: number;
}

const Header = ({ cartNumber }: HeaderProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const mobileMenuRef = useRef<HTMLDivElement>(null);
	const [isMobileMenuShown, setIsMobileMenuShown] = useState<boolean>(false);
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const { infoStrip, profile } = useSelector(({ app, auth }: StateType) => ({
		infoStrip: app.infoStrip,
		profile: auth.profile
	}));



	useEffect(() => {
		const { pathname } = location;

		const calcIsMenuChanged = () => {
			if (pathname === "/") {
				if (window.innerWidth > 960) {
					return window.scrollY > window.innerHeight - 70;
				} else if (window.innerWidth > 842) {
					return window.scrollY > 460;
				} else if (window.innerWidth > 700) {
					return window.scrollY > 545;
				} else {
					return window.scrollY > 480;
				}
			}
			if (pathname.includes("shop")) {
				if (window.innerWidth > 1237) {
					return window.scrollY > 152;
				} else if (window.innerWidth > 1177) {
					return window.scrollY > 182;
				} else if (window.innerWidth > 749) {
					return window.scrollY > 230;
				} else {
					return window.scrollY > 466;
				}
			}
			if (pathname === "/learn") {
				if (window.innerWidth > 767) {
					return window.scrollY > 490;
				} else if (window.innerWidth > 587) {
					return window.scrollY > 430;
				} else {
					return window.scrollY > 320;
				}
			}
		};

		const handleScroll = () => {
			if (calcIsMenuChanged()) {
				if (!isChanged) {
					setIsChanged(true);
				}
			} else if (isChanged) {
				setIsChanged(false);
			}
			if (isMobileMenuShown) {
				setIsMobileMenuShown(false);
			}
		};

		if (pathname === "/" || pathname.includes("shop") || pathname === "/learn") {
			document.addEventListener("scroll", handleScroll);

			return () => {
				document.removeEventListener("scroll", handleScroll);
			};
		} else {
			setIsChanged(true);
		}
	}, [isChanged, location, isMobileMenuShown]);

	useEffect(() => {
		const { pathname } = location;

		if (pathname.includes("shop") && window.scrollY === 0) {
			setIsChanged(false);
		}

		if (pathname === "/" || pathname === "/learn") {
			setIsChanged(false);
		}
	}, [location]);

	useEffect(() => {
		document.addEventListener("click", handleDocumentClick);

		return () => {
			document.removeEventListener("click", handleDocumentClick);
		};
	}, []);



	const showSidebarHandler = (mode: SidebarMode) => () => {
		const { pathname } = location;

		setIsMobileMenuShown(false);
		if (!profile && mode === "LOGIN") {
			const y = window.scrollY;

			dispatch(appActions.setCloseSidebarDirection({ pathname, y }));

			history.push("/login");
		}
		if (mode === "CART") {
			const y = window.scrollY;

			dispatch(appActions.setCloseSidebarDirection({ pathname, y }));

			history.push("/cart");
		}
	};

	const handleDocumentClick = (e: Event) => {
		if (
			mobileMenuRef &&
			mobileMenuRef.current &&
			!mobileMenuRef.current.contains(e.target as Node)
		) {
			setIsMobileMenuShown(true);
		}
	};


	const onLogoutSuccess = () => {
		console.log('Logged out Success');		
	};

	const onFailure = () => {
		console.log('Handle failure cases');
	};

	const { signOut } = useGoogleLogout({
		clientId:process.env.REACT_APP_CLIENT_ID!,
		onLogoutSuccess,
		onFailure,
	});

	const handleLogoutClick = () => {
		signOut();
		dispatch(authActions.logout(history));
	};

	const [isActive, setActive] = useState(false);

	const toggleClass = () => {
		setActive(!isActive);
	};

	return (
		<>
			<div
				className={
					"header-component" + (isChanged ? " changed" : "") + (infoStrip ? " extra-height" : "")
				}
				id="header"
			>

				<Navbar expand="lg" className="navbar-3 w-nav">
					<div className="container-9 w-container">
						<Navbar.Brand className="link-block-7 w-inline-block"><Link to="/" onClick={() => setIsMobileMenuShown(false)}>
							<img alt="Winston The Premier Men's Health Virtual Clinic" src={logo_small} width="151" className="image-4" /></Link></Navbar.Brand>

						<Navbar.Toggle className="menu-button w-nav-button" onClick={() => setIsMobileMenuShown(!isMobileMenuShown)}>
							<div className="icon w-icon-nav-menu" onClick={toggleClass}></div>
						</Navbar.Toggle>
						<div id="basic-navbar-nav" >
							{/* <Navbar.Collapse id="basic-navbar-nav" >							 */}
							{isMobileMenuShown ?
								<Nav className="nav-menu-2 w-nav-menu mr-auto">
									<div className="res-menu">

										<NavDropdown title="Shop" id="basic-nav-dropdown">
											<Link className="dropdown-link w-dropdown-link" to="/erectile-dysfunction" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Erectile Dysfunction</strong>
											</Link>

											<Link className="dropdown-link w-dropdown-link" to="/hair-loss" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Hair Loss</strong>
											</Link>

											<Link className="dropdown-link w-dropdown-link" to="/cold-sores" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Cold Sores</strong>
											</Link>


											<Link className="dropdown-link w-dropdown-link" to="/acne" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Acne</strong>
											</Link>

											<Link className="dropdown-link w-dropdown-link" to="/allergies" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Allergies</strong>
											</Link>

											<Link className="dropdown-link-8 w-dropdown-link" to="/transfer-rx" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Transfer Prescription</strong>
											</Link>
										</NavDropdown>

										<NavDropdown title="Who We Are" id="basic-nav-dropdown">
											<Link className="dropdown-link w-dropdown-link" to="/about-us" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">About Us</strong>
											</Link>


											<Link className="dropdown-link w-dropdown-link" to="/why-winston" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Why Winston</strong>
											</Link>


											<Link className="dropdown-link-9 w-dropdown-link" to="/contact-us" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Contact Us</strong>
											</Link>


										</NavDropdown>

										<NavDropdown title="What We Do" id="basic-nav-dropdown">

											{/* <Link className="dropdown-link w-dropdown-link" to="/about-us" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">About Us</strong>
											</Link>


											<Link className="dropdown-link w-dropdown-link" to="/why-winston" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Why Winston</strong>
											</Link> */}


											<a className="dropdown-link-9 w-dropdown-link" href="https://learn.askwinston.ca" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">Learn</strong>
											</a>


											<Link className="dropdown-link-9 w-dropdown-link" to="/faq" onClick={() => setIsMobileMenuShown(false)}>
												<strong className="bold-text-5">FAQ&#x27;s</strong>
											</Link>

										</NavDropdown>


										<Link className="link-block-9 w-inline-block" to="/cart" onClick={() => setIsMobileMenuShown(false)}><img src={shopping} width="22" alt="Winston The Premier Men's Health Virtual Clinic" />{!!cartNumber && <strong>{cartNumber}</strong>}</Link>

										{!profile ? (
											<span onClick={showSidebarHandler("LOGIN")} className="link-block-10 w-inline-block">
												<strong>Login</strong>
											</span>
										) : (
											<>
												<Link to="/profile" onClick={() => setIsMobileMenuShown(false)}>
													<span className="link-block-10 w-inline-block">
														<strong>Profile</strong>
													</span>
												</Link>
												<span onClick={handleLogoutClick} className="link-block-10 w-inline-block">
													<strong>Logout</strong>
												</span>
											</>
										)}

									</div>
								</Nav>
								: null}
							{/* </Navbar.Collapse>						 */}
						</div>

						<Navbar.Collapse id="basic-navbar-nav-2" >
							<Nav className="nav-menu-2 w-nav-menu mr-auto">
								<div className="res-menu">
									{!isMobileMenuShown ? (
										<NavDropdown title="Shop" id="basic-nav-dropdown" renderMenuOnMount={true} onMouseOver={() => { setCollapsed(true) }}>
											{collapsed && (
												<>
													<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/erectile-dysfunction" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Erectile Dysfunction</strong></Dropdown.Item>
													<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/hair-loss" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Hair Loss</strong></Dropdown.Item>
													<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/cold-sores" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Cold Sores</strong></Dropdown.Item>
													<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/acne" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Acne</strong></Dropdown.Item>
													<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/allergies" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Allergies</strong></Dropdown.Item>
													<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/transfer-rx" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Transfer Prescription</strong></Dropdown.Item>
												</>
											)}
										</NavDropdown>) : null}

									<NavDropdown title="Who We Are" id="basic-nav-dropdown" renderMenuOnMount={true} onMouseOver={() => { setCollapsed(true) }}>
										{collapsed && (
											<>
												<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/about-us" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">About Us</strong></Dropdown.Item>
												<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/why-winston" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Why Winston</strong></Dropdown.Item>
												<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/contact-us" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Contact Us</strong></Dropdown.Item>
											</>
										)}
									</NavDropdown>

									<NavDropdown title="What We Do" id="basic-nav-dropdown" renderMenuOnMount={true} onMouseOver={() => { setCollapsed(true) }}>
										{collapsed && (
											<>
												{/* <Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/about-us" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">About Us</strong></Dropdown.Item>
												<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/why-winston" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Why Winston</strong></Dropdown.Item> */}
												<Dropdown.Item tag="a" onClick={() => { setCollapsed(false) }} href="https://learn.askwinston.ca" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">Learn</strong></Dropdown.Item>
												<Dropdown.Item as={Link} onClick={() => { setCollapsed(false) }} to="/faq" className="dropdown-link w-dropdown-link"><strong className="bold-text-5">FAQ&#x27;s</strong></Dropdown.Item>
											</>
										)}
									</NavDropdown>


									<Link className="link-block-9 w-inline-block" to="/cart"><img src={shopping} width="22" alt="Winston The Premier Men's Health Virtual Clinic" />{!!cartNumber && <strong>{cartNumber}</strong>}</Link>


									{!profile ? (
										<span onClick={showSidebarHandler("LOGIN")} className="link-block-10 w-inline-block">
											<img src={account} width="22" alt="Winston The Premier Men's Health Virtual Clinic" />
										</span>
									) : (
										<>
											<Link className="link-block-10 w-inline-block" to="/profile">
												<strong>Profile</strong>
											</Link>
											<span onClick={handleLogoutClick} className="link-block-10 w-inline-block">
												<strong>Logout</strong>
											</span>
										</>
									)}

								</div>
							</Nav>
						</Navbar.Collapse>
					</div>
				</Navbar>
			</div>


		</>
	);
};

export default Header;
