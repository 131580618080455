import React, {
	ChangeEvent,
	Ref,
	useRef,
	useImperativeHandle,
	useState,
	KeyboardEvent,
	forwardRef,
	useEffect
} from "react";
import {  useGoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { actions as authActions } from "../../redux/auth";
import MessageConst from "../../const/Message.const";
import ValidationService from "../../service/Validation.service";
import logo from "../../static/images/winston-logo-small.png";
import google from "../../static/images/google.png";
import "./styles.scss";
import Signup from "./signup"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {actions as appActions} from "../../redux/app";


interface LoginProps {
	isLoginButtonShown: boolean;
	handleLoginModeClick: () => void;
	onRegisterModeClick: () => void;
	handleForgotModeClick: () => void;
	onAddedCallback?: () => void;
}

export interface LoginRefObject {
	doLogin: () => Promise<void>;
}

const Login = forwardRef((props: LoginProps, ref: Ref<LoginRefObject>) => {
	// const { isLoading } = useSelector(({ auth }: StateType) => ({
	// 	isLoading: auth.authLoading
	// }));
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname } = useLocation();
	const emailInput = useRef<HTMLInputElement>(null);
	const passwordInput = useRef<HTMLInputElement>(null);
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [validationMessage, setValidationMessage] = useState<string>("");
	// const [newvalidationMessage, setnewValidationMessage] = useState<string>("");
	const [isAgreed] = useState<boolean>(false);
	const isLogin = useRef<boolean>(false);

	useImperativeHandle(ref, () => ({
		doLogin,
		doRegister
	}));

	useEffect(() => {
		if(props.isLoginButtonShown){
			dispatch(appActions.setIsCheckout(false));
		}		
	},[])

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			doLogin();
			doRegister();
		}
	};

	// const handleNewEmail = (e: ChangeEvent<HTMLInputElement>) => {
	// 	const newEmail: string = e.target.value.trim();

	// 	setEmail(newEmail);

	// 	if (ValidationService.validateEmail(newEmail)) {
	// 		setValidationMessage("");
	// 	}
	// };
	// const handleNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
	// 	setPassword(e.target.value);

	// 	if (ValidationService.validatePassword(e.target.value)) {
	// 		setValidationMessage("");
	// 	}
	// };
	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const email = event.target.value.trim();
		setEmail(email);

	};

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setPassword(event.target.value);
	};
	const doRegister = async (): Promise<void> => {
		if (!ValidationService.validateEmail(email)) {
			if (emailInput.current) {
				emailInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.emailError);

			return;
		}

		if (!ValidationService.validatePassword(password)) {
			if (passwordInput.current) {
				passwordInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.passwordError);

			return;
		}
		if (!isAgreed) {
			setValidationMessage(MessageConst.validation.termsOfUseError);

			return;
		}
		let utmSource = window.sessionStorage.getItem("utm_source");
		dispatch(
			authActions.registerRequest({
				email,
				password,
				isAgreed,
				utmSource,
				setValidationMessage,
				history,
				isLoginMode: pathname === "/login"
			})
		)
	}
	const doLogin = async (): Promise<void> => {
		sessionStorage.setItem("emtyPopup", "open");				

		if (!ValidationService.validateEmail(email)) {
			if (emailInput.current) {
				emailInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.emailError);
			return;
		}

		if (!ValidationService.validatePassword(password)) {
			if (passwordInput.current) {
				passwordInput.current.focus();
			}

			setValidationMessage(MessageConst.validation.passwordError);
			return;
		}

		dispatch(
			authActions.loginRequest({
				email,
				password,
				setValidationMessage,
				history,
				isLoginMode: pathname === "/login"
			})
		);
	};

	const [isActive, setActive] = useState(false);

	const toggleClass = () => {
		setActive(!isActive);
	};
	// const handleIsAgreed = (event: ChangeEvent<HTMLInputElement>) => {
	// 	const newAgreedState: boolean = event.target.checked;

	// 	if (!newAgreedState) {
	// 		setValidationMessage(MessageConst.validation.termsOfUseError);
	// 	} else {
	// 		setValidationMessage("");
	// 	}

	// 	setIsAgreed(newAgreedState);
	// };
	const onSuccessSignup = (res: any) => {
		let tokenId = res.tokenId;
		let utmSource = window.sessionStorage.getItem("utm_source");
		if (isLogin.current == true) {
			sessionStorage.setItem("emtyPopup", "open");
			dispatch(
				authActions.loginRequest({
					email,
					password,
					setValidationMessage,
					history,
					isLoginMode: pathname === "/login",
					tokenId
				})
			);
		} else {
			sessionStorage.setItem("emtyPopup", "open");
			dispatch(
				authActions.registerRequest({
					email,
					password,
					isAgreed,
					utmSource,
					setValidationMessage,
					history,
					isLoginMode: pathname === "/login",
					tokenId
				})
			)
		}
		res.disconnect();
	}


	const onFailureSignup = (res: any) => {
		console.log('[Login Failed] res:', res);
	}


	const { signIn } = useGoogleLogin({
		onSuccess: onSuccessSignup,
		onFailure: onFailureSignup,
		clientId: process.env.REACT_APP_CLIENT_ID!,
		isSignedIn: true,
		accessType: 'offline'
	});

	// const handleGoogleSignUp = () => {
	// 	isLogin.current = false;
	// 	signIn();
	// }

	const handleGoogleSignIn = () => {
		isLogin.current = true;
		signIn();
	}

	return (
		<div className="login-component">

			<div id="login">

				<div className="navbar-3 w-nav" >
					<div className="container-17 w-container">
						<nav role="navigation" className={isActive ? 'nav-menu-2 w-nav-menu show' : 'nav-menu-2 w-nav-menu'}>
							<div className="res-menu">

								<div data-hover="1" data-delay="0" className="w-dropdown">
									<div className="shop-3 w-dropdown-toggle">
										<Link to="/contact-us" className="help-link">
											<div className="text-block-68">Help</div>
										</Link>
									</div>
									<nav className="w-dropdown-list">
										<Link to="/contact-us" className="link-block-9 w-inline-block help-link">
											<div className="text-block-68">Help</div>
										</Link>
									</nav>
								</div>
							</div>
						</nav>
						<div onClick={toggleClass} className={isActive ? 'menu-button w-nav-button bg' : 'menu-button w-nav-button'}>
							<div className="icon w-icon-nav-menu"></div>
						</div>
						<Link to="/" className="link-block-7 w-inline-block">
							<img src={logo} width="151" alt="logo" className="image-22" />
						</Link>
					</div>
				</div>



				<div className="section">

					<Tabs className="container w-container">
						<div className="w-row">
							<div className="w-col w-col-3"></div>
							<div className="column-69 w-col w-col-6">
								<TabList className="columns-38 w-row authTabList">
									<Tab className="w-col w-col-6" onClick={props.onRegisterModeClick}>
										<span className="button-2 w-button">Sign Up</span>
									</Tab>
									<Tab className="w-col w-col-6" onClick={props.handleLoginModeClick}>
										<span className="button-3 w-button">Log In</span>
									</Tab>
								</TabList>
							</div>
						</div>

						<div className="container-13 w-container">
							<div className="w-row">
								<div className="w-col w-col-3"></div>
								<div className="column-49 w-col w-col-6">
									<TabPanel>
										<Signup
											doRegister={doRegister}
										/>
									</TabPanel>
									<TabPanel>
										<div className="div-block-40">
											<h1 className="heading-2">Welcome back</h1>
											<p className="paragraph-2">Log in to your Winston platform account to continue your online visit.</p>
											<div className="w-form">
												<form id="email-form" name="email-form" data-name="Email Form" className="form">
													<input type="email" className="text-field-3 w-input" placeholder="Email address" id="name-2" required ref={emailInput}
														value={email}
														onChange={handleEmailChange}
														onKeyPress={handleKeyPress}
													/>
													<input type="password" className="text-field-4 w-input" placeholder="Password" id="email-2" required
														ref={passwordInput}
														value={password}
														onChange={handlePasswordChange}
														onKeyPress={handleKeyPress}
													/>

													<Link to="/forgot-password" className="link-3">Forgot Password ?</Link>

													<div className={validationMessage ? "w-form-fail-show" : "w-form-fail"}>
														<div dangerouslySetInnerHTML={{ __html: validationMessage }}></div>
													</div>

													<span onClick={doLogin} className="button-9 w-button"><strong>Sign in</strong></span>
													<div className="div-block-41"><img src={google} alt="google" className="image-19 googleImg" />
														<button type="button" onClick={handleGoogleSignIn} className="heading-30">Log In with Google</button>
													</div>
												</form>

												{/* validation column */}


												{/* <div className="w-form-done">
										<div>Thank you! Your submission has been received!</div>
									</div>
									<div className="w-form-fail">
										<div>Oops! Something went wrong while submitting the form.</div>
									</div> */}
											</div>
										</div>
									</TabPanel>
								</div>
								<div className="w-col w-col-3"></div>
							</div>
						</div>
					</Tabs>



				</div>

			</div>

		</div>
	);
});
export default Login;
