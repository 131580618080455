import {call, put, takeLatest, Effect, select} from "redux-saga/effects";

import {actions as types} from "./";
import {actions as appTypes} from "../app";
import {actions as patientTypes} from "../patient";
import {actions as productTypes} from "../product";
import {actions as cartTypes} from "../cart";

import * as httpAuth from "../../http/auth";
import http from "../../http";

import {getToken, getProfile, getProfilePageToOpen} from "../selectors";
import {ActionType} from "../types";

import MessageConst from "../../const/Message.const";

function* onRegister({payload}: ActionType) {
	const {setValidationMessage, history, isLoginMode, tokenId, ...profileData} = payload;

	try {
		let result:any;
		let profileInfo = {...profileData};
		let utmSource = profileInfo.utmSource;
		if(tokenId){
			result = yield call(httpAuth.googleSignup, {tokenId, utmSource});
		}else{
			result = yield call(httpAuth.register, {...profileData});
		}

		const {profile, token} = result.data;

		http.defaults.headers.Authorization = "Bearer " + token;

		yield put(types.setToken(token));
		yield put(types.registerSuccess(profile));
		yield put(appTypes.setCloseSidebarDirection(null));

		if (profile?.authority === "PATIENT") {
			yield put(cartTypes.getCartRequest());
		}

		if (isLoginMode) {
			const profilePageToOpen = yield select(getProfilePageToOpen);

			if (profilePageToOpen) {
				history.push(profilePageToOpen);
			} else {
				history.push("/account");
			}
		}
	} catch (error) {
		const profileget  = error.response.data;
		// console.log(profileget);
		if([409].includes(error.response.status)){
			history.push("/google-link")
		}	
		setValidationMessage(error.response.data.message || MessageConst.validation.registerError);
		yield put(types.removeToken());
		yield put(types.registerFailure(profileget));
	}
}

function* onForgotPassword({payload}: ActionType) {
	const {setValidationMessage, setForgotMode, email} = payload;

	try {
		yield call(httpAuth.forgotPassword, email);

		setValidationMessage("");
		setForgotMode("EMAIL_SENT");

		yield put(types.forgotPasswordSuccess());
	} catch (error) {
		setValidationMessage(error.response.data.message);
		yield put(types.forgotPasswordFailure());
	}
}

function* onLogin({payload}: ActionType) {
	const {email, password, setValidationMessage, history, isLoginMode, tokenId,isLinkToGoogle, callback} = payload;

	try {
		let result;
		if(tokenId){
			result = yield call(httpAuth.googleLogin, {tokenId});
		}else if(isLinkToGoogle){
			result = yield call(httpAuth.googlelink,{email,password})
		}
		else{
			result = yield call(httpAuth.login, {email, password});
		}		
		const {profile, token} = result.data;

		http.defaults.headers.Authorization = "Bearer " + token;

		yield put(types.loginSuccess(profile));
		yield put(types.setToken(token));
		yield put(appTypes.setCloseSidebarDirection(null));

		
		if ( isLoginMode && profile.authority === "PATIENT") {
			const profilePageToOpen = yield select(getProfilePageToOpen);
			if(callback !=null && profile.phone){
				history.push('/shipping');
			}else if(callback !=null){
				callback();
			}else if (profilePageToOpen) {
				history.push(profilePageToOpen);
			} else {
				history.push("/profile");
			}
		}

		const cart = localStorage.getItem("cartProducts");

		if (cart && profile.authority === "PATIENT") {
			yield put(cartTypes.saveCartRequest(JSON.parse(cart)));
		}
		if(profile.authority === 'PATIENT'){
			let cartItems = yield put(cartTypes.getCartRequest());
			localStorage.setItem("cartProducts", JSON.stringify(cartItems));
		}
	} catch (error) {
		
		// console.log(error.response.data.email);		
		if([409].includes(error.response.status)){
			const profileget  = error.response.data;
			yield put(types.loginFailure(profileget));
			history.push('/google-link');
		}else if([400].includes(error.response.status)){
			setValidationMessage(error.response.data.message);

		}else{
		setValidationMessage( MessageConst.validation.authError);
		}
		yield put(types.removeToken());
	}
}

function* onGetProfile({payload}: ActionType) {
	try {
		const token = yield select(getToken);

		if (token) {
			http.defaults.headers.Authorization = "Bearer " + token;

			const result = yield call(httpAuth.getProfile);
			const profile = result.data;

			yield put(types.getProfileSuccess(profile));

			if (payload?.history && payload?.mode === "forgot") {
				payload?.history.push("/profile/account-info");
			}

			yield put(productTypes.getProductsRequest());
		} else {
			yield put(productTypes.getProductsRequest());
		}

		yield put(appTypes.setLoading(false));
	} catch (error) {
		yield put(productTypes.getProductsRequest());
		yield put(types.removeToken());
		yield put(appTypes.setLoading(false));
		yield put(types.getProfileFailure());
	}
}

function* onLogout({payload: history}: ActionType) {
	try {
		const {authority} = yield select(getProfile);

		if (authority === "DOCTOR" || authority === "PHARMACIST") {
			yield put(productTypes.getProductsRequest());
		}

		if (history) {
			history.push("/");
		}

		yield put(types.removeProfile());
		yield put(types.removeToken());
		yield put(patientTypes.selectShippingAddress(null));
		yield put(patientTypes.selectBillingCard(null));
		yield put(patientTypes.selectPromoCode(null));
	} catch (error) {
		console.log(error);
	}
}

const authSagas: Effect[] = [
	takeLatest(types.getProfileRequest, onGetProfile),
	takeLatest(types.loginRequest, onLogin),
	takeLatest(types.registerRequest, onRegister),
	takeLatest(types.forgotPasswordRequest, onForgotPassword),
	takeLatest(types.logout, onLogout)
];

export default authSagas;
