import React from "react";
import Select from "react-select";

import "./styles.scss";

type SelectStyleState = {
	isSelected: boolean;
	isFocused: boolean;
};

interface CustomSelectProps {
	label?: string;
	[key: string]: any;
}

const CustomSelect = ({label, ...props}: CustomSelectProps) => {
	const customSelectStyles = {
		container: (provided: Object) => ({
			...provided,
			width: "100%"
		}),
		valueContainer: (provided: Object) => ({
			...provided,
			padding: "0 8px"
		}),
		placeholder: (provided: Object) => ({
			...provided,
			color: "#4a4a4a"
		}),
		singleValue: (provided: Object) => ({
			...provided,
			height: "100%"
		}),
		control: (provided: Object, state: any) => ({
			...provided,
			borderRadius: "0",
			cursor: "pointer",
			border: "2px solid $main-gold",
			boxShadow: "0",
			fontFamily: "Avenir-Book",
			fontSize: "14px",
			lineHeight: "20px",
			outline: state.isFocused ? "1px auto black" : "none",
			"@media (max-width: 959px)": {
				fontSize: "16px"
			},
			"&:hover": {
				border: "2px solid $main-gold"
			},
			"&:active": {
				boxShadow: "0"
			},
			"&:focus": {
				outline: "1px auto black"
			}
		}),
		dropdownIndicator: (provided: Object) => ({
			...provided,
			cursor: "pointer",
			color: "$main-gold"
		}),
		indicatorSeparator: (provided: Object) => ({
			...provided,
			display: "none"
		}),
		menu: (provided: Object) => ({
			...provided,
			margin: 0,
			borderRadius: 0,
			fontFamily: "Avenir-Book",
			fontSize: "14px",
			height:"auto",
			borderWidth: "1px 1px",
			borderStyle: "solid",
			borderColor: "hsl(0,0%,80%)"
		}),
		menuList: (provided: Object) => ({
			...provided,
			padding: 0,
			borderWidth: "0px 0px",
			borderStyle: "solid",
			borderColor: "#000000"
		}),
		option: (provided: Object, state: any) => ({
			...provided,
			borderBottom: "2px solid $main-gold",
			cursor: "pointer",
			background: state.isFocused ? "#CCC" : "#FFF",
			color: "#000000",
			display: state.isSelected ? "none" : "block"
		}),
		noOptionsMessage: (provided: Object) => ({
			...provided,
			borderBottom: "2px solid $main-gold",
			background: "#FFF"
		})
	};

	return (
		<div className="custom-select-wrapper">
			{label && <div className="label">{label}</div>}
			<Select isMulti={false} isSearchable={false} styles={customSelectStyles} {...props} />
		</div>
	);
};

export default CustomSelect;
