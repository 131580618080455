import {handleActions, createActions} from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
	GET_CART_REQUEST: undefined,
	GET_CART_SUCCESS: undefined,
	GET_CART_FAILURE: undefined,

	SAVE_CART_REQUEST: undefined,
	SAVE_CART_SUCCESS: undefined,
	SAVE_CART_FAILURE: undefined,

	MAKE_SUBSCRIPTION_REQUEST: undefined,
	MAKE_SUBSCRIPTION_SUCCESS: undefined,
	MAKE_SUBSCRIPTION_FAILURE: undefined,

	ADD_PRODUCT_REQUEST: undefined,
	ADD_PRODUCT_SUCCESS: undefined,
	ADD_PRODUCT_FAILURE: undefined,

	DELETE_PRODUCT_REQUEST: undefined,
	DELETE_PRODUCT_SUCCESS: undefined,
	DELETE_PRODUCT_FAILURE: undefined,

	UPDATE_PRODUCT_REQUEST: undefined,
	UPDATE_PRODUCT_SUCCESS: undefined,
	UPDATE_PRODUCT_FAILURE: undefined,

	ADD_CONSULTATION_DATE_REQUEST: undefined,

	ERASE_CART: undefined
});

const reducer = handleActions(
	{
		[`${actions.getCartRequest}`]: handlers.getCartRequest,
		[`${actions.getCartSuccess}`]: handlers.getCartSuccess,
		[`${actions.getCartFailure}`]: handlers.getCartFailure,

		[`${actions.saveCartRequest}`]: handlers.saveCartRequest,
		[`${actions.saveCartSuccess}`]: handlers.saveCartSuccess,
		[`${actions.saveCartFailure}`]: handlers.saveCartFailure,

		[`${actions.makeSubscriptionRequest}`]: handlers.makeSubscriptionRequest,
		[`${actions.makeSubscriptionSuccess}`]: handlers.makeSubscriptionSuccess,
		[`${actions.makeSubscriptionFailure}`]: handlers.makeSubscriptionFailure,

		[`${actions.addProductRequest}`]: handlers.addProductRequest,
		[`${actions.addProductSuccess}`]: handlers.addProductSuccess,
		[`${actions.addProductFailure}`]: handlers.addProductFailure,

		[`${actions.deleteProductRequest}`]: handlers.deleteProductRequest,
		[`${actions.deleteProductSuccess}`]: handlers.deleteProductSuccess,
		[`${actions.deleteProductFailure}`]: handlers.deleteProductFailure,

		[`${actions.updateProductRequest}`]: handlers.updateProductRequest,
		[`${actions.updateProductSuccess}`]: handlers.updateProductSuccess,
		[`${actions.updateProductFailure}`]: handlers.updateProductFailure,

		[`${actions.addConsultationDateRequest}`]: handlers.addConsultationDateRequest,

		[`${actions.eraseCart}`]: handlers.eraseCart
	},
	initialState
);
export default reducer;
