import {handleActions, createActions} from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
	ADD_SHIPPING_ADDRESS_REQUEST: undefined,
	ADD_SHIPPING_ADDRESS_SUCCESS: undefined,
	ADD_SHIPPING_ADDRESS_FAILURE: undefined,

	ADD_BILLING_CARD_REQUEST: undefined,
	ADD_BILLING_CARD_SUCCESS: undefined,
	ADD_BILLING_CARD_FAILURE: undefined,

	SET_PRIMARY_SHIPPING_ADDRESS_REQUEST: undefined,
	SET_PRIMARY_SHIPPING_ADDRESS_SUCCESS: undefined,
	SET_PRIMARY_SHIPPING_ADDRESS_FAILURE: undefined,

	SET_PRIMARY_BILLING_CARD_REQUEST: undefined,
	SET_PRIMARY_BILLING_CARD_SUCCESS: undefined,
	SET_PRIMARY_BILLING_CARD_FAILURE: undefined,

	VERIFY_PROMO_CODE_REQUEST: undefined,
	VERIFY_PROMO_CODE_SUCCESS: undefined,
	VERIFY_PROMO_CODE_FAILURE: undefined,

	UPDATE_PROFILE_REQUEST: undefined,
	UPDATE_PROFILE_SUCCESS: undefined,
	UPDATE_PROFILE_FAILURE: undefined,

	UPLOAD_ID_REQUEST: undefined,
	UPLOAD_ID_SUCCESS: undefined,
	UPLOAD_ID_FAILURE: undefined,

	UPLOAD_INSURANCE_REQUEST: undefined,
	UPLOAD_INSURANCE_SUCCESS: undefined,
	UPLOAD_INSURANCE_FAILURE: undefined,

	SELECT_SHIPPING_ADDRESS: undefined,

	SELECT_BILLING_CARD: undefined,

	SELECT_PROMO_CODE: undefined
});

const reducer = handleActions(
	{
		[`${actions.addShippingAddressRequest}`]: handlers.addShippingAddressRequest,
		[`${actions.addShippingAddressSuccess}`]: handlers.addShippingAddressSuccess,
		[`${actions.addShippingAddressFailure}`]: handlers.addShippingAddressFailure,

		[`${actions.addBillingCardRequest}`]: handlers.addBillingCardRequest,
		[`${actions.addBillingCardSuccess}`]: handlers.addBillingCardSuccess,
		[`${actions.addBillingCardFailure}`]: handlers.addBillingCardFailure,

		[`${actions.setPrimaryShippingAddressRequest}`]: handlers.setPrimaryShippingAddressRequest,
		[`${actions.setPrimaryShippingAddressSuccess}`]: handlers.setPrimaryShippingAddressSuccess,
		[`${actions.setPrimaryShippingAddressFailure}`]: handlers.setPrimaryShippingAddressFailure,

		[`${actions.setPrimaryBillingCardRequest}`]: handlers.setPrimaryBillingCardRequest,
		[`${actions.setPrimaryBillingCardSuccess}`]: handlers.setPrimaryBillingCardSuccess,
		[`${actions.setPrimaryBillingCardFailure}`]: handlers.setPrimaryBillingCardFailure,

		[`${actions.verifyPromoCodeRequest}`]: handlers.verifyPromoCodeRequest,
		[`${actions.verifyPromoCodeSuccess}`]: handlers.verifyPromoCodeSuccess,
		[`${actions.verifyPromoCodeFailure}`]: handlers.verifyPromoCodeFailure,

		[`${actions.updateProfileRequest}`]: handlers.updateProfileRequest,
		[`${actions.updateProfileSuccess}`]: handlers.updateProfileSuccess,
		[`${actions.updateProfileFailure}`]: handlers.updateProfileFailure,

		[`${actions.uploadIdRequest}`]: handlers.uploadIdRequest,
		[`${actions.uploadIdSuccess}`]: handlers.uploadIdSuccess,
		[`${actions.uploadIdFailure}`]: handlers.uploadIdFailure,

		[`${actions.uploadInsuranceRequest}`]: handlers.uploadInsuranceRequest,
		[`${actions.uploadInsuranceSuccess}`]: handlers.uploadInsuranceSuccess,
		[`${actions.uploadInsuranceFailure}`]: handlers.uploadInsuranceFailure,

		[`${actions.selectShippingAddress}`]: handlers.selectShippingAddress,

		[`${actions.selectBillingCard}`]: handlers.selectBillingCard,

		[`${actions.selectPromoCode}`]: handlers.selectPromoCode
	},
	initialState
);
export default reducer;
