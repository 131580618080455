import React, {useState, useEffect, ChangeEvent} from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";

import {getAllPromoCodes, addPromoCode, deletePromoCode} from "../../../../http/promoCode";

import DateService from "../../../../service/Date.service";
import ValidationService from "../../../../service/Validation.service";

import PromoCodeType from "../../../../model/PromoCodeType";
import PromoCode from "../../../../model/PromoCode";

import LoaderComponent from "../../../Loader";

import "./styles.scss";
import {
	ProductCategory,
	ProductCategoryName,
	ServedProductCategories
} from "../../../../model/ProductCategory";

const PromoCodes = () => {
	const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [code, setCode] = useState<string>("");
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const [toDate, setToDate] = useState<Date | null>(null);
	const [type, setType] = useState<PromoCodeType>("AMOUNT");
	const [value, setValue] = useState<string>("");
	const [validationMessage, setValidationMessage] = useState<string>("");
	const [giftCategories, setGiftCategories] = useState<ProductCategory[]>([]);

	const getAllCodes = async () => {
		setIsLoading(true);
		try {
			const result = await getAllPromoCodes();

			setPromoCodes(result.data);
		} catch (err) {
			console.log(err);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getAllCodes();
	}, []);

	useEffect(() => {
		setGiftCategories([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const handleStartDateChange = (date: Date | null): void => {
		if (date !== null) {
			setFromDate(date);
		}
	};
	const handleEndDateChange = (date: Date | null): void => {
		if (date !== null) {
			setToDate(date);
		}
	};

	const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		const rgx = /^[0-9]*\.?[0-9]*$/;

		if (rgx.test(e.target.value)) {
			setValue(e.target.value);
		}
	};

	const handleAddPromoCode = async () => {
		if (!ValidationService.validateRequired(code)) {
			setValidationMessage("Please enter code");

			return;
		}

		if (!fromDate || !toDate || fromDate > toDate) {
			setValidationMessage("Please select valid dates");

			return;
		}

		if (type !== "GIFT") {
			if (!ValidationService.validateRequired(value)) {
				setValidationMessage("Please enter code value");

				return;
			}

			if (type === "PERCENT" && (+value <= 0 || +value > 100)) {
				setValidationMessage("Please provide valid percent value");

				return;
			}
		} else {
			if (!giftCategories.length) {
				setValidationMessage("Please select product categories");

				return;
			}
		}

		const promoCode: PromoCode = {
			code,
			type,
			fromDate: DateService.formatLocalDateForServer(fromDate),
			toDate: DateService.formatLocalDateForServer(toDate),
			value: +value,
			problemCategory: []
		};

		if (type === "AMOUNT") {
			promoCode.value = +value * 100;
		}

		if (type === "GIFT") {
			promoCode.problemCategory = [...giftCategories];
			promoCode.value = 0;
		}

		try {
			await addPromoCode(promoCode);

			setCode("");
			setFromDate(null);
			setToDate(null);
			setType("AMOUNT");
			setValue("");
			setValidationMessage("");

			await getAllCodes();
		} catch (error) {
			const message = error?.response?.data?.message || "Something went wrong please try again";
			setValidationMessage(message);
		}
	};

	const handleDeletePromoCode = async (code: string) => {
		try {
			await deletePromoCode(code);

			await getAllCodes();
		} catch (error) {
			console.log(error);
		}
	};

	const handleCategoryChange = (isChecked: boolean, value: ProductCategory) => {
		const newPromoCategories = [...giftCategories];

		if (isChecked) {
			newPromoCategories.push(value);
		} else {
			const catIndex = newPromoCategories.findIndex((el) => el === value);

			newPromoCategories.splice(catIndex, 1);
		}
		setGiftCategories(newPromoCategories);
	};

	const isCategotyChecked = (category: ProductCategory) => giftCategories.includes(category);

	const getProbmlemCategoryString = (problemCategories: ProductCategory[]) => {
		let categoriesString = "";

		problemCategories.forEach((pc) => (categoriesString += ProductCategoryName[pc] + ", "));
		categoriesString = categoriesString.slice(0, -2);

		return categoriesString;
	};

	const renderCodes = () => {
		return promoCodes.map((code, index) => (
			<div className="promocode-item" key={index}>
				<div className="promocode-name">Code: {code.code}</div>
				<div className="promocode-dates">
					<div className="promocode-date-from">
						Date from: {moment(code.fromDate).format("MMM D, YYYY")}
					</div>
					<div className="promocode-date-to">
						Date to: {moment(code.toDate).format("MMM D, YYYY")}
					</div>
				</div>
				<div className="promocode-value">
					{`${code.type}: ${
						code.type === "AMOUNT"
							? "$" + code.value / 100
							: code.type === "PERCENT"
							? code.value + "%"
							: getProbmlemCategoryString(code.problemCategory)
					}`}
				</div>
				<div className="delete-btn" onClick={() => handleDeletePromoCode(code.code)}>
					Delete
				</div>
			</div>
		));
	};

	return (
		<div className="admin-dashboard-promo-codes prefiles">
			{isLoading && <LoaderComponent />}
			<div className="add-section">
				<div className="add-promo-title">Add Code</div>
				<div className="add-code-form">
					<div className="name-input">
						<span className="field-title">Code:</span>
						<input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
					</div>
					<div className="promo-dates">
						<div className="date-input">
							<span className="field-title">Start Date:</span>
							<ReactDatePicker
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								minDate={moment().toDate()}
								dateFormat="MMM d, yyyy"
								selected={fromDate}
								onChange={handleStartDateChange}
							/>
						</div>
						<div className="date-input">
							<span className="field-title">End Date:</span>
							<ReactDatePicker
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								disabled={!fromDate}
								minDate={fromDate || moment().toDate()}
								dateFormat="MMM d, yyyy"
								selected={toDate}
								onChange={handleEndDateChange}
							/>
						</div>
					</div>
					<div className="promo-type-and-value">
						<div className="promo-type-select">
							<span className="field-title">Select Type</span>
							<select
								name="type"
								value={type}
								onChange={(e) => setType(e.target.value as PromoCodeType)}
							>
								<option value="AMOUNT">Amount</option>
								<option value="PERCENT">Percent</option>
								<option value="GIFT">Gift</option>
							</select>
						</div>
						{type === "GIFT" ? (
							<div className="promo-type-select">
								<span className="field-title">Select Category</span>

								<div className="categories-select">
									{ServedProductCategories.map((c) => (
										<div className="category-checkbox-input" key={c}>
											<input
												type="checkbox"
												name={c}
												value={c}
												checked={isCategotyChecked(c)}
												onChange={(e) =>
													handleCategoryChange(e.target.checked, e.target.value as ProductCategory)
												}
											/>
											<label>{ProductCategoryName[c]}</label>
										</div>
									))}
								</div>
							</div>
						) : (
							<div className="value-input">
								<span className="field-title">Value:</span>
								<input
									type="text"
									value={value}
									onChange={handleValueChange}
									placeholder="eg 12.99"
								/>
							</div>
						)}
					</div>
					<div className="submit-btn" onClick={handleAddPromoCode}>
						Submit
					</div>
				</div>
				{validationMessage && <div className="validation-error">{validationMessage}</div>}
			</div>
			<div className="codes-section">
				{promoCodes.length === 0 ? (
					<div className="no-code">There is no active codes at this moment</div>
				) : (
					renderCodes()
				)}
			</div>
		</div>
	);
};

export default PromoCodes;
