import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Switch, Route, useLocation, Redirect, useHistory, RouteProps} from "react-router-dom";
import Rollbar from "rollbar";

import {StateType} from "../../redux/types";
import {actions as authActions} from "../../redux/auth";
import {actions as appActions} from "../../redux/app";

import AboutUsComponent from "../AboutUs";
import AdminDashboardComponent from "../AdminDashboard";
import DoctorDashboardComponent from "../DoctorDashboard";
import TheFacts from "../Learn";
import FaceComponent from "../Face";
import FaqComponent from "../FAQ";
import FooterComponent from "../Footer";
import HeaderComponent from "../Header";
import HowItWorksComponent from "../HowItWorks";
import LoaderComponent from "../Loader";
import PharmacistDashboardComponent from "../PharmacistDashboard";
import PrivacyPolicyTermsOfUseComponent from "../PrivacyPolicyTermsOfUse";
import Profile from "../Profile";
import Sidebar from "../Sidebar";
import WhyWinstonComponent from "../WhyWinston";
import Shop from "../Shop";
import ProductPage from "../ProductPage";
import FactPage from "../FactPage";
import ContactUs from "../ContactUs";
import TransferRxPage from "../TransferRxPage";
import CheckoutSuccess from "../../components/OrderProcess/components/CheckoutSuccess";
// import Relogin from "../Login/Re-login"
import "react-slidedown/lib/slidedown.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../static/fonts/fonts.scss";
import "./styles.scss";
import Modal from "../Shared/Modal";
import ProductED from "../ProductED";
import ProductCS from "../ProductCS";
import ProductHL from "../ProductHL";
import ProductACNE from "../ProductACNE";
import ProductAL from "../ProductAL";
import PopUp from "../PopUp";
import close from "../../static/images/close.png";
import popUpimg from  "../../static/images/blog-allergies.png"
declare global {
	interface Window {
		Intercom: any;
		fbq: any;
		rollbar: Rollbar;
	}
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const AuthRoute = (props: RouteProps) => {
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));

	if (profile) {
		return <Route {...props} />;
	}

	return <Redirect to="/" />;
};

const NonAuthRoute = (props: RouteProps) => {
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));

	if (!profile) {
		return <Route {...props} />;
	}

	return <Redirect to="/" />;
};

const Main = () => {
	const query = useQuery();
	const {pathname, key: locationKey} = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
    // const [firstVisit, setFirstVisit] = useState(false);
	const {loading, isModalShown, loadingProducts, profile, selectedProducts, closeSidebarDirection} = useSelector(
		({app, modal, auth, cart, product}: StateType) => ({
			loading: app.loading,
			isModalShown: modal.isShown,
			selectedProducts: cart.selectedProducts,
			profile: auth.profile,
			loadingProducts: product.loadingProducts,
			shopScrollTarget: app.shopScrollTarget,
			isSubscribed: app.isSubscribed,
			closeSidebarDirection: app.closeSidebarDirection
		})
	);

	const [, setScrolled] = useState<boolean>(false);

	useEffect(() => {
		const handleScrolled = () => {
			if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener("scroll", handleScrolled);
		return () => {
			window.removeEventListener("scroll", handleScrolled);
		};
	}, []);

	useEffect(() => {
		const fbq = window.fbq || null;

		if (fbq) {
			fbq("track", "PageView");
		}
	}, [pathname]);

	useEffect(() => {
		const isLogout = query.get("logout");

		if (isLogout) {
			dispatch(authActions.logout());

			history.push("/login");
		}
	}, [dispatch, history, query]);

	useEffect(() => {
		const Intercom = window.Intercom || null;

		if (Intercom && (profile?.authority === "PHARMACIST" || profile?.authority === "DOCTOR")) {
			Intercom("update", {
				hide_default_launcher: true
			});
		} else if (Intercom) {
			Intercom("update", {
				hide_default_launcher: false
			});
		}
	}, [profile]);

	useEffect(() => {
		if (authActions.getProfileRequest) {
			dispatch(authActions.getProfileRequest());
		} else {
			if (typeof(window.rollbar) !== "undefined") {
				/*
				window.rollbar.warning(
					"getProfileRequest action from authActions is not initialized!"
				);*/
			}
		}
	}, [dispatch]);

	useEffect(() => {
		if (closeSidebarDirection && pathname === closeSidebarDirection.pathname) {
			window.scrollTo(0, closeSidebarDirection.y);
			dispatch(appActions.setCloseSidebarDirection(null));
		} else if (pathname !== "/shop" && !pathname.includes("profile/transfer-rx")) {
			window.scrollTo(0, 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationKey, pathname, dispatch]);

	const handleLogoutClick = () => {
		dispatch(authActions.logout(history));
	};
	// useEffect(()=>{
	// 	let visited =sessionStorage.getItem("visited");
	// 	if(visited === null){
	// 		setFirstVisit(true);
	// 	}			
				
	// },[])
	// const handleClose =()=>{
	// 	setFirstVisit(false);
	// 	sessionStorage.setItem('visited',"true");
	// }

	const renderRoutes = () => {
		if (profile) {
			switch (profile.authority) {
				case "DOCTOR":
					return (
						<Route
							render={() => (
								<DoctorDashboardComponent profile={profile} onLogoutClick={handleLogoutClick} />
							)}
						/>
					);
				case "PHARMACIST":
					return (
						<Route
							render={() => (
								<PharmacistDashboardComponent profile={profile} onLogoutClick={handleLogoutClick} />
							)}
						/>
					);
				case "ADMIN":
					return <Route render={() => <AdminDashboardComponent />} />;
			}
		}

		return (
			<div className="main-wrapper">
				<HeaderComponent cartNumber={selectedProducts.length} />
				{/* {firstVisit ?
						<div id="infoPopup">
						<PopUp>
							<div className="container-8 w-container">
								<div className="closeBtn">
									<div onClick={handleClose}><img className="closeBtnImg" src={close} alt="close" /></div>
								</div>
								<div className="columns-67 w-row">
									<div className="column-115 w-col w-col-6 popup-img-col">
									 <img className="popup-left-img" src={popUpimg} height="100%" width="100%"  alt="image" />
									</div>
									<div className="column-109 w-col w-col-6 ">
										<div className="popupContent">
											<h1 className="popupContent-heading">Lorem Ipsum is simply dummy text</h1>
											<p className="popupContent-info">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

											<a className="button-popup" href="#"><strong>Yes, Lorem Ipsum is simply</strong></a>
										</div>	
									</div>
								</div>
							</div>
						</PopUp>
						</div>
						: null} */}
				<Switch>					
					<Route exact path="/faq" component={FaqComponent} />
					<Route exact path="/erectile-dysfunction" component={ProductED} />
					<Route exact path="/hair-loss" component={ProductHL} />
					<Route exact path="/cold-sores" component={ProductCS} />
					<Route exact path="/acne" component={ProductACNE} />
					<Route exact path="/allergies" component={ProductAL} />
					<Route exact path="/about-us" component={AboutUsComponent} />
					<Route exact path="/contact-us" component={ContactUs} />
					<Route exact path="/learn" component={TheFacts} />
					<Route exact path="/how-it-works" component={HowItWorksComponent} />
					<Route exact path="/transfer-rx" component={TransferRxPage} />
					<Route exact path="/why-winston" component={WhyWinstonComponent} />
					{/* <Route exact path="/re-login" component={Relogin}/> */}
					<Route
						exact
						path="/terms-of-use"
						render={() => <PrivacyPolicyTermsOfUseComponent mode={"TermsOfUse"} isStatic />}
					/>
					<Route
						exact
						path="/privacy-policy"
						render={() => <PrivacyPolicyTermsOfUseComponent mode={"PrivacyPolicy"} isStatic />}
					/>
					<Route exact path="/" render={() => <FaceComponent />} />
					<Route exact path="/shop/:category" render={() => <Shop />} />
					<Route exact path="/shop" render={() => <Shop />} />
					<Route exact path="/article/:articleName" render={() => <FactPage />} />
					<AuthRoute path="/profile" render={() => <Profile />} />
					<AuthRoute exact path="/checkout-success" render={() => <CheckoutSuccess />} />
					<NonAuthRoute
						exact
						path={["/login", "/forgot-password/:token", "/forgot-password","/google-link"]}
						render={() => <Sidebar />}
					/>
					<Route
						exact
						path={[
							"/cart",
							"/signin",
							"/account",
							"/shipping",
							"/payment",
							"/id",
							"/doctor-consult",
							"/confirm"
						]}
						render={() => <Sidebar />}
					/>
					<Route exact path="/:productName" render={() => <ProductPage />} />
				</Switch>
				<FooterComponent />
			</div>
		);
	};

	return (
		<div className="main-component">
			{isModalShown && (<Modal />)}
			{loading || loadingProducts ? <LoaderComponent /> : renderRoutes()}
		</div>
	);
};

export default Main;
