import React, { Component, FormEvent } from "react";
import { Link } from "react-router-dom";
import ValidationService from "../../service/Validation.service"
import MessageConst from "../../const/Message.const"
import { subscribe } from "../../http/emailSubscription"
import iconFacebookSvg from "../../static/images/facebook5x-1.png";
import iconInstagramSvg from "../../static/images/instagram5x-1.png";
import iconLinkedinSvg from "../../static/images/linkedin5x-1.png";
import iconTweeterSvg from "../../static/images/twitter5x-1.png";
// import bbbLogo from "../../static/images/bbb_logo.svg";
import visaCard from "../../static/images/card_visa.png";
import amexCard from "../../static/images/card_amex.png";
import mastercardCard from "../../static/images/card_mastercard.png";
import unionpayCard from "../../static/images/card_unionpay.png";
import discoverCard from "../../static/images/card_discover.png";
import legit from "../../static/images/legit.png";
import "./styles.scss";

interface FooterState {
	copyrightYear: string;
	email: string;
	name: string;
	successMessage: string;
	validation: string;
	isLoading: boolean;
	errors: { email: boolean }
}

export default class FooterComponent extends Component<{}, FooterState> {
	public constructor(props: {}) {
		super(props);

		const date = new Date();
		this.state = {
			copyrightYear: date.getFullYear().toString(),
			email: "",
			name: "",
			successMessage: "",
			validation: "",
			isLoading: false,
			errors: { email: true }
		};
		this.handleClick = this.handleClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}
	
	componentDidUpdate() {
		if (this.state.validation || this.state.successMessage){
		 setTimeout(() => {
				this.setState({
				  validation:'',
				  successMessage:''
				});
			  }, 2000);
		}
		else {
			clearTimeout();
		  }
		//  let myVar = setTimeout(()=>this.setState({validation:''}),2000)
		// clearTimeout(myVar)
		
			// setTimeout(()=>this.setState({validation:''}),2000)	
		// setTimeout(() => this.setState({ validation: '' }), 2000);
		// setTimeout(() => this.setState({ successMessage: '' }), 20000);
	}
	
	private getLegiscriptSeal = () => {
		const url = window.location.protocol + "//static.legitscript.com/seals/5468581.png";

		return (
			<a
				href="http://legitscript.com/pharmacy/askwinston.ca"
				target="_blank"
				title="Verify LegitScript Approval"
				rel="noopener noreferrer"
			>
				<img src={url} alt="LegitScript approved" width="140" height="100" />
			</a>
		);
	};

	handleClick(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ email: e.target.value })
	}

	 onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		this.setState({ successMessage: "" });
		var email = this.state.email
		var userName = email.substring(0,email.indexOf('@'))
		console.log(userName);
		

		if (!ValidationService.validateEmail(this.state.email)) {
			this.setState({ validation: MessageConst.validation.emailError })

			return;
		}

		this.setState({ isLoading: true });

		try {
			await subscribe({
				name: userName,
				email: this.state.email
			});

			this.setState({ email: "" });

		 	this.setState({ validation: "" });
			
			this.setState({successMessage: "You have subscribed into Winston news!!!"})
		} catch (err){
			this.setState({ validation: "Something went wrong, please try again" });
			this.setState({ successMessage: "" });
		}

		this.setState({ isLoading: false });
	};

	public render() {
		return (
			<div id="footer">
				<div className="section-14">
					<div className="container-24 w-container">
						<h1 className="heading-109"><strong>Get Winston News</strong></h1>
						<div className="w-row">
							<div className="column-137 w-col w-col-6">
								<div className="w-row">
									<div className="w-col w-col-9">
										<div className="w-form">
											<form id="email-form" name="email-form" data-name="Email Form" className="form">
												{/* <input type="email" className="text-field w-input" name="email" data-name="Email" placeholder="Email" id="email" required /> */}
												<input
													id="text-field w-input"
													className={this.state.validation ? "text-field w-input error" : "text-field w-input"}
													type="email"
													placeholder="Email"
													value={this.state.email}
													onChange={this.handleClick}
													name="email"
												/>
												
												{/* <a href="#" className="button-20 w-button" {()=>{this.onSubmit()}} ><strong>Submit</strong></a> */}
												<button className="button-20 w-button" onClick={this.onSubmit}>Submit</button>
											</form>


											{this.state.validation ?(
												 <div className="validation-error">{this.state.validation}</div>):null}
											{this.state.successMessage ?( <div className="success-message">
												{this.state.successMessage}</div>): null}
										</div>
									</div>
									<div className="w-col w-col-3"></div>
								</div>
							</div>
							<div className="column-138 w-col w-col-6">
								<div className="div-block-70">
									<a href="https://www.linkedin.com/company/ask-winston/about" rel="noopener noreferrer" className="link-block-11 w-inline-block" target="_blank"><img src={iconLinkedinSvg} width="20" alt="linkedin" className="image-29" /></a>
									<a href="https://www.instagram.com/ask.winston" rel="noopener noreferrer" className="link-block-12 w-inline-block" target="_blank"><img src={iconInstagramSvg} width="20" alt="instagram" className="image-31" /></a>
									<a href="https://twitter.com/winstonhealth" rel="noopener noreferrer" className="link-block-13 w-inline-block" target="_blank"><img src={iconTweeterSvg} width="20" alt="twitter" className="image-30" /></a>
									<a href="https://www.facebook.com/ask.winston11" rel="noopener noreferrer" className="link-block-14 w-inline-block" target="_blank"><img src={iconFacebookSvg} width="20" alt="facebook" className="image-30" /></a>
									{/* <a href="#" className="link-block-15 w-inline-block" target="_blank"><img src={youtube5x} width="20" alt="" className="image-30" /></a> */}
								</div>
							</div>
						</div>
					</div>
					<div className="container-22 w-container">
						<div className="w-row">
							<div className="column-124 w-col w-col-3">
								<h1 className="heading-112"><strong>Shop</strong></h1>
								<Link to="/acne" className="link-14">Acne</Link>
								<Link to="/erectile-dysfunction" className="link-11">Erectile Dysfunction</Link>
								<Link to="/cold-sores" className="link-13">Cold Sores</Link>
								<Link to="/hair-loss" className="link-12">Hair loss</Link>
								<Link to="/allergies" className="link-12">Allergies</Link>
							</div>
							<div className="column-125 w-col w-col-3">
								<h1 className="heading-106"><strong>Company</strong></h1>
								<Link to="/why-winston" className="link-5">Why Winston</Link>
								<Link to="/about-us" className="link-6">About Us</Link>
								<Link to="/contact-us" className="link-9">Contact Us</Link>
							</div>
							<div className="column-128 w-col w-col-3">
								<h1 className="heading-105"><strong>Support</strong></h1>
								<Link to="/transfer-rx" className="link">Transfer Prescription</Link>
								<a href="https://learn.askwinston.ca" className="link-3">Learn</a>
								<Link to="/how-it-works" className="link-2">How It Works</Link>
								<Link to="/faq" className="link-4">FAQ&#x27;s</Link>
							</div>
							<div className="column-129 w-col w-col-3">
								<h1 className="heading-113"><strong>Legal</strong></h1>
								<Link to="/privacy-policy" className="link-8">Privacy Policy</Link>
								<Link to="/terms-of-use" className="link-7">Terms of use</Link>
							</div>
						</div>
					</div>
					<div className="container-20 w-container">
						<div className="columns-72 w-row">
							<div className="column-140 w-col w-col-6">
								<a href="http://legitscript.com/pharmacy/askwinston.ca" rel="noopener noreferrer" target="_blank"><img src={legit} width="112" alt="" className="image-24" /></a>
								{/* <a href="https://www.bbb.org/ca/on/markham/profile/health-care-referral/winston-0107-1385715#sealclick" rel="noopener noreferrer" target="_blank"><img src={bbbLogo} width="56" alt="" /></a> */}
							</div>
							<div className="column-126 w-col w-col-6">
								<img src={unionpayCard} width="36" alt="unionpay" className="image-32" />
								<img src={discoverCard} width="36" alt="discover" className="image-33" />
								<img src={amexCard} width="36" alt="amex" className="image-34" />
								<img src={mastercardCard} width="36" alt="mastercard" className="image-35" />
								<img src={visaCard} width="36" alt="visa" />
								<h1 className="heading-108">Copyright © 2021 XY Health Ltd. All rights reserved. <br />Winston is not a pharmacy or drug manufacturer.</h1>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
