import {ActionType} from "../types";

export interface ModalState {
	header: string;
	text: string;
	isShown: boolean;
}

const initialState: ModalState = {
	header: "",
	text: "",
	isShown: false,
};

export const showModal = (state: ModalState, {payload}: ActionType) => ({
	...state,
	header: payload.header,
	text: payload.text,
	isShown: true
});

export const hideModal = (state: ModalState) => ({
	...state,
	isShown: false
});

export default initialState;
