import {call, put, takeLatest, Effect, select, all} from "redux-saga/effects";

import {actions as types} from "./";
import {actions as patientTypes} from "../patient";
import {ActionType} from "../types";

import {getSelectedProducts, getProductItems, getProfile} from "../selectors";

import * as httpCart from "../../http/cart";

import {formatProductToCart, formatCartItemToSave} from "../../utils/helpers";

import SelectedProduct from "../../model/SelectedProduct";
import CartItem from "../../model/CartItem";
import Product from "../../model/Product";
import {bookSlot} from "../../http/schedule";

declare global {
	interface Window {
		saq: any;
	}
}

function* onGetCart() {
	try {
		const result = yield call(httpCart.getCart);
		const cart = result.data.items;

		if (cart.length) {
			const productItems = yield select(getProductItems);
			const newCart: SelectedProduct[] = [];
			const productsToDelete: {product: any; quantity: any; count: 1}[] = [];

			cart.forEach((cartItem: CartItem) => {
				const product = productItems.find((prod: Product) => prod.id === cartItem.productId);

				if (product) {
					const quantity = product.quantities.find(
						(q: Product) => q.id === cartItem.productQuantityId
					);
					if (quantity) {
						newCart.push({
							product,
							quantity,
							count: 1
						});
					} else {
						productsToDelete.push({
							product: {id: cartItem.productId},
							quantity: {id: cartItem.productQuantityId},
							count: 1
						});
					}
				} else {
					productsToDelete.push({
						product: {id: cartItem.productId},
						quantity: {id: cartItem.productQuantityId},
						count: 1
					});
				}
			});

			yield all(productsToDelete.map((p) => put(types.deleteProductRequest(p))));

			yield put(types.getCartSuccess(newCart));
		} else {
			const oldCart = yield select(getSelectedProducts);

			if (oldCart.length) {
				yield put(types.saveCartRequest(oldCart));
			}

			yield put(types.getCartSuccess(oldCart));
		}
	} catch (error) {
		console.log(error);
		yield put(types.getCartFailure());
	}
}

function* onSaveCart({payload: cart}: ActionType) {
	const formatCartItemToSave = (cartItem: SelectedProduct): CartItem => ({
		productId: cartItem.product.id,
		productQuantityId: cartItem.quantity.id,
		productCount: 1
	});

	try {
		const newCart = cart.map((cartItem: SelectedProduct) => formatCartItemToSave(cartItem));

		const result = yield call(httpCart.saveCart, newCart);
		const productItems = yield select(getProductItems);
		const formattedResult: SelectedProduct[] = [];
		const productsToDelete: {product: any; quantity: any; count: 1}[] = [];

		result.data.items.forEach((cartItem: CartItem) => {
			const product = productItems.find((prod: Product) => prod.id === cartItem.productId);
			if (product) {
				const quantity = product.quantities.find(
					(q: Product) => q.id === cartItem.productQuantityId
				);
				if (quantity) {
					formattedResult.push({
						product,
						quantity,
						count: 1
					});
				} else {
					productsToDelete.push({
						product: {id: cartItem.productId},
						quantity: {id: cartItem.productQuantityId},
						count: 1
					});
				}
			} else {
				productsToDelete.push({
					product: {id: cartItem.productId},
					quantity: {id: cartItem.productQuantityId},
					count: 1
				});
			}
		});

		yield all(productsToDelete.map((p) => put(types.deleteProductRequest(p))));
		yield put(types.saveCartSuccess(formattedResult));
	} catch (error) {
		console.log(error);
		yield put(types.saveCartFailure());
	}
}

function* onMakeSubscription({payload}: ActionType) {
	const {cart, setValidationMessage, history} = payload;

	try {
		const subscription = yield call(httpCart.makeSubscription, cart);
		const booking = yield call(bookSlot, {
			slotId: cart.consultationSlotId,
			subscriptionId: subscription.data.map((item: {id: number}) => item.id)
		});
		const subscriptionIds: number[] = [];
		booking.data.subscription.forEach((item: {id: number}) => {
			subscriptionIds.push(item.id);
		});

		localStorage.removeItem("cartProducts");
		history.push("/checkout-success");
		window.saq("ts", "4X8l353YU3PFJlChGXxKqQ");
		yield put(patientTypes.selectShippingAddress(null));
		yield put(patientTypes.selectBillingCard(null));
		yield put(patientTypes.selectPromoCode(null));
		yield put(types.makeSubscriptionSuccess(subscriptionIds));
	} catch (error) {
		console.log(error);
		setValidationMessage("Error happened.");
		yield put(types.makeSubscriptionFailure());
	}
}

function* onAddProduct({payload: {product}}: ActionType) {
	try {
		const selectedProducts = yield select(getSelectedProducts);

		const isAdded =
			selectedProducts.findIndex((prod: SelectedProduct) => prod.product.id === product.id) + 1;

		if (!isAdded) {
			const productToAdd = formatProductToCart(product);
			const productToSave = formatCartItemToSave(productToAdd);
			const profile = yield select(getProfile);

			if (profile) {
				const newCart = [productToSave];

				yield call(httpCart.saveCart, newCart);
			}

			yield put(types.addProductSuccess(productToAdd));
		} else {
			yield put(types.addProductFailure());
		}
	} catch (error) {
		console.log(error);

		yield put(types.addProductFailure());
	}
}

function* onDeleteProduct({payload: product}: ActionType) {
	const formatCartItemToSave = (cartItem: SelectedProduct): CartItem => ({
		productId: cartItem.product.id,
		productQuantityId: cartItem.quantity.id,
		productCount: 1
	});

	try {
		const profile = yield select(getProfile);

		if (profile) {
			const productToDelete = formatCartItemToSave(product);

			yield call(httpCart.deleteProduct, productToDelete);
		}
		yield put(patientTypes.selectPromoCode(null));
		yield put(types.deleteProductSuccess(product));
	} catch (error) {
		console.log(error);
		yield put(types.deleteProductFailure());
	}
}

function* onUpdateProduct({payload: product}: ActionType) {
	const formatCartItemToSave = (cartItem: SelectedProduct): CartItem => ({
		productId: cartItem.product.id,
		productQuantityId: cartItem.quantity.id,
		productCount: 1
	});

	try {
		const profile = yield select(getProfile);

		if (profile) {
			const productToUpdate = formatCartItemToSave(product);

			yield call(httpCart.saveCart, [productToUpdate]);
		}

		yield put(types.updateProductSuccess(product));
	} catch (error) {
		yield put(types.updateProductFailure());
	}
}

const authSagas: Effect[] = [
	takeLatest(types.makeSubscriptionRequest, onMakeSubscription),
	takeLatest(types.getCartRequest, onGetCart),
	takeLatest(types.saveCartRequest, onSaveCart),
	takeLatest(types.addProductRequest, onAddProduct),
	takeLatest(types.deleteProductRequest, onDeleteProduct),
	takeLatest(types.updateProductRequest, onUpdateProduct)
];

export default authSagas;
