import {handleActions, createActions} from "redux-actions";
import initialState, * as handlers from "./handlers";

export const actions = createActions({
	SHOW_MODAL: undefined,
	HIDE_MODAL: undefined,
});

const reducer = handleActions(
	{
		[`${actions.showModal}`]: handlers.showModal,
		[`${actions.hideModal}`]: handlers.hideModal,
	},
	initialState
);

export default reducer;
