import React, {useState} from "react";
import {Link, useHistory,useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Title } from "react-meta-elements";
import stars from "../../static/images/stars.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import handWave_small from "../../static/images/hand-waveing-small.png";
import handWave_large from "../../static/images/hand-waveing-large.png";
import choose_product from "../../static/images/choose-product.jpg";
import connect_doctor from "../../static/images/connect-doctor.jpg";
import take_control from "../../static/images/take-control.jpg";
import licenced_doctor from "../../static/images/licenced_doctor.png";
import refill from "../../static/images/refill.png";
import standard_shipping from "../../static/images/standard_shipping.png";
import whiteDoctor from "../../static/images/white-doctor-circle.png";
import close from "../../static/images/close.png";
// ed images
import insuranceWhite from "../../static/images/insurance.png";
import virtualWhite from "../../static/images/free-virtual.png";
import canadianWhite from "../../static/images/Canadian-produced-products-icon.png";

import diabetes from "../../static/images/diabetes.png";
import stressIcon1 from "../../static/images/stress-icon.png";
import kidney from "../../static/images/kidney.png";
import recreational from "../../static/images/recreational.png";
import cardiovascular from "../../static/images/cardiovascular.png";
import smoking from "../../static/images/smoking.png";
import sideEffect from "../../static/images/side-effect.png";
import ejaculatory from "../../static/images/ejaculatory.png";
import psychosocial from "../../static/images/psychosocial.png";
import physicalTrauma from "../../static/images/physical-trauma.png";
import viagara from "../../static/images/viagara-and-cialis.jpg";
import ed from "../../static/images/ed-101.jpg";
import "./styles.scss";
import {StateType} from "../../redux/types";
import Product from "../../model/Product";
import { actions as cartActions } from "../../redux/cart";
import {actions as appActions} from "../../redux/app";
import { Modal } from "react-bootstrap";
import {getProductImage, formatProductNameToURL} from "../../utils/helpers";




function ProductED() {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);

    const dispatch = useDispatch();
	const history = useHistory();
    const location = useLocation();
    const[selectedDose, setSelectedDose] = useState<number>(0);


    const { products } = useSelector(({ product, app }: StateType) => ({
		products: product.productItems,
		// shopScrollTarget: app.shopScrollTarget
	}));
    const handleAddProductToCart = (product: Product, selectedDose:any) => () => {
		const y = window.scrollY;
		const {pathname} = location;

		dispatch(appActions.setCloseSidebarDirection({pathname, y}));

        const quantity = product.quantities.find((q)=> q.id == selectedDose);
        const index = product.quantities.findIndex((q)=> q.id == selectedDose);
        product.qty = {...quantity!};        
        product.selectedQuantityIndex = index!;

           

		history.push("/cart");

		dispatch(cartActions.addProductRequest({product}));        
	};
    const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
    
	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);

	const [ingredient, setIngredient] = useState<string>("");
	const handleShow1 = (text:string) => {
		setShow1(true)
		setIngredient(text);
	}	
    const handleSelectChange = (event: any) => {
        setSelectedDose(event.target.value);
    }	
	const [safeInfo, setSafeInfo] = useState<string>("");
	const handleShow2 = (text:string) => {
		setShow2(true)
		setSafeInfo(text);
	}

    // Getting utm source and medium from query params
	let search = window.location.search;
	if(search!=""){
		window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		console.log(search.split('?')[1]);
	}

     return(
    <div>
<Title>Erectile Dysfunction(ED) med |Winston</Title>
<div id="factPageED">
 
 <div className="page-101"> 

{/* modal starts here */}
         
			
{/* modal ends here */}

 <div className="section">
     <div className="container-12 w-container">
     <h1 className="heading">Is ED Ruining The Fun?<br/></h1>
     <div className="w-row">
         <div className="column-109 w-col w-col-8">
         <p className="paragraph">Order online prescription ED meds and have them delivered directly to your door. Have a virtual consultation with our licensed Canadian doctor to get prescribed. <br/></p>
         <div className="columns w-row">
         <div className="column w-col w-col-4"><img src={insuranceWhite}  width="40" alt="insurance" />
             <h1 className="heading-2"><strong>Insurance<br/>accepted</strong><br/></h1>
             </div>
             <div className="column-2 w-col w-col-4"><img src={virtualWhite}  width="40" alt="virtual" />
             <h1 className="heading-3"><strong>Free virtual <br/>consultation</strong><br/></h1>
             </div>
             <div className="column-3 w-col w-col-4"><img src={canadianWhite}  width="40" alt="canadian" />
             <h1 className="heading-4"><strong>Canadian produced<br/>ED meds</strong><br/></h1>
             </div>
         </div>
         </div>
         <div className="w-col w-col-4"></div>
     </div>
     </div>
 </div>

 <div className="section-1">
            <div className="container-11 w-container">
                 <h1 className="heading-5">About Our ED Medications<br/></h1>
                 <p className="paragraph-14">At Winston, we offer two of the most effective erectile dysfunction medications - Tadalafil (Active ingredient in Cialis) and Sildenafil (Active ingredient in Viagra). These safe ED medications will help you get and maintain an erection for as long as you need to.<br/></p>
            </div> 
</div>
  {
       products.filter(product=> product.problemCategory.includes('ED')).map(productitems=>
        (
        <section id="1st-section" className="section-1" key={productitems.id}>
          <div className="container-6 w-container">
            <div className="columns-30 w-row">
              <div className="column-89 w-col w-col-6"><img src={getProductImage(productitems.name)} width="394"  alt={productitems.name} className="image-2" /></div>
                <div className="column-45 w-col w-col-6">
                  <h1 className="heading-10">{productitems.name}</h1>
                  <h1 className="heading-11"><strong>{productitems.subName}</strong><br/></h1>
                  <div className="paragraph-2 m-30px-b" dangerouslySetInnerHTML={{__html:productitems.landingPageText}} />
                  <div className="columns-31 w-row">
                   <div className="w-col w-col-6">
             <span onClick={()=>handleShow1(productitems.ingredient)} className="button-14 w-button"><strong className="bold-text">Ingredients</strong></span>
             <Modal show={show1} onHide={handleClose1} animation={false} id="factPageED">
            <div className="div-block-55">
				<div className="div-block-56">
				<div className="div-block-45" onClick={handleClose1}><img src={close}  width="25" alt="" /></div>
                    <h1 className="heading-96">Ingredients</h1>
                    <div dangerouslySetInnerHTML={{__html:ingredient}}/>
                </div>
			</div>    
            </Modal>
             </div>
             <div className="w-col w-col-6">
             <span  onClick={()=>handleShow2(productitems.safetyInfo)} className="button-8 w-button"><strong>Safety Info</strong></span>
             <Modal show={show2} onHide={handleClose2} animation={false} id="factPageED">
            <div className="div-block-43">
				<div className="div-block-44">
				<div className="div-block-45" onClick={handleClose2}><img src={close}  width="25" alt="close" /></div>	
                    <h1 className="heading-60"><strong>Safety information details</strong><br/></h1>			
                    <div  dangerouslySetInnerHTML={{__html:safeInfo}}/>
                   
				</div>
			</div>
            </Modal>
             </div>
         </div>
         <Link to={`/${formatProductNameToURL(productitems.name)}`} className="button-15 w-button" >
             <span className="">Learn more</span>
        </Link>
         <div className="div-block-20">
             <h1 className="heading-12">Selects dose and quantity:</h1>
             <div className="form-block-2 w-form">
             <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
                 <select id="field-2" name="field-2" className="select-field-2 w-select" 
                    value={selectedDose>0?selectedDose:productitems.quantities[0].id} 
                    onChange={(event)=>handleSelectChange(event)}>
                    {productitems.quantities.sort((a, b) => a.id > b.id ? 1:-1).map((index)=>(
                        <option key={index.id} value={index.id}>{index.quantity}</option>
                    ))}
                </select>
            </form>
             <div className="w-form-done">
                 <div>Thank you! Your submission has been received!</div>
             </div>
             <div className="w-form-fail">
                 <div>Oops! Something went wrong while submitting the form.</div>
             </div>
             </div>
             <div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
             <button onClick={handleAddProductToCart(productitems, selectedDose>0?selectedDose:productitems.quantities[0].id)} className="button-9 w-button">
                 <strong>Add to cart</strong>
            </button>
         </div>
         </div>
     </div>
     </div>
    </section>             
    )
 )
}
 <div className="section-2 canada">
     <div className="container-16 w-container">
     <div className="w-row">
         <div className="column-52 w-col w-col-9">
         <h1 className="heading-53">Our treatments are proudly sourced from trusted Canadian manufacturers.<br/></h1>
         <h1 className="heading-98">Pharma Science, Jamp Pharma, Apotex<br/></h1>
         </div>
         <div className="column-16 w-col w-col-3">
             <img className="image-25" src={handWave_small} sizes="100vw"
						srcSet={`${handWave_large} 1024w, ${handWave_large} 767w, ${handWave_small} 479w`} width="79"  alt="handwave" /></div>
     </div>
     </div>
 </div>
 <div className="section-4">
     <div className="container-8 w-container">
     <h1 className="heading-75">What Causes Erectile Dysfunction?</h1>
     <p className="paragraph-8">The most common risk factors include:<br/></p>
     <div className="columns-60 w-row">
         <div className="column w-col w-col-6"><img src={diabetes}  width="40" alt="diabetes" />
         <h1 className="heading-74">Diabetes</h1>
         </div>
         <div className="column-100 w-col w-col-6"><img src={stressIcon1}  width="40" alt="stressIcon1" />
         <h1 className="heading-74">Stress</h1>
         </div>
     </div>
     <div className="columns-64 w-row">
         <div className="column-107 w-col w-col-6"><img src={kidney}  width="40" alt="kidney" className="image-15" />
         <h1 className="heading-15">Chronic kidney disease</h1>
         </div>
         <div className="column-107 w-col w-col-6"><img src={recreational}  width="40" alt="recreational" />
         <h1 className="heading-74">Recreational drug use</h1>
         </div>
     </div>
     <div className="columns-64 w-row">
         <div className="column-103 w-col w-col-6"><img src={cardiovascular}  width="40" alt="cardiovascular" />
         <h1 className="heading-74">Cardiovascular disease</h1>
         </div>
         <div className="column-107 w-col w-col-6"><img src={smoking}  width="40" alt="smoking" />
         <h1 className="heading-74">Smoking</h1>
         </div>
     </div>
     <div className="columns-64 w-row">
         <div className="column-107 w-col w-col-6"><img src={sideEffect}  width="40" alt="sideEffect" />
         <h1 className="heading-74">Side effects of medications</h1>
         </div>
         <div className="column-107 w-col w-col-6"><img src={ejaculatory}  width="40" alt="ejaculatory" />
         <h1 className="heading-74">Ejaculatory disorders</h1>
         </div>
     </div>
     <div className="columns-64 w-row">
         <div className="column-107 w-col w-col-6"><img src={psychosocial}  width="40" alt="psychosocial" />
         <h1 className="heading-74">Psychosocial factors</h1>
         </div>
         <div className="column-107 w-col w-col-6"><img src={physicalTrauma}  width="40" alt="physicalTrauma" />
         <h1 className="heading-74">Physical trauma</h1>
         </div>
     </div>
     </div>
 </div>
 <div className="section-5">
     <div className="container-3 w-container">
     <h1 className="heading-75">How Winston Works<br/></h1>
     <p className="paragraph-13">Our goal is to make healthcare accessible and medications affordable in 3 easy steps.<br/></p>
     <div className="w-row">
         <div className="column-51 w-col w-col-4">
         <div className="div-block-59"><img src={choose_product}  alt="product" />
             <h1 className="heading-95">1. Choose a product<br/></h1>
             <p className="paragraph-12">Pick from our collection of safe and effective medication based on your symptoms and health needs.</p>
         </div>
         </div>
         <div className="column-51 w-col w-col-4">
         <div className="div-block-25"><img src={connect_doctor}  alt="doctor" className="image-16" />
             <h1 className="heading-55">2. Connect with a doctor<br/></h1>
             <p className="paragraph-11">A licensed doctor will speak to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br/></p>
         </div>
         </div>
         <div className="column-52 w-col w-col-4">
         <div className="div-block-26"><img src={take_control}  alt="control" />
             <h1 className="heading-55">3. Take control<br/></h1>
             <p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br/></p>
         </div>
         </div>
     </div>
     </div>
 </div>
 <div className="section-6">
     <div className="container-4 w-container">
     <h1 className="heading-53">With Winston’s Auto-Refill You Get:<br/></h1>
     <h1 className="heading-45 mob-hide">Let Winston be your wingman and he’ll always deliver!</h1>
     <div className="columns-25 w-row">
         <div className="column-48 w-col w-col-4">
         <div className="div-block-38"><img src={licenced_doctor}  alt="doctor" className="image-18" />
             <p className="paragraph-6">Custom treatment plan by licensed doctors</p>
         </div>
         </div>
         <div className="column-48 w-col w-col-4">
         <div className="div-block-38"><img src={refill}  alt="refill" />
             <h1 className="heading-44">Skip, pause or cancel your refills at any time</h1>
         </div>
         </div>
         <div className="column-49 w-col w-col-4">
         <div className="div-block-40"><img src={standard_shipping}  alt="shipping" />
             <h1 className="heading-44">Free standard shipping right to your door</h1>
         </div>
         </div>
     </div>
     <h1 className="heading-45 mob-show">Let Winston be your wingman and he’ll always deliver!</h1>
     </div>
 </div>
 <div className="slider w-slider">
 <Carousel>
					<Carousel.Item interval={200}>
						
						<Carousel.Caption>
							<div className="columns-65 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-14 w-col w-col-8">
								<h1 className="heading-26">Amazing concept and super easy process. End to end!<br/></h1><img src={stars}  alt="stars" className="image-14" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={4000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Dr. very friendly, answered all my concerns.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, In Consultation, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={6000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Works well and is more classy and discreet compared to the blue pill.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={8000}>
						<Carousel.Caption>
							<div className="columns-2 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">I’m so happy with the results, the doctor and the convenience!<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, On Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					</Carousel>
 </div>
 
<div className="section-7">
				<div className="container-5 w-container">
				<h1 className="heading-39">Common Questions about ED<br/></h1>
			    <div className="div-block-46 imgBlock" onClick={() => setOpen(!open)}  aria-expanded={open}>
					<h1 className="heading-34"><strong>Can I get brand name ED medication?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow" />
				</div>
				<Collapse in={open}>
				<div>
					<p className="paragraph-13">At this time we currently only offer the generic version of popular ED medications given their affordability and how effective they are at treating come symptoms of ED. Most pharmacies typically will dispense the generics as well for this reason unless specifically indicated on a prescription to do so otherwise.<br/></p>
				</div>
				</Collapse>

		 		<div className="div-block-34 imgBlock"  onClick={() => setOpen1(!open1)}  aria-expanded={open1}>
					<h1 className="heading-37"><strong>Are prescription ED medications effective?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow" />
				</div>
				<Collapse in={open1}>
				<div className="div-block-31">
					<p className="paragraph-2">ED medications can be very effective in treating common symptoms of ED. The response to a particular medication varies from each individual given their medical conditions, other medications they may be taking and their own unique way of absorbing and metabolizing a medication.<br/></p>
				</div>
				</Collapse>

				<div className="div-block-30 imgBlock"  onClick={() => setOpen2(!open2)}  aria-expanded={open2}>
					<h1 className="heading-37"><strong>Are there other treatments?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon}  width="35" alt="arrow" />
				</div>
				<Collapse in={open2}>
				<div >
					<p className="paragraph-2">There are several treatment options for ED, some of which include: <br/>Oral medications.<br/>Sex therapy.<br/>Penile injections.<br/>Vacuum devices.<br/>Intraurethral medication.<br/>Surgery (penile implant).<br/></p>
				</div>
				</Collapse>

				<div className="div-block-48 imgBlock" onClick={() => setOpen3(!open3)}  aria-expanded={open3}>
					<h1 className="heading-37"><strong>Why do I need to know my blood pressure before getting prescribed ED medication?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow"/>
				</div>

				<Collapse in={open3}>
				<div className="div-block-31">
					<p className="paragraph-2">It is important to understand your cardiovascular health prior to getting prescribed medications for ED. High blood pressure can not only contribute to ED, but medications taken for high blood pressure could also lead to additional side effects while taking ED meds. Talk to one of our doctors to learn more.<br/></p>
				</div>
				</Collapse>

				<div className="div-block-48 imgBlock"  onClick={() => setOpen4(!open4)}  aria-expanded={open4}>
					<h1 className="heading-37"><strong>How much does ED medication cost?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon} width="35" alt="arrow" />
				</div>
				<Collapse in={open4}>
				<div className="div-block-31">
					<p className="paragraph-2">The price will vary depending on the medication you choose and the quantity of pills. </p>
				</div>
				</Collapse>

				<div className="div-block-48 imgBlock" onClick={() => setOpen5(!open5)}  aria-expanded={open5}>
					<h1 className="heading-37"><strong>Am I covered by my insurance?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon} width="35" alt="arrow" />
				</div>
				<Collapse in={open5}>
				<div className="div-block-31">
					<p className="paragraph-2">Insurance coverage is based on your insurance policy and is dependent on your selected medication. We recommend that you upload your insurance card during registration so that our pharmacy partner can confirm your coverage for the prescribed medication.<br/><br/>Alternatively, you can wait until your order is received and submit your receipt to your insurance provider for reimbursement.<br/></p>
				</div>
				</Collapse>

				<div className="div-block-48 imgBlock" onClick={() => setOpen6(!open6)}  aria-expanded={open6}>
					<h1 className="heading-37"><strong>Why am I experiencing ED?</strong><br/></h1>
                    <img className="imgRotate" src={arrow_icon} width="35" alt="arrow" />
				</div>
				<Collapse in={open6}>
				<div className="div-block-31">
					<p className="paragraph-2">There are many medical conditions that can lead to ED or symptoms suggesting ED. This is why it is important to ensure you are checking in with your doctor regularly for routine health assessments. Connect with one of our doctor's to discuss possible factors contributing to your symptoms.<br/></p>
				</div>
				</Collapse>


				<Link to="/faq"  className="button-17 w-button"><strong>See more FAQs</strong></Link>
				</div>
</div>

 <div className="section-2">
     <div className="container-15 w-container">
     <div className="columns-5 w-row">
         <div className="column-114 w-col w-col-9">
         <h1 className="heading-46">Not Sure What Product You Need?<br/></h1>
         <p className="paragraph-2">Book a free consultation call with a Canadian doctor to advise you!<br/></p>
         <Link to="/contact-us"
			// target="_blank"
			// rel="noopener noreferrer"
           className="button-18 w-button"><strong>Let us help you</strong><br/>
         </Link>
         </div>
         <div className="column-113 w-col w-col-3"><img src={whiteDoctor}  width="212" alt="doctor" className="image-20" /></div>
     </div>
     </div>
 </div>
 <div className="section-10">
     <div className="container-8 w-container">
     <h1 className="heading-52">Blog Articles<br/></h1>
     <div className="w-row">
         <div className="column-71 w-col w-col-6">
         <div className="div-block-60 blogFirstBg"></div>
         <div className="div-block-16">
             <h1 className="heading-47">ED 101<br/></h1>
             <p className="paragraph-4">Sexual dysfunction can include erectile dysfunction, decreased libido and abnormal ejaculation. Specifically, erectile dysfunction is the difficulty of achieving or maintaining an erection to an extent that it affects sexual intercourse…<br/></p>
             <div className="div-block-22">
             <a href="https://learn.askwinston.ca/ed-101/" className="button-13 w-button">
                 <strong>Learn more</strong><br/>
             </a>             
             <div className="text-block-5">5 min read</div>
             </div>
         </div>
         </div>
         <div className="column-52 w-col w-col-6">
         <div className="div-block-21 blogSecondBg" ></div>
         <div className="div-block-17">
             <h1 className="heading-48">Viagra vs. Cialis<br/></h1>
             <p className="paragraph-4">At Winston, we want you to understand not only what ED treatments we carry but also how they work. This video will give you a basic overview of the differences between Sildenafil (the active ingredient in Viagra) and Tadalafil (the active ingredient in Cialis) to help you choose the best treatment…<br/></p>
             <div className="div-block-23">
             <a href="https://learn.askwinston.ca/viagra-vs-cialis/" className="button-13 w-button">
                 <strong>Learn more</strong><br/>
             </a>
             <div className="text-block-6">5 min read</div>
             </div>
         </div>
         </div>
     </div>
     </div>
 </div>
 </div>
 </div>
 </div>
     )
}
export default ProductED;
