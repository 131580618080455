import React, {useState} from "react";
import {Link , useHistory,useLocation} from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Title } from "react-meta-elements";
import stars from "../../static/images/stars.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import insurance from "../../static/images/insurance.png";
import virtual from "../../static/images/free-virtual.png";
import canadian from "../../static/images/Canadian-produced-products-icon.png";
import handWave_small from "../../static/images/hand-waveing-small.png";
import handWave_large from "../../static/images/hand-waveing-large.png";
import choose_product from "../../static/images/choose-product.jpg";
import connect_doctor from "../../static/images/connect-doctor.jpg";
import take_control from "../../static/images/take-control.jpg";
import licenced_doctor from "../../static/images/licenced_doctor.png";
import refill from "../../static/images/refill.png";
import standard_shipping from "../../static/images/standard_shipping.png";
import whiteDoctor from "../../static/images/white-doctor-circle.png";
import close from "../../static/images/close.png";
// cs images
import skinContact from "../../static/images/skin-contact.png";
import unprotected from "../../static/images/unprotected-sex.png";
import immunosuppression from "../../static/images/immunosuppression.png";
import infectedContact from "../../static/images/infected-contact.png";
import kissing from "../../static/images/kissing.png";
import medication1 from "../../static/images/medication.png";
import sharing1 from "../../static/images/sharing.png";
import fcd from "../../static/images/fcd-blog.jpg";
import {useDispatch, useSelector} from "react-redux";
import {StateType} from "../../redux/types";
import { actions as cartActions } from "../../redux/cart";
import {actions as appActions} from "../../redux/app";
import Product from "../../model/Product";
import { Modal } from "react-bootstrap";
import {getProductImage, formatProductNameToURL} from "../../utils/helpers";
import "./styles.scss";

const ProductCS = () => {

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
    const location = useLocation();

	const handleAddProductToCart = (product: Product) => () => {
		const y = window.scrollY;
		const {pathname} = location;

		dispatch(appActions.setCloseSidebarDirection({pathname, y}));

		history.push("/cart");

		dispatch(cartActions.addProductRequest({product}));
	};
	const { products } = useSelector(({ product, app }: StateType) => ({
		products: product.productItems,
		// shopScrollTarget: app.shopScrollTarget
	}));

	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	
	  
	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);
	
	const [ingredient, setIngredient] = useState<string>("");
	const handleShow1 = (text:string) => {
		setShow1(true)
		setIngredient(text);
	}		
	const [safeInfo, setSafeInfo] = useState<string>("");
	const handleShow2 = (text:string) => {
		setShow2(true)
		setSafeInfo(text);
	}	

	// Getting utm source and medium from query params
	let search = window.location.search;
	if(search!=""){
		window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		console.log(search.split('?')[1]);
	}

    return (
        <>
				<Title>Cold Sores meds  |Winston</Title>
				<div id="factPageCS">
			<div className="page-101" >

			{/* modal starts here  */}
			
			
			{/* modal ends here */}

			<div className="section">
				<div className="container-12 w-container">
				<h1 className="heading">Control Your Cold Sores<br/></h1>
				<div className="w-row">
					<div className="column-109 w-col w-col-7">
					<p className="paragraph">Winston has the right treatments for you, whether you are newly infected or battling a recurring cold sore breakout. <br/>Get your groove back.<br/></p>
					</div>
					<div className="w-col w-col-5"></div>
				</div>
				<div className="w-row">
					<div className="column-117 w-col w-col-8">
					<div className="columns w-row">
						<div className="column w-col w-col-4"><img src={insurance}  width="40" alt="insurance" className="image-21" />
						<h1 className="heading-3"><strong>Insurance<br/>accepted</strong><br/></h1>
						</div>
						<div className="column w-col w-col-4"><img src={virtual}  width="40" alt="virtual" className="image-23" />
						<h1 className="heading-3"><strong>Free virtual <br/>consultation</strong><br/></h1>
						</div>
						<div className="column w-col w-col-4"><img src={canadian} width="40" alt="canadian" className="image-24" />
						<h1 className="heading-3"><strong>Canadian produced<br/>medications</strong><br/></h1>
						</div>
					</div>
					</div>
					<div className="w-col w-col-4"></div>
				</div>
				</div>
			</div>
			<div className="section-1">
				<div className="container-11 w-container">
				<h1 className="heading-5">About Our Cold Sores Medications<br/></h1>
				<p className="paragraph-14">Oral herpes also called cold sores, are caused by a highly contagious virus, Herpes Simplex Virus (HSV). Winston&#x27;s licensed doctors prescribe safe and effective antiviral medications such as Valacyclovir and topical Acyclovir to treat cold sores. These medications work by inhibiting the spread of the virus, decreasing the length of outbreaks, and helping the sores heal faster.<br/></p>
				</div>
			</div>
			{
       products.filter(product=> product.problemCategory.includes('CS')).map(productitems=>
        (
			<section id="1st-section" className="section-1" key={productitems.id}>
				<div className="container-12 w-container">
				<div className="w-row">
					<div className="column-109 w-col w-col-6"><img src={getProductImage(productitems.name)}  width="394" alt="product" className="image-3" /></div>
					<div className="column-109 w-col w-col-6">
					<h1 className="heading-14">{productitems.name}<br/></h1>
					<h1 className="heading-11"><strong>{productitems.subName}</strong><br/></h1>
					<div className="paragraph-14 m-30px-b" dangerouslySetInnerHTML={{__html:productitems.landingPageText}} />
					<div className="w-row">
						<div className="w-col w-col-6">
						<span onClick={()=>handleShow1(productitems.ingredient)} className="button-14 w-button">
							<strong className="bold-text">Ingredients</strong></span>
						<Modal show={show1} onHide={handleClose1} animation={false} id="factPageCS">
			               <div className="div-block-55">
			             	 <div className="div-block-56">
			                 	<div className="div-block-45" onClick={handleClose1}><img src={close}  width="25" alt="close" /></div>
								 <h1 className="heading-77">Ingredients</h1>
				                <div dangerouslySetInnerHTML={{__html:ingredient}}/>
				             </div>
		                	</div>
			            </Modal>
						</div>
						<div className="w-col w-col-6">
						<span onClick={()=>handleShow2(productitems.safetyInfo)} className="button-8 w-button">
							<strong>Safety Info</strong></span>
						<Modal show={show2} onHide={handleClose2} animation={false} id="factPageCS">
		                	<div className="div-block-63">
				              <div className="div-block-44">
				                <div className="div-block-45" onClick={handleClose2}><img src={close}  width="25" alt="close" /></div>
								<h1 className="heading-60"><strong>Safety information details</strong><br/></h1>
				                <div dangerouslySetInnerHTML={{__html:safeInfo}}/>
				              </div>
			                </div>	
			            </Modal>
						</div>
					</div>
					<Link to={`/${formatProductNameToURL(productitems.name)}`} className="button-15 w-button" >
                        <span className="">Learn more</span>
                    </Link>
					<div className="div-block-19">
						<h1 className="heading-12">Monthly supply starting at  ${Number(productitems.startingAtPrice).toFixed() || 0}</h1>
						<div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
						<span onClick={handleAddProductToCart(productitems)} className="button-9 w-button"><strong>Add to cart</strong></span>
					</div>
					</div>
				</div>
				</div>
			</section>
		)
	   )
		}
			<div className="section-2 canada">
				<div className="container-12 w-container">
				<div className="w-row">
					<div className="w-col w-col-9">
					<h1 className="heading-6">Our treatments are proudly sourced from trusted Canadian manufacturers.<br/></h1>
					<h1 className="heading-100">Apotex Pharmaceutical<br/></h1>
					</div>
					<div className="column-16 w-col w-col-3"><img src={handWave_small} sizes="100vw"
						srcSet={`${handWave_large} 1024w, ${handWave_large} 767w, ${handWave_small} 479w`}  width="86" alt="hand" /></div>
				</div>
				</div>
			</div>
			<div className="section-4">
				<div className="container-12 w-container">
				<h1 className="heading-75">Causes and Risk Factors of Cold Sores<br/></h1>
				<p className="paragraph-8">The most common risk factors include:<br/></p>
				<div className="columns-60 w-row">
					<div className="column w-col w-col-6"><img src={skinContact} width="40" alt="skin" />
					<h1 className="heading-96">Prolonged skin to skin contact</h1>
					</div>
					<div className="column-100 w-col w-col-6"><img className="image-25" src={unprotected}  width="40" alt="unprotected" />
					<h1 className="heading-96">Unprotected sex</h1>
					</div>
				</div>
				<div className="columns-66 w-row">
					<div className="column-107 w-col w-col-6">
					<h1 className="heading-77"><strong className="bold-text-3">HIV </strong></h1>
					<h1 className="heading-82">Human immunodeficiency viruses</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={immunosuppression}  width="40" alt="hiv" />
					<h1 className="heading-82">Immunosuppression</h1>
					</div>
				</div>
				<div className="columns-66 w-row">
					<div className="column-103 w-col w-col-6"><img src={infectedContact}  width="40" alt="infected" />
					<h1 className="heading-82">Contact with infected saliva,<br/>mucous membranes, skin</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={kissing}  width="40" alt="kissing" className="image-22" />
					<h1 className="heading-79">Kissing</h1>
					</div>
				</div>
				<div className="columns-66 w-row">
					<div className="column-107 w-col w-col-6"><img src={medication1}  width="40" alt="medication" />
					<h1 className="heading-82">Certain medications i.e. drugs that<br/>can suppress your immune system</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={sharing1} width="30" height="50" alt="sharing" />
					<h1 className="heading-80">Sharing items like razors</h1>
					</div>
				</div>
				</div>
				<div className="container-16 w-container">
				<h1 className="heading-97">Herpes Virus Reactivates</h1>
				<p className="paragraph-15">After primary infection, the herpes virus travels from the infection site to a collection of nerve cells located near the spinal cord, where it stays dormant in the latency phase until it is reactivated by a trigger. Triggers such as stress (emotionally or physically), increased sun or UV exposure, exposure to extreme heat or cold, infection or an illness, hormonal changes, or fatigue can trigger a reactivation and breakout of cold sores.</p>
				</div>
			</div>
			<div className="section-5">
				<div className="container-3 w-container">
				<h1 className="heading-75">How Winston Works<br/></h1>
				<p className="paragraph-13">Our goal is to make healthcare accessible and medications affordable in 3 easy steps.<br/></p>
				<div className="w-row">
					<div className="column-51 w-col w-col-4">
					<div className="div-block-59"><img src={choose_product}  alt="product" />
						<h1 className="heading-95">1. Choose a product<br/></h1>
						<p className="paragraph-12">Pick from our collection of safe and effective medication based on your symptoms and health needs.</p>
					</div>
					</div>
					<div className="column-51 w-col w-col-4">
					<div className="div-block-25"><img src={connect_doctor} alt="doctor" className="image-16" />
						<h1 className="heading-55">2. Connect with a doctor<br/></h1>
						<p className="paragraph-11">A licensed doctor will speak to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br/></p>
					</div>
					</div>
					<div className="column-109 w-col w-col-4">
					<div className="div-block-26"><img src={take_control} alt="control" />
						<h1 className="heading-55">3. Take control<br/></h1>
						<p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br/></p>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="section-6">
				<div className="container-4 w-container">
				<h1 className="heading-6">With Winston’s Auto-Refill You Get:<br/></h1>
				<h1 className="heading-45 mob-hide">Let Winston be your wingman and he’ll always deliver!</h1>
				<div className="columns-25 w-row">
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={licenced_doctor}  alt="doctor" className="image-18" />
						<p className="paragraph-6">Custom treatment plan by licensed doctors</p>
					</div>
					</div>
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={refill} alt="refill" />
						<h1 className="heading-44">Skip, pause or cancel your refills at any time</h1>
					</div>
					</div>
					<div className="column-49 w-col w-col-4">
					<div className="div-block-40"><img src={standard_shipping} alt="shipping" />
						<h1 className="heading-44">Free standard shipping right to your door</h1>
					</div>
					</div>
				</div>
				<h1 className="heading-45 mob-show">Let Winston be your wingman and he’ll always deliver!</h1>
				</div>
			</div>
			<div className="slider w-slider">
			<Carousel>
					<Carousel.Item interval={2000}>
						
						<Carousel.Caption>
							<div className="columns-65 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-14 w-col w-col-8">
								<h1 className="heading-26">Amazing concept and super easy process. End to end!<br/></h1><img src={stars}  alt="stars" className="image-14" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={4000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Dr. very friendly, answered all my concerns.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, In Consultation, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={6000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Works well and is more classy and discreet compared to the blue pill.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={8000}>
						<Carousel.Caption>
							<div className="columns-2 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">I’m so happy with the results, the doctor and the convenience!<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, On Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					</Carousel>
			</div>
			<div className="section-7">
				<div className="container-5 w-container collapsePanel">
				<h1 className="heading-39">Common Questions about Cold Sores<br/></h1>
				<div onClick={() => setOpen(!open)}  aria-expanded={open}  className="div-block-46 imgBlock">
					<h1 className="heading-34"><strong>What are cold sores?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>
				<Collapse in={open}>
				<div >
					<p className="paragraph-13">They are blisters caused by contracting the Herpes Simplex Virus (HSV), which typically affects the mouth and other mucosal areas of the body. They may be accompanied by other symptoms like tingling and pain at the site.<br/></p>
				</div>
				</Collapse>

				<div className="div-block-34 imgBlock" onClick={() => setOpen1(!open1)}  aria-expanded={open1}>
					<h1 className="heading-37"><strong>What are the most common causes of cold sores?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>

				<Collapse in={open1}>
				<div  className="div-block-31">
					<p className="paragraph-14">Common causes include contact with infected saliva, mucous membranes, skin, unprotected sex, sharing items like razors, kissing, and prolonged skin to skin contact. For a complete list of risk factors as well as causes, please refer to Cold Sores 101 in the Learn section.<br/></p>
				</div>
				</Collapse>

				<div onClick={() => setOpen2(!open2)}  aria-expanded={open2}  className="div-block-30 imgBlock">
					<h1 className="heading-37"><strong>What treatment options do you currently provide for cold sores?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>
				<Collapse in={open2}>
				<div >
					<p className="paragraph-14">Currently, we offer two treatments, an oral medication, Valacyclovir, and a topical cream, Acyclovir</p>
				</div>
				</Collapse>

				<div onClick={() => setOpen3(!open3)}  aria-expanded={open3} className="div-block-48 imgBlock">
					<h1 className="heading-37"><strong>Do either of these treatments provide a cure for the Herpes Simplex Virus?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arrow-icon" />
				</div>
				<Collapse in={open3}>
				<div  className="div-block-31">
					<p className="paragraph-14">Both Valacyclovir and Acyclovir are treatment options to help reduce the recurrences, symptoms, and risk of transmission of the herpes virus, which can lead to oral sores or genital sores. However, it is not a cure for the Herpes Simplex Virus.</p>
				</div>
				</Collapse>

				<div onClick={() => setOpen4(!open4)}  aria-expanded={open4}  className="div-block-48 imgBlock">
					<h1 className="heading-37" ><strong>Do I require a prescription for these treatments?</strong><br/></h1><img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon" />
				</div>
				<Collapse in={open4}>
				<div  className="div-block-31">
					<p className="paragraph-14">Yes, our doctors can provide a prescription to one of our accredited pharmacy partners that can allow for quick and discreet delivery.</p>
				</div>
				</Collapse>

				<div onClick={() => setOpen5(!open5)}  aria-expanded={open5} className="div-block-48 imgBlock">
					<h1 className="heading-37"><strong>Am I covered by my insurance?<br/></strong></h1>
					<img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon" />
				</div>
				<Collapse in={open5}>
				<div  className="div-block-31">
					<p className="paragraph-14">Coverage varies based on individual insurance providers. If insurance information is uploaded to your account it will be verified by our pharmacy partner who will apply the coverage, if applicable.<br/></p>
				</div>
				</Collapse>

				<Link to="/faq"  className="button-17 w-button"><strong>See more FAQs</strong></Link>
				</div>
			</div>
			<div className="section-2">
				<div className="container-15 w-container">
				<div className="columns-5 w-row">
					<div className="column-114 w-col w-col-9">
					<h1 className="heading-46">Not Sure What Product You Need?<br/></h1>
					<p className="paragraph-14">Book a free consultation call with a Canadian doctor to advise you!<br/></p>
					<Link  to="/contact-us"
						// target="_blank"
						// rel="noopener noreferrer" 
						className="button-18 w-button"><strong>Let us help you</strong><br/></Link>
					</div>
					<div className="column-113 w-col w-col-3"><img src={whiteDoctor}  width="212" alt="white-doctor" className="image-20" /></div>
				</div>
				</div>
			</div>
			<div className="section-10">
				<div className="container-8 w-container">
				<h1 className="heading-52">Blog Articles<br/></h1>
				<div className="columns-67 w-row">
					<div className="column-115 w-col w-col-6">
					<div className="div-block-60 blogBackground" ></div>
					</div>
					<div className="column-109 w-col w-col-6">
					<div className="div-block-61">
						<h1 className="heading-98">Cold Sores 101<br/></h1>
						<p className="paragraph-4">Although there is no cure for the herpes virus, there are oral medications to help ease the symptoms as well as reduce the risk of spreading the virus. These treatments come under a group of medications known as antivirals. One of the most common antivirals used to treat herpes is Valacyclovir which is the generic form of Valtrex. <br/></p>
						<div className="div-block-23">
						<a href="https://learn.askwinston.ca/cold-sores-101/" className="button-13 w-button">
                         <strong>Learn more</strong><br/>
                        </a>  	
						<div className="text-block-6">5 min read</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
			</div>
			</>
    )
}
export default ProductCS;

