import React, {useState} from "react";
import ReactTooltip from "react-tooltip";
import {SlideDown} from "react-slidedown";

import OrderStatus from "../../../../model/OrderStatus";
import Order from "../../../../model/Order";

import MessageConst from "../../../../const/Message.const";

import SkipRefillIcon from "../../../../static/images/skipRefillIcon.jpg";
import EarlyRefillIcon from "../../../../static/images/earlyRefillIcon.jpg";
import SubscriptionDetailsArrow from "../../../../static/images/subscription-details-arrow.png";

import "./styles.scss";
import OrderInfoItem from "../OrderInfoItem";
import moment from "moment";

interface SubscriptionInfoItemProps {
	subscription: Order;
	subscriptionOrders: Order[];
	setShowSkipRefillDialog?: (subscription: Order) => void;
	setShowEarlyRefillDialog?: (subscription: Order) => void;
	setShowPauseDialog?: (subscription: Order) => void;
	setShowResumeDialog?: (subscription: Order) => void;
	setShowResumeCardFailedDialog?: (subscription: Order) => void;
}

const SubscriptionInfoItem = ({
	subscription,
	subscriptionOrders,
	setShowSkipRefillDialog,
	setShowEarlyRefillDialog,
	setShowPauseDialog,
	setShowResumeDialog,
	setShowResumeCardFailedDialog
}: SubscriptionInfoItemProps) => {
	const [open, setOpen] = useState<boolean>(false);

	const isShowRefillBtns =
		subscription.status === OrderStatus.active &&
		!(
			subscription.orders.findIndex(
				(orderItem) =>
					orderItem.status === OrderStatus.waitingPharmacist ||
					orderItem.status === OrderStatus.packaging
			) + 1
		);

	const renderSubscriptionOrders = () => {
		return subscriptionOrders.map((order) => <OrderInfoItem key={order.id} order={order} />);
	};

	const isPaused = [OrderStatus.paused, OrderStatus.pausedRxTransfer].includes(subscription.status);

	return (
		<div className="subscription-item">
			<div className={`top-row ${isPaused ? "big-width" : ""}`}>
				<div className="status">
					<div className="status-title">Subscription Status:</div>
					<div className="status-text">{MessageConst.orderStatus[subscription.status]}</div>
				</div>
				{(subscription.status === OrderStatus.paused ||
					subscription.status === OrderStatus.pausedRxTransfer) && (
					<div className="pause-reason">(Credit card failure)</div>
				)}
				{subscription.status === OrderStatus.pausedByPatient && (
					<div className="pause-reason">(You have paused your order)</div>
				)}
			</div>
			{isShowRefillBtns && (
				<div className="refill-actions-btns">
					<ReactTooltip
						className="info-tooltip"
						overridePosition={({left, top}, _e, _t, node) => {
							return {
								top,
								left: typeof node === "string" ? left : Math.max(left, 0)
							};
						}}
					/>
					<div className="earlier-refill-block">
						<div
							className="earlier-refill-btn"
							onClick={() => setShowEarlyRefillDialog!(subscription)}
						>
							<img src={EarlyRefillIcon} alt="" />

							<span>Early Refill</span>
						</div>
						<div
							className="tooltip-icon"
							data-tip="If you are taking your medication more often than expected you can get your next refill earlier than the original refill date by pressing get refill earlier. Tip: If you find your medication isn’t lasting you until your next scheduled refill you may want to increase your order to a larger pack size."
						>
							i
						</div>
					</div>

					<div className="skip-refill-block">
						<div className="skip-refill-btn" onClick={() => setShowSkipRefillDialog!(subscription)}>
							<img src={SkipRefillIcon} alt="" />
							<span>Skip Refill</span>
						</div>
						<div
							className="tooltip-icon"
							data-tip="If for some reason you don’t need your next refill as scheduled, you can skip your next refill by pressing skip refill. By skipping a refill, you will still have access to your refills."
						>
							i
						</div>
					</div>

					<div className="pause-block">
						<div className="pause-btn" onClick={() => setShowPauseDialog!(subscription)}>
							<div className="pause-icon"></div>
							<span>Pause</span>
						</div>
						<div
							className="tooltip-icon"
							data-tip="Pausing your subscription means you will not be receiving any more refills until you reactivate it within the expiration date. If you pause within 7 days by the upcoming refill date, then your changes will take effect on all future orders moving forward."
						>
							i
						</div>
					</div>
				</div>
			)}
			{(subscription.status === OrderStatus.paused ||
				subscription.status === OrderStatus.pausedRxTransfer) && (
				<div className="refill-actions-btns">
					<ReactTooltip
						className="info-tooltip"
						overridePosition={({left, top}, _e, _t, node) => {
							return {
								top,
								left: typeof node === "string" ? left : Math.max(left, 0)
							};
						}}
					/>
					<div className="resume-block">
						<div className="play-btn" onClick={() => setShowResumeCardFailedDialog!(subscription)}>
							<div className="play-icon"></div>
							<span>Resume</span>
						</div>
						<div
							className="tooltip-icon"
							data-tip="Before re-activating your subscription and receive your next refill, you will need to update your credit card information."
						>
							i
						</div>
					</div>
				</div>
			)}

			{subscription.status === OrderStatus.pausedByPatient && (
				<div className="refill-actions-btns">
					<ReactTooltip
						className="info-tooltip"
						overridePosition={({left, top}, _e, _t, node) => {
							return {
								top,
								left: typeof node === "string" ? left : Math.max(left, 0)
							};
						}}
					/>
					<div className="resume-block">
						<div className="play-btn" onClick={() => setShowResumeDialog!(subscription)}>
							<div className="play-icon"></div>
							<span>Resume</span>
						</div>
						<div
							className="tooltip-icon"
							data-tip="Resuming your subscription will activate and process your next order and you’ll be notified when the next scheduled refill is shipped."
						>
							i
						</div>
					</div>
				</div>
			)}
			{subscription.status === OrderStatus.active && (
				<div className="refill-count">
					<div className="refill-count-title">Number of Refills</div>
					<div className="refill-count-text">{subscription.prescription?.refillsLeft}</div>
				</div>
			)}
			<div className="center-row">
				<div className="id">
					<div className="id-title">Subscription ID:</div>
					<div className="id-text">{subscription.id}</div>
				</div>
				<div className="product-name">
					<div className="product-name-title">Product:</div>
					<div className="product-name-text">
						{subscription.items.map((item, index) => (
							<div key={index}>{item.productDosage}</div>
						))}
					</div>
				</div>
			</div>
			<div className="bottom-row">
				{subscription.nextOrderDate &&
				subscription.status !== OrderStatus.paused &&
				subscription.status !== OrderStatus.pausedByPatient &&
				subscription.status !== OrderStatus.pausedRxTransfer ? (
					<div className="dates-wrapper">
						<div className="next-refill-date">
							<div className="next-refill-date-title">Next Refill Date</div>
							<div className="next-refill-date-text">{subscription.nextOrderDate}</div>
						</div>
						<div className="expires-date">
							<div className="expires-date-title">Expires on</div>
							<div className="expires-date-text">
								{moment(subscription.prescription?.toDate).format("YYYY-MM-DD")}
							</div>
						</div>
					</div>
				) : subscription.prescription?.toDate ? (
					<div className="expires-date">
						<div className="expires-date-title">Expires on</div>
						<div className="expires-date-text">
							{moment(subscription.prescription?.toDate).format("YYYY-MM-DD")}
						</div>
					</div>
				) : (<div className="expires-date" />)}
				<div className="bottom-right">
					<div className="subscription-price">
						<div className="subscription-price-title">Order Price</div>
						<div className="subscription-price-text">{`$${(subscription.orderPrice / 100).toFixed(
							2
						)}`}</div>
					</div>
				</div>
			</div>
			{!!subscriptionOrders.length && (
				<div className="collapse-details-btn" onClick={() => setOpen(!open)}>
					<span>
						{open ? "Collapse " : "View "}
						Details
					</span>
					<img src={SubscriptionDetailsArrow} className={open ? "open" : ""} alt="" />
				</div>
			)}
			<div className="order-item-details">
				<SlideDown>{open && renderSubscriptionOrders()}</SlideDown>
			</div>
		</div>
	);
};

export default SubscriptionInfoItem;
