import React, {useState, ChangeEvent, useEffect, Fragment} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
	Prompt,
	useRouteMatch,
	Switch,
	Route,
	Redirect,
	useHistory,
	useLocation
} from "react-router-dom";

import {actions as patientActions} from "../../../../redux/patient";
import {StateType} from "../../../../redux/types";

import {
	uploadDocument,
	setTransferRxProgress,
	getTransferRxProgress
} from "../../../../http/patient";
import {makeSubscriptionWithRx} from "../../../../http/cart";

import ProductQuantity from "../../../../model/ProductQuantity";
import {ServedProductCategories, ProductCategoryName} from "../../../../model/ProductCategory";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import ImageUploadComponent from "../../../ImageUpload";
import ImageViewComponent from "../../../ImageView";
import CustomSelect from "../../../CustomSelect";
import CustomInput from "../../../CustomInput";
import ShippingAddresses from "../ShippingAddresses";
import AddShippingAddress from "../AddShippingAddress";
import BillingCards from "../BillingCards";
import AddBillingCard from "../AddBillingCard";
import SuccessTransfer from "../SuccessTransfer";

import IDsvg from "../../../../static/images/icon-id.svg";
import IconConfirmGreen from "../../../../static/images/_confirmGreen.svg";
import transferDetailsImage from "../../../../static/images/transferRxBottleDetails.png";

import "./styles.scss";
import LoaderComponent from "../../../Loader";
import ContinueTransfer from "../ContinueTransfer";
import PopUp from "../../../PopUp";
import Tooltip from "../../../Tooltip";
import {compressImage} from "../../../../utils/helpers";

type Option = {
	label: string;
	value: string;
};

const stepName = [
	"Identification and Insurance",
	"Medication Identification",
	"Prescription Details",
	"Shipping Information",
	"Billing Information"
];

const stepUrl = [
	"id-insurance-step",
	"select-product-step",
	"prescription-step",
	"shipping-info-step",
	"billing-info-step"
];

const productCategoryOptions: Option[] = ServedProductCategories.map((category) => ({
	label: ProductCategoryName[category],
	value: category
}));

const TransferRx = () => {
	const dispatch = useDispatch();
	const {path} = useRouteMatch();
	const {pathname} = useLocation();
	const history = useHistory();
	const {profile, products} = useSelector(({auth, product}: StateType) => ({
		profile: auth.profile,
		products: product.productItems
	}));
	const [emtyProfileOpen,setemtyProfileOpen]= useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false);
	const [step, setStep] = useState<number>(1);
	const [productCategory, setProductCategory] = useState<Option | null>(null);
	const [product, setProduct] = useState<Option | null>(null);
	const [quantity, setQuantity] = useState<Option | null>(null);
	const [rxNumber, setRxNumber] = useState<string>("");
	const [pharmacyName, setPharmacyName] = useState<string>("");
	const [pharmacyPhone, setPharmacyPhone] = useState<string>("");
	const [bottleImageId, setBottleImageId] = useState<string | null>(null);
	const [isImageViewShow, setIsImageViewShow] = useState<string | null>(null);
	const [imageType, setImageType] = useState<string>("");
	const [exitModal, setExitModal] = useState<string | null>(null);
	const [validationMessage, setValidationMessage] = useState<string>("");
	const [navVisibility, setNavVisibility] = useState<boolean>(true);

	const productOptions: Option[] = products
		.filter((product) => product.problemCategory === productCategory?.value)
		.map((product) => ({
			label: product.name,
			value: product.id.toString()
		}));
	let quantityOptions: Option[] = [];

	if (product) {
		const productObj = products.find((prod) => prod.id === +product.value);

		if (productObj) {
			quantityOptions = productObj.quantities
				.sort((a, b) => b.price - a.price)
				.map((quantity: ProductQuantity) => ({
					value: quantity.id.toString(),
					label: quantity.quantity
				}));
		}
	}

	useEffect(() => {
		setStep(1);
	}, []);

	useEffect(() => {
		if (!["success-transfer", "continue-transfer"].some((str) => pathname.includes(str))) {
			history.push(`${path}/${stepUrl[step - 1]}`);
		}
	}, [step, history, path, pathname]);

	useEffect(() => {
		const loadProgress = async () => {
			const result = await getTransferRxProgress();

			const progress = result.data;

			if (progress) {
				history.push(`${path}/continue-transfer`);
			}
		};

		loadProgress();
	}, [history, path]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const resumeTransfer = () => {
		const loadProgress = async () => {
			const result = await getTransferRxProgress();
			const progress = result.data;

			if (progress) {
				loadProgressFromRes(progress);
			}
		};

		loadProgress();
	};

	const handleOpenImgView = (type: string, id: string) => {
		setImageType(type);
		setIsImageViewShow(id);
	};

	const handleCloseImgView = () => {
		setImageType("");
		setIsImageViewShow(null);
	};

	const handleIdImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) => dispatch(patientActions.uploadIdRequest(newFile)));
		// dispatch(patientActions.uploadIdRequest(file));
	};

	const handleInsuranceImgChange = async (file: File) => {
		compressImage(file, (newFile: Blob) =>
			dispatch(patientActions.uploadInsuranceRequest(newFile))
		);
		// dispatch(patientActions.uploadInsuranceRequest(file));
	};

	const handleBottleImgChange = async (file: File) => {
		const formData = new FormData();
		formData.append("file", file);
		try {
			setLoading(true);

			const result = await uploadDocument(formData);
			const bottleId = result.data;

			setBottleImageId(bottleId);
			setValidationMessage("");
		} catch {
			setValidationMessage(MessageConst.error.uploadingImageError);
		} finally {
			setLoading(false);
		}
	};

	const saveProgress = () => {
		const objToSave: {
			step: number;
			productCategory?: Option;
			product?: Option;
			quantity?: Option;
			rxNumber?: string;
			pharmacyName?: string;
			pharmacyPhone?: string;
			bottleImageId?: string;
		} = {
			step: step + 1
		};

		if (productCategory) {
			objToSave.productCategory = productCategory;
		}

		if (product) {
			objToSave.product = product;
		}

		if (quantity) {
			objToSave.quantity = quantity;
		}
		if (rxNumber) {
			objToSave.rxNumber = rxNumber;
		}

		if (pharmacyName) {
			objToSave.pharmacyName = pharmacyName;
		}

		if (pharmacyPhone) {
			objToSave.pharmacyPhone = pharmacyPhone;
		}

		if (bottleImageId) {
			objToSave.bottleImageId = bottleImageId;
		}

		setTransferRxProgress(objToSave);
	};

	const loadProgressFromRes = (savedProgress: {
		step: number;
		productCategory?: Option;
		product?: Option;
		quantity?: Option;
		rxNumber?: string;
		pharmacyName?: string;
		pharmacyPhone?: string;
		bottleImageId?: string;
	}) => {
		history.push(`${path}`);

		if (savedProgress?.productCategory?.label && savedProgress?.productCategory.value) {
			setProductCategory(savedProgress.productCategory);
		}

		if (savedProgress?.product?.label && savedProgress?.product.value) {
			setProduct(savedProgress.product);
		}

		if (savedProgress?.quantity?.label && savedProgress?.quantity.value) {
			setQuantity(savedProgress.quantity);
		}

		if (savedProgress?.rxNumber) {
			setRxNumber(savedProgress.rxNumber);
		}

		if (savedProgress?.pharmacyName) {
			setPharmacyName(savedProgress.pharmacyName);
		}

		if (savedProgress?.pharmacyPhone) {
			setPharmacyPhone(savedProgress.pharmacyPhone);
		}

		if (savedProgress?.bottleImageId) {
			setBottleImageId(savedProgress.bottleImageId);
		}

		if (savedProgress?.step) {
			setStep(savedProgress.step);
		}
	};

	const isStepReady = (stepNumber: number) => {
		switch (stepNumber) {
			case 1:
				if (!profile?.idDocument) {
					console.log(!profile?.idDocument);
					
					setValidationMessage(MessageConst.validation.idError);

					return;
				} else {
					setValidationMessage("");

					return true;
				}
			case 2: {
				if (!productCategory?.value) {
					setValidationMessage(MessageConst.validation.conditionError);

					return;
				}

				if (!product?.value) {
					setValidationMessage(MessageConst.validation.prescribedProductError);

					return;
				}

				if (!quantity?.value) {
					setValidationMessage(MessageConst.validation.quantityError);

					return;
				}

				setValidationMessage("");

				return true;
			}

			case 3: {
				if (!rxNumber) {
					setValidationMessage(MessageConst.validation.rxNumberError);
					return;
				}

				if (!pharmacyName) {
					setValidationMessage(MessageConst.validation.pharmacyNameError);
					return;
				}

				if (!pharmacyPhone || !ValidationService.validatePhone(pharmacyPhone)) {
					setValidationMessage(MessageConst.validation.pharmacyPhoneError);
					return;
				}

				if (!bottleImageId) {
					setValidationMessage(MessageConst.validation.bottleImageError);
					return;
				}

				setValidationMessage("");

				return true;
			}

			case 4: {
				const allShippingAddresses = profile?.shippingAddresses;

				const primaryAddress = allShippingAddresses?.find((address) => address.isPrimary === true);

				if (primaryAddress) {
					setValidationMessage("");
					return true;
				} else {
					setValidationMessage(MessageConst.validation.shippingAddressError);
					break;
				}
			}
			case 5: {
				const allBillingCards = profile?.billingCards;

				const primaryCard = allBillingCards?.find((card) => card.isPrimary === true);

				if (primaryCard) {
					submitRx();
				} else {
					setValidationMessage(MessageConst.validation.billingCardError);
				}

				return false;
			}

			default:
				break;
		}
	};

	const handleNextClick = () => {
		const currentStep = step;
		if(
			profile?.firstName==null &&
			profile?.lastName==null &&
			profile?.phone==null &&
			profile?.birthday==null){
			setemtyProfileOpen(true)
			return;
		}
         console.log(step);
		 
		if (isStepReady(currentStep)) {
			setStep(step + 1);

			saveProgress();
		}
	};
	const handleCloseEmtyProfile=()=>{
		setemtyProfileOpen(false);
		history.push("/profile/account-info");
		
	}

	const submitRx = async () => {
		const requestObj = {
			promoCode: "",
			rxDocumentId: +bottleImageId!,
			rxNumber: rxNumber,
			pharmacyNameAndAddress: pharmacyName,
			pharmacyPhone: pharmacyPhone,
			productId: +product!.value,
			productQuantityId: +quantity!.value
		};

		setLoading(true);

		try {
			await makeSubscriptionWithRx(requestObj);
			setStep(1);
			setProductCategory(null);
			setProduct(null);
			setQuantity(null);
			setRxNumber("");
			setPharmacyName("");
			setPharmacyPhone("");
			setBottleImageId(null);
			history.push(`${path}/success-transfer`);
		} catch {
			setValidationMessage(MessageConst.error.unknownError);
		}

		setLoading(false);
	};

	const handleBackClick = () => {
		setValidationMessage("");
		setStep(step - 1);
	};

	const handleChangeRxId = (event: ChangeEvent<HTMLInputElement>): void => {
		const rxId = ValidationService.numberConverter(event.target.value);

		setRxNumber(rxId);
	};

	const handleChangePhone = (event: ChangeEvent<HTMLInputElement>): void => {
		const phone: string = ValidationService.phoneConverter(event.target.value);

		setPharmacyPhone(phone);
	};

	return (
		<div className="transfer-rx-wrapper">
			{loading && <LoaderComponent showShadow />}
			{emtyProfileOpen &&(
				<PopUp>
					<div className="exit-modal">
					<div className="title">PERSONAL INFORMATION REQUIRED</div>
					<div className="content">
					<p>Please update your Personal Information on your Account
					 Profile Page prior to submitting an order</p>
						 </div>
					<div className="button-links">
							<button  onClick={handleCloseEmtyProfile}>Ok</button>
						</div>
						</div>
				</PopUp>
			)}
			<Switch>
				<Route
					exact
					path={`${path}/continue-transfer`}
					render={() => <ContinueTransfer resumeTransfer={resumeTransfer} />}
				/>
				<Route exact path={`${path}/success-transfer`} render={() => <SuccessTransfer />} />
				<Route path="/">
					<div className="top-block-wrapper">
						<Prompt
							when={!exitModal}
							message={(nextLocation) => {
								const {pathname} = nextLocation;

								if (!pathname.includes("transfer-rx")) {
									setExitModal(pathname);
									return false;
								} else {
									return true;
								}
							}}
						/>
						<div className="title">{`Part ${step}/5 - ${stepName[step - 1]}`}</div>
						<div className="progress-bar">
							<div className="step-progress filled">
								<div className="fill" />
							</div>
							<div className={`step-progress ${step >= 2 ? "filled" : ""}`}>
								<div className="fill" />
							</div>
							<div className={`step-progress ${step >= 3 ? "filled" : ""}`}>
								<div className="fill" />
							</div>
							<div className={`step-progress ${step >= 4 ? "filled" : ""}`}>
								<div className="fill" />
							</div>
							<div className={`step-progress ${step === 5 ? "filled" : ""}`}>
								<div className="fill" />
							</div>
						</div>
					</div>
					<div className="steps-wrapper">
						<Switch>
							<Route exact path={`${path}/id-insurance-step`}>
								<div className="id-insurance-step">
									<h1 className="title">Thanks for Choosing Winston!</h1>
									<div className="subtitle">
										Please fill in the details below so that we can begin your prescription
										transfer. If you have any questions feel free to contact us on the live chat in
										the bottom right hand corner of your screen.
									</div>

									<div className="id-upload-block">
										<div className="question-block">
											<div className="question__withTooltip">
												<span className="question">
													Upload your Government Issued Photo identification:
												</span>
												<Tooltip
													text={
														<Fragment>
															<b>Why do we need your identification?</b>
															<p>
																To ensure medications prescribed are being used by the individual
																they have been prescribed to, we are required to verify your
																identity before we can release your prescription.
															</p>
														</Fragment>
													}
												/>
											</div>
											<p className="answer">
												Accepted forms : Driver’s License, Health Card, Passport, or any Government
												Issued ID Card.
											</p>
										</div>
										<div className="img-buttons">
											<ImageUploadComponent
												img={!!profile!.idDocument}
												onImgChange={handleIdImgChange}
												mode="ID"
											/>
											{profile!.idDocument && (
												<label
													className="view-img"
													onClick={() => handleOpenImgView("ID", profile!.idDocument!)}
												>
													<img src={IDsvg} alt="" />
													<p className="view-img-text">View image</p>
												</label>
											)}
										</div>
										{profile!.idDocument && (
											<div className="success-upload">
												<span>Success!</span>
												<img src={IconConfirmGreen} alt="" />
											</div>
										)}
									</div>
									<div className="insurance-upload-block">
										<div className="question-block">
											<span className="question">Have healthcare benefits?</span>
											<span className="answer">
												Upload a picture of your insurance card here and our pharmacy partner will
												apply your coverage, if available, to the prescription once approved.
											</span>
										</div>
										<div className="img-buttons">
											<ImageUploadComponent
												img={!!profile!.insuranceDocument}
												onImgChange={handleInsuranceImgChange}
												mode="Insurance"
											/>
											{profile!.insuranceDocument && (
												<label
													className="view-img"
													onClick={() =>
														handleOpenImgView("Insurance", profile!.insuranceDocument!)
													}
												>
													<img src={IDsvg} alt="" />
													<p className="view-img-text">View image</p>
												</label>
											)}
										</div>
										{profile!.insuranceDocument && (
											<div className="success-upload">
												<span>Success!</span>
												<img src={IconConfirmGreen} alt="" />
											</div>
										)}
									</div>
								</div>
							</Route>
							<Route exact path={`${path}/select-product-step`}>
								<div className="select-product-step">
									<div className="title">Select the medication to be transferred.</div>
									<div className="content">
										<div className="category">
											<CustomSelect
												label="Condition"
												placeholder="Step 1: Diagnosed condition"
												options={productCategoryOptions}
												value={productCategory}
												onChange={(selected: Option) => {
													if (!Array.isArray(selected) && selected) {
														setProductCategory(selected);
														setProduct(null);
														setQuantity(null);
													}
												}}
											/>
										</div>
										<div className="product-name">
											<CustomSelect
												label="Product Name"
												placeholder="Step 2: Prescribed product"
												options={productOptions}
												value={product}
												isDisabled={!productCategory}
												onChange={(selected: Option) => {
													if (!Array.isArray(selected) && selected) {
														setProduct(selected);
														setQuantity(null);
													}
												}}
											/>
										</div>
										<div className="product-quantity">
											<CustomSelect
												label="Quantity"
												placeholder="Step 3: Product quantity"
												options={quantityOptions}
												value={quantity}
												isDisabled={!product}
												onChange={(selected: Option) => {
													if (!Array.isArray(selected) && selected) {
														setQuantity(selected);
													}
												}}
											/>
										</div>
									</div>
								</div>
							</Route>
							<Route exact path={`${path}/prescription-step`}>
								<div className="prescription-step">
									<div className="content">
										<div className="title">
											Input you current prescription and pharmacy details.
										</div>

										<div className="rx-number">
											<CustomInput label="Rx Number" value={rxNumber} onChange={handleChangeRxId} />
										</div>
										<div className="pharmacy-name">
											<CustomInput
												label="Pharmacy Name"
												value={pharmacyName}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													setPharmacyName(e.target.value)
												}
											/>
										</div>
										<div className="pharmacy-phone">
											<CustomInput
												label="Pharmacy Phone Number"
												value={pharmacyPhone}
												onChange={handleChangePhone}
											/>
										</div>
										<div className="bottle-image-upload-block">
											<div className="subtitle">
												To ensure the accuracy of your prescription details, please upload an image
												of your prescription bottle.
											</div>
											<div className="img-buttons">
												<ImageUploadComponent
													img={!!bottleImageId}
													onImgChange={handleBottleImgChange}
													mode="Bottle"
												/>
												{bottleImageId && (
													<label
														className="view-img"
														onClick={() => handleOpenImgView("Bottle", bottleImageId)}
													>
														<img src={IDsvg} alt="" />
														<p className="view-img-text">View image</p>
													</label>
												)}
											</div>
											{bottleImageId && (
												<div className="success-upload">
													<span>Image uploaded successfully!</span>
													<img src={IconConfirmGreen} alt="" />
												</div>
											)}
										</div>
									</div>
									<div className="help-image">
										<img src={transferDetailsImage} alt="" />
									</div>
								</div>
							</Route>
							<Route exact path={`${path}/shipping-info-step`}>
								<div className="shipping-info-step">
									<div className="title">
										Add your shipping details below. If you have already provided it as part of your
										account setup, please review and ensure that nothing has changed.
									</div>
									<ShippingAddresses />
									<AddShippingAddress
										setNavVisibility={setNavVisibility}
										setValidationMessage={setValidationMessage}
									/>
								</div>
							</Route>
							<Route exact path={`${path}/billing-info-step`}>
								<div className="billing-info-step">
									<div className="title">
										You are almost done! Enter your payment details below or verify the details we
										have on file.
									</div>
									<BillingCards />
									<AddBillingCard
										setNavVisibility={setNavVisibility}
										setValidationMessage={setValidationMessage}
										scrollToTop
									/>
								</div>
							</Route>
							<Route path="/" render={() => <Redirect to={`${path}/id-insurance-step`} />} />
						</Switch>
						<div className="validation-message">
							<span className="validation-error">{validationMessage}</span>
						</div>
						{navVisibility && (
							<div className="steps-navigation">
								{step !== 1 && (
									<button className="back" onClick={handleBackClick}>
										Back
									</button>
								)}
								<button className="next" onClick={handleNextClick}>
									{step === 5 ? "Submit" : "Next"}
								</button>
							</div>
						)}
					</div>
					{exitModal && (
						<PopUp>
							<div className="exit-modal">
								<div className="title">WARNING</div>
								<div className="content">
									<p>
										By exiting this page before completing the process your prescription will not be
										transferred to Winston.
									</p>
									<p>Are you sure you want to exit?</p>
									<div className="button-links">
										<button onClick={() => history.push(exitModal)}>Yes</button>
										<button onClick={() => setExitModal(null)}>No</button>
									</div>
								</div>
							</div>
						</PopUp>
					)}
				</Route>
			</Switch>
			{isImageViewShow && (
				<ImageViewComponent
					imageViewProps={isImageViewShow}
					close={handleCloseImgView}
					title={`${imageType} image`}
				/>
			)}
		</div>
	);
};

export default TransferRx;
