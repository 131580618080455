import React from "react";
import {useSelector, useDispatch} from "react-redux";

import {StateType} from "../../redux/types";
import {actions as patientActions} from "../../redux/patient";

import ShippingAddress from "../../model/ShippingAddress";

import LoaderComponent from "../Loader";

import "./styles.scss";

interface ShippingAddressesProps {
	isSelectable: boolean;
	isDeletable: boolean;
	mode: "checkout" | "profile";
}

export interface ShippingAddressesState {
	isLoading: boolean;
}

const ShippingAddresses = (props: ShippingAddressesProps) => {
	const dispatch = useDispatch();

	const {selectedShippingAddress, profile, isLoading} = useSelector(
		({patient, auth}: StateType) => ({
			selectedShippingAddress: patient.selectedShippingAddress,
			profile: auth.profile,
			isLoading: patient.addingShippingAddress
		})
	);

	const handleSelectedAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.isSelectable) {
			const address =
				profile?.shippingAddresses.find((item) => item.id === +e.target.value) || null;
			dispatch(patientActions.selectShippingAddress(address));
		}
	};

	// const handleSetPrimaryAddressClick = (id: number | undefined) => () => {
	// 	if (id) {
	// 		dispatch(patientActions.setPrimaryShippingAddressRequest(id));
	// 	}
	// };

	return (
		<div className="shipping-addresses-component">
			{isLoading ? (
				<LoaderComponent />
			) : (
				<div className="shipping-addresses-component__content">
					{profile?.shippingAddresses.length !== 0 && props.mode === "profile" && (
						<div className="micro-header">Your shipping addresses</div>
					)}
					{profile?.shippingAddresses.map((address: ShippingAddress) => (
						<div
							className={"shipping-address" + (props.isDeletable ? " row-margin" : "")}
							key={address.id}
						>

							<label className="radio-button-field-2 shipping w-radio">
							{props.isSelectable && (
								<>
								<div className={((!!selectedShippingAddress && address.id === selectedShippingAddress.id)?'radio-button-2':'radio-button') + " w-form-formradioinput w-form-formradioinput--inputType-custom shipping w-radio-input"}>
								</div>
								<input type="radio" style={{opacity:'0', position:"absolute", zIndex:-1}} data-name="Radio" id="radio" 
									name="shipping-address"
									onChange={handleSelectedAddressChange}
									value={address.id}
									checked={
										!!selectedShippingAddress && address.id === selectedShippingAddress.id
									}
								/>
								</>
							)}
								<span className="radio-button-label-2 shipping w-form-label">
								{address.addressLine1}, {address.addressLine2}
									{address.addressLine2 ? ", " : ""}
									{address.addressCity}, {address.addressProvince}, {address.addressCountry},{" "}
									{address.addressPostalCode}
								</span>
							</label>
							{/* {address.isPrimary && <div className="primary-text">Primary</div>}
							{!address.isPrimary && props.mode === "profile" && (
								<button className="set-primary" onClick={handleSetPrimaryAddressClick(address.id)}>
									Set as Primary Address
								</button>
							)} */}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ShippingAddresses;
