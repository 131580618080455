import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select, { ValueType } from "react-select";

import { StateType } from "../../redux/types";
import { actions as patientActions } from "../../redux/patient";
import { actions as cartActions } from "../../redux/cart";

import SelectedProduct from "../../model/SelectedProduct";
import ProductQuantity from "../../model/ProductQuantity";

import close from "../../static/images/close.png";
import insurance from "../../static/images/insurance.png";
import virtual from "../../static/images/free-virtual.png";
import delivary from "../../static/images/delivery.png";
import canadianProduct from "../../static/images/Canadian-produced-products-icon.png";

import "./styles.scss";
import { getProductImage } from "../../utils/helpers";

type QuantityOption = { label: string; value: number };
type SelectStyleState = {
	isSelected: boolean;
};
// const customSelectStyles = {
// 	container: (provided: {}) => ({
// 		...provided,
// 		width: "50%",

// 		"@media (max-width: 1200px)": {
// 			width: "75%"
// 		},
// 		"@media (max-width: 959px)": {
// 			width: "100%"
// 		}
// 	}),
// 	valueContainer: (provided: {}) => ({
// 		...provided,
// 		height: "24px",
// 		cursor: "pointer"
// 	}),
// 	singleValue: (provided: {}) => ({
// 		...provided,
// 		height: "100%"
// 	}),
// 	control: (provided: {}) => ({
// 		...provided,
// 		borderRadius: "0",
// 		border: "2px solid $main-gold",
// 		boxShadow: "0",
// 		fontFamily: "Avenir-Book",
// 		fontSize: "20px",
// 		lineHeight: "24px",
// 		"@media (max-width: 959px)": {
// 			fontSize: "16px"
// 		},
// 		"&:hover": {
// 			border: "2px solid $main-gold"
// 		},
// 		"&:active": {
// 			boxShadow: "0"
// 		}
// 	}),
// 	dropdownIndicator: (provided: {}) => ({
// 		...provided,
// 		color: "$main-gold"
// 	}),
// 	indicatorSeparator: (provided: {}) => ({
// 		...provided,
// 		display: "none"
// 	}),
// 	menu: (provided: {}) => ({
// 		...provided,
// 		margin: 0,
// 		borderRadius: 0,
// 		fontFamily: "Avenir-Book",
// 		fontSize: "20px",
// 		"@media (max-width: 959px)": {
// 			fontSize: "16px"
// 		}
// 	}),
// 	menuList: (provided: {}) => ({
// 		...provided,
// 		padding: 0,
// 		borderWidth: "0px 2px",
// 		borderStyle: "solid",
// 		borderColor: "$main-gold"
// 	}),
// 	option: (provided: {}, state: SelectStyleState) => ({
// 		...provided,
// 		borderBottom: "2px solid $main-gold",
// 		cursor: "pointer",
// 		background: "#FFF",
// 		color: "#000000",
// 		display: state.isSelected ? "none" : "block"
// 	})
// };

interface CartProps {
	handleNext: () => void;	
}

const PatientCart = forwardRef((props: CartProps) => {
	// const history = useHistory();
	const dispatch = useDispatch();
	const { selectedPromoCode, selectedProducts } = useSelector(({ patient, cart }: StateType) => ({
		selectedPromoCode: patient.selectedPromoCode,
		selectedProducts: cart.selectedProducts
	}));

	const promoCodeInput = useRef<HTMLInputElement>(null);

	const [promoCode, setPromocode] = useState<string>(
		selectedPromoCode ? selectedPromoCode.code : ""
	);
	const [validationMessage, setValidationMessage] = useState<{
		type: "success" | "error";
		text: string;
	} | null>(null);

	let total = 0;
	let discountMultiplier = 1.0;
	let discountAddition = 0.0;

	useEffect(() => {
		if (!selectedPromoCode) {
			setValidationMessage(null);
			setPromocode("");
		}
	}, [selectedPromoCode]);

	useEffect(() => {				
		setQuantity();
	}, [])


	const setQuantity = () =>{
		if(selectedProducts){			
			selectedProducts.forEach(element => {
				if(element.product.qty){					
					const quantity = element.product.quantities.find(
						(q) => q.id === element.product.qty.id
					);
					const newProduct = { ...element };
			
					newProduct.quantity = { ...quantity! };
			
					dispatch(cartActions.updateProductRequest(newProduct));
				}
			});
		}
	}

	if (selectedPromoCode) {
		switch (selectedPromoCode.type.toString()) {
			case "AMOUNT":
				discountAddition = -selectedPromoCode.value;
				break;
			case "PERCENT":
				discountMultiplier = 1.0 - selectedPromoCode.value / 100.0;
				break;
		}
	}
	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleApplyPromoCode();
		}
	};

	const handlePromoCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPromocode(event.target.value);
	};

	const handleApplyPromoCode = async () => {		
		if (!promoCode) {
			alert();
			dispatch(patientActions.selectPromoCode(null));
			return;
		}

		dispatch(patientActions.verifyPromoCodeRequest({ promoCode, setValidationMessage }));
	};

	

	const handleProductQuantityChange = (
		cartItem: SelectedProduct,
		selected: ValueType<QuantityOption>
	) => {
		const quantity = cartItem.product.quantities.find(
			(q) => q.id === (selected! as QuantityOption).value
		);
		cartItem.product.qty =   { ...quantity! };
		const newProduct = { ...cartItem };

		newProduct.quantity = { ...quantity! };

		dispatch(cartActions.updateProductRequest(newProduct));
	};


	return (
		<div id="cart">
			<div className="section cart">
				<div className="container-12 w-container">
					<div className="w-row">
						<div className="column-25 w-col w-col-3">
							<Link to="/shop" className="link view-products-btn">
								<strong>← Return To Shop</strong>
							</Link>
						</div>
						<div className="w-col w-col-9"></div>
					</div>
				</div>
				<div className="container-10 w-container">
					<h1 className="heading-6">Shopping Cart<span className="text-span"></span></h1>
				</div>
				<div className="container-11 w-container">
					<div className="columns-27 w-row">
						<div className="column-23 w-col w-col-8">
							{selectedProducts.map((item: SelectedProduct, index) => {
								total += item.quantity.price * item.count;
								const options: { value: number; label: string }[] = item.product.quantities
									.sort((a, b) => b.price - a.price)
									.map((quantity: ProductQuantity) => ({
										value: quantity.id,
										label: quantity.quantity
									}));

								const selectedDose = item.product.quantities.find(
									(quantity) => quantity.id === item.quantity.id
								);
								return (
									<div key={item.product.id}>
										<div className="div-block-19">
											<button
												className="remove-btn"
												onClick={() => {
													dispatch(cartActions.deleteProductRequest(item));
												}}
											>
												<img src={close} width="25" alt="close" className="image-4" /></button>
										</div>
										<div className="div-block">
											<div className="columns w-row">
												<div className="w-col w-col-3">
													<img src={getProductImage(item.product.name)} width="107" sizes="107px" alt="product" className="image-26" />
												</div>
												<div className="w-col w-col-6">
													<h1 className="heading">{item.product.name}</h1>
													<div className="text-block-13">Monthly refill</div>
													<div className="form-block-2 w-form">
														<form id="email-form" name="email-form" data-name="Email Form">
															<label className="field-label-2">Dose &amp; quantity:</label>
															{options.length > 1 ? (
																<Select
																	id="field"
																	name="field"
																	value={{ value: item.quantity.id, label: selectedDose!.quantity }}
																	options={options}
																	isMulti={false}
																	isSearchable={false}
																	onChange={(selected) => {
																		if (!Array.isArray(selected) && selected) {
																			handleProductQuantityChange(item, selected);
																		}
																	}}
																/>
															) : (
																<div className="single-dose">{selectedDose!.quantity}</div>
															)}
														</form>														

														{/* <div className="w-form-done">
															<div>Thank you! Your submission has been received!</div>
														</div>
														<div className="w-form-fail">
															<div>Oops! Something went wrong while submitting the form.</div>
														</div> */}
													</div>
												</div>
												<div className="w-col w-col-3">
													<h1 className="heading-4">${((item.quantity.price * item.count)/100).toFixed(2)}</h1>
												</div>
											</div>
										</div>
									</div>
								);
							})}
							{selectedProducts.length === 0 && (
								<div className="empty-cart">
									<p>You have not added anything to your cart.</p>
									<Link to="/shop" className="view-products-btn w-button button-2 viewProduct">
										<strong>View Products</strong>
									</Link>
								</div>
							)}
						</div>
						<div className="column-24 w-col w-col-4">
							<div className="div-block-15">
								<h1 className="heading-5">Total</h1>
								<div className="form-block-3 w-form">
									<form id="email-form-3" name="email-form-3" data-name="Email Form 3" className="form">
										<input className="text-field w-input"
											placeholder="Have a promo code?"
											type="text"
											ref={promoCodeInput}
											onChange={handlePromoCodeChange}
											onKeyPress={handleKeyPress}
											value={promoCode}
										/>
										<input type="button"
											value="Apply"
											onClick={handleApplyPromoCode}
											data-wait="Please wait..."
											className="w-button submit-button" />
									</form>

									{/* validation column */}
									{/* <div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
										<div>{validationMessage}</div>
									</div> */}
														
									<div
			 							className={`							 
							${!validationMessage?.type
												? ""
												: validationMessage?.type === "success"
													? "w-form-done-show"
													: "w-form-fail-show"
											}`}
									>
										{validationMessage?.text}
									</div>
								</div>
								<div className="columns-22 w-row">
									<div className="w-col w-col-6">
										<div>Online doctor consultation</div>
									</div>
									<div className="w-col w-col-6">
										<div className="text-block-29">FREE</div>
									</div>
								</div>
								<div className="columns-23 w-row">
									<div className="column-18 w-col w-col-6">
										<div>Standard Shipping (2-3 days)</div>
									</div>
									<div className="w-col w-col-6">
										<div className="text-block-29">FREE</div>
									</div>
								</div>
								<div className="columns-28 w-row">
									<div className="w-col w-col-6">
										<div>Subtotal </div>
									</div>
									<div className="w-col w-col-6">
										<div className="text-block-32">${(total / 100).toFixed(2)}</div>
									</div>
								</div>
								<div className="columns-19 w-row">
									<div className="w-col w-col-6">
										<div>Tax</div>
									</div>
									<div className="w-col w-col-6">
										<div className="text-block-29">$0</div>
									</div>
								</div>
								{selectedPromoCode && (
									<div className="columns-19 w-row">
										<div className="w-col w-col-6">
											<div>Discount</div>
										</div>
										<div className="w-col w-col-6">
											<div className="text-block-29">${((total - (total * discountMultiplier + discountAddition)) / 100).toFixed(2)}</div>
										</div>
									</div>
								)}
								<div className="columns-24 w-row">
									<div className="w-col w-col-6">
										<div>Total </div>
									</div>
									<div className="w-col w-col-6">
										<div className="text-block-29 fw-600">${((total * discountMultiplier + discountAddition) / 100).toFixed(2)}<br /></div>
									</div>
								</div>
								<span onClick={props.handleNext} className="w-button button-2" ><strong>Due Now $0</strong></span>
								{/* <Link className="w-button button-2" to="/login">Due Now $0</Link> */}
							</div>
							<div className="text-block-31">Feel safe knowing that you will not be charged until your order has been prescribed and the delivery tracking number has been provided.</div>
						</div>
					</div>
				</div>
				<div className="container-13 w-container">
					<div className="columns-29 w-row">
						<div className="column-30 w-col w-col-6">
							<div className="w-row">
								<div className="column-26 w-col w-col-6"><img src={insurance} width="40" alt="insurance" className="image-21" />
									<h1 className="heading-11">Insurance<br />accepted<br /></h1>
								</div>
								<div className="column-27 w-col w-col-6"><img src={virtual} width="40" alt="virtual" className="image-24" />
									<h1 className="heading-12">Safe and <br />encrypted<br /></h1>
								</div>
							</div>
						</div>
						<div className="column-31 w-col w-col-6">
							<div className="w-row">
								<div className="column-28 w-col w-col-6"><img src={delivary} width="53" sizes="(max-width: 479px) 100vw, 53px" alt="delivery" />
									<h1 className="heading-10">Discreet<br />delivery</h1>
								</div>
								<div className="column-29 w-col w-col-6"><img src={canadianProduct} width="40" alt="canadian-product" className="image-24" />
									<h1 className="heading-9">Canadian produced<br />meds<br /></h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	);
});

export default PatientCart;
