import React, { useEffect } from "react";
import {useParams, Redirect} from "react-router-dom";

import "./styles.scss";
import {ArticleHtmlPages} from "../../model/TheFactsCategories";

const FactPage = () => {
	const {articleName} = useParams();

	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
	}, [])

	const article = ArticleHtmlPages.find((art) => art.link === articleName);
	return article ? article.html : <Redirect to="/learn" />;
};

export default FactPage;
