import {handleActions, createActions} from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
	SET_SHOP_SCROLL_TARGET: undefined,

	SET_LOADING: undefined,

	SET_IS_SUBSCRIBED: undefined,

	SET_PROFILE_PAGE_TO_OPEN: undefined,

	SET_CLOSE_SIDEBAR_DIRECTION: undefined,

	SET_IS_CHECKOUT: undefined
});

const reducer = handleActions(
	{
		[`${actions.setShopScrollTarget}`]: handlers.setShopScrollTarget,

		[`${actions.setLoading}`]: handlers.setLoading,

		[`${actions.setIsSubscribed}`]: handlers.setIsSubscribed,

		[`${actions.setProfilePageToOpen}`]: handlers.setProfilePageToOpen,

		[`${actions.setCloseSidebarDirection}`]: handlers.setCloseSidebarDirection,

		[`${actions.setIsCheckout}`]: handlers.setIsCheckout
	},
	initialState
);
export default reducer;
