import {ActionType} from "../types";
import Profile from "../../model/Profile";
import Profileget from "../../model/Profileget"

export interface AuthState {
	token: string | null;
	profile: Profile | null;
	profileget:Profileget |null;
	authLoading: boolean;
}

let token: string | null = "";
try {
	token = localStorage.getItem("token");
} catch (e) {
	console.log(e);
	alert(
		"Failed to read the 'localStorage' property: " +
		"Access is denied for this document. " +
		"Please, Unblock third-party cookies and site data in your browser configuration"
	);
}

const initialState: AuthState = {
	token,
	profile: null,
	profileget:null,
	authLoading: false
};

export const getProfileRequest = (state: AuthState,{payload}:ActionType) => ({
	...state,

});

export const getProfileSuccess = (state: AuthState, {payload}: ActionType) => ({
	...state,
	profile: payload,
	profileget:null,

});

export const getProfileFailure = (state: AuthState) => ({
	...state,
	profile: null,
	profileget:null,

});

export const loginRequest = (state: AuthState, {payload}:ActionType) => ({
	...state,
	authLoading: true,
	profileget:payload
});

export const loginSuccess = (state: AuthState, {payload}: ActionType) => {
	return {
		...state,
		profile: payload,
		profileget:payload,
		authLoading: false
	};
};

export const loginFailure = (state: AuthState,{payload}: ActionType) => ({
	...state,
	profile: null,
	profileget:payload,
	authLoading: false
});

export const registerRequest = (state: AuthState, {payload}:ActionType) => ({
	...state,
	authLoading: true,
	profileget:payload

});

export const registerSuccess = (state: AuthState, {payload}: ActionType) => {
	return {
		...state,
		profile: payload,
		profileget:payload,
		authLoading: false
	};
};

export const registerFailure = (state: AuthState,{payload}:ActionType) => ({
	...state,
	profile: null,
	profileget:payload,
	authLoading: false
});

export const forgotPasswordRequest = (state: AuthState) => ({
	...state,
	authLoading: true
});

export const forgotPasswordSuccess = (state: AuthState) => ({
	...state,
	authLoading: false
});

export const forgotPasswordFailure = (state: AuthState) => ({
	...state,
	authLoading: false
});

export const updateProfile = (state: AuthState, {payload}: ActionType) => ({
	...state,
	profile: {...payload}
});

export const logout = (state: AuthState) => {
	return {
		...state
	};
};

export const removeProfile = (state: AuthState) => {
	return {
		...state,
		token: null,
		profile: null
	};
};

export const setToken = (state: AuthState, {payload}: ActionType) => {
	localStorage.setItem("token", payload);

	return {
		...state,
		token: payload
	};
};

export const removeToken = (state: AuthState) => {
	localStorage.removeItem("token");

	return {
		...state,
		token: null
	};
};

export default initialState;
