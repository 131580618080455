import {call, put, takeLatest, Effect, select} from "redux-saga/effects";

import {actions as types} from "./";
import {actions as authTypes} from "../auth";

import * as httpPatient from "../../http/patient";

import ShippingAddress from "../../model/ShippingAddress";
import SelectedProduct from "../../model/SelectedProduct";
import BillingCard from "../../model/BillingCard";

import MessageConst from "../../const/Message.const";

import {ActionType} from "../types";
import {getProfile, getSelectedProducts} from "../selectors";

function* onAddShippingAddress({payload}: ActionType) {
	const {selectNewAddress, mode, setPrimary, callback, ...address} = payload;

	try {
		const result = yield call(httpPatient.addShippingAddress, address);
		const newShippingAddress = result.data;
		const profile = yield select(getProfile);
		const newProfile = {...profile};

		newProfile.shippingAddresses.push(newShippingAddress);

		yield put(authTypes.updateProfile(newProfile));

		if (mode === "checkout" || setPrimary) {
			yield put(types.setPrimaryShippingAddressRequest(result.data.id));
		}

		if (selectNewAddress) {
			yield put(types.selectShippingAddress(newShippingAddress));
		}

		if (callback) {
			callback();
		}

		yield put(types.addShippingAddressSuccess());
	} catch (error) {
		yield put(types.addShippingAddressFailure());
	}
}

function* onAddBillingCard({payload}: ActionType) {
	const {selectNewCard, mode, setPrimary, callback, info} = payload;

	try {
		const result = yield call(httpPatient.addBillingCard, info);
		const newBillingCard = result.data;
		const profile = yield select(getProfile);
		const newProfile = {...profile};

		newProfile.billingCards.push(newBillingCard);

		yield put(authTypes.updateProfile(newProfile));

		if (mode === "checkout" || setPrimary) {
			yield put(types.setPrimaryBillingCardRequest(result.data.id));
		}
		if (selectNewCard) {
			yield put(types.selectBillingCard(newBillingCard));
		}

		if (callback) {
			callback();
		}

		yield put(types.addBillingCardSuccess());
	} catch (error) {
		callback(error.response.data.message);
		yield put(types.addBillingCardFailure());
	}
}

function* onSetPrimaryShippingAddress({payload: id}: ActionType) {
	try {
		yield call(httpPatient.setPrimaryShippingAddress, id);

		const profile = yield select(getProfile);
		const newProfile = {...profile};

		newProfile.shippingAddresses = newProfile.shippingAddresses.map((address: ShippingAddress) => ({
			...address,
			isPrimary: false
		}));

		const addressIndex = newProfile.shippingAddresses.findIndex(
			(address: ShippingAddress) => address.id === id
		);

		newProfile.shippingAddresses[addressIndex].isPrimary = true;

		yield put(authTypes.updateProfile(newProfile));

		yield put(types.setPrimaryShippingAddressSuccess());
	} catch (error) {
		yield put(types.setPrimaryShippingAddressFailure());
	}
}

function* onSetPrimaryBillingCard({payload: id}: ActionType) {
	try {
		yield call(httpPatient.setPrimaryBillingCard, id);

		const profile = yield select(getProfile);
		const newProfile = {...profile};

		newProfile.billingCards = newProfile.billingCards.map((card: BillingCard) => ({
			...card,
			isPrimary: false
		}));

		const cardIndex = newProfile.billingCards.findIndex((card: ShippingAddress) => card.id === id);

		newProfile.billingCards[cardIndex].isPrimary = true;

		yield put(authTypes.updateProfile(newProfile));

		yield put(types.setPrimaryBillingCardSuccess());
	} catch (error) {
		yield put(types.setPrimaryBillingCardFailure());
	}
}

function* onVerifyPromoCode({payload}: ActionType) {
	const {promoCode, setValidationMessage} = payload;

	try {
		const result = yield call(httpPatient.verifyPromoCode, promoCode);
		const newPromoCode = result.data;
		if (newPromoCode.type === "GIFT") {
			const promocodeCategories = newPromoCode.problemCategory;
			let cartCategories: string[] = [];
			const cart = yield select(getSelectedProducts);

			cart.forEach((cartItem: SelectedProduct) =>
				cartCategories.push(cartItem.product.problemCategory)
			);

			cartCategories = cartCategories.filter(function (item, pos) {
				return cartCategories.indexOf(item) === pos;
			});

			const isIncludes = promocodeCategories.some((c: string) => cartCategories.includes(c));

			if (!isIncludes) {
				setValidationMessage({type: "error", text: MessageConst.error.promoCode});
				yield put(types.verifyPromoCodeFailure());
				return;
			}
		}
		setValidationMessage({type: "success", text: MessageConst.promoCode.done});
		yield put(types.verifyPromoCodeSuccess(newPromoCode));
	} catch (error) {
		setValidationMessage({type: "error", text: MessageConst.error.promoCode});
		yield put(types.verifyPromoCodeFailure());
	}
}

function* onUpdateProfile({payload}: ActionType) {
	const {profile, setValidationMessage, callback} = payload;
	try {
		const result = yield call(httpPatient.updateProfile, profile);
		const newProfile = result.data;

		setValidationMessage("");
		yield put(authTypes.updateProfile(newProfile));

		if (callback) {
			callback();
		}
		
		yield put(types.updateProfileSuccess());
	} catch (error) {
		yield put(types.updateProfileFailure());
	}
}

function* onUploadId({payload: file}: ActionType) {
	try {
		var formData = new FormData();
		formData.append("file", file);

		const result = yield call(httpPatient.uploadId, formData);
		const newIdDocument = result.data;
		const profile = yield select(getProfile);
		const newProfile = {...profile, idDocument: newIdDocument};

		yield put(authTypes.updateProfile(newProfile));
		yield put(types.uploadIdSuccess());
	} catch (error) {
		yield put(types.uploadIdFailure());
	}
}

function* onUploadInsurance({payload: file}: ActionType) {
	try {
		var formData = new FormData();
		formData.append("file", file);

		const result = yield call(httpPatient.uploadInsurance, formData);
		const newIdUnsurance = result.data;
		const profile = yield select(getProfile);
		const newProfile = {...profile, insuranceDocument: newIdUnsurance};

		yield put(authTypes.updateProfile(newProfile));
		yield put(types.uploadInsuranceSuccess());
	} catch (error) {
		yield put(types.uploadInsuranceFailure());
	}
}

function* onSelectShippingAddress({payload}: ActionType) {
	try {
		if (payload?.id) {
			yield put(types.setPrimaryShippingAddressRequest(payload.id));
		}
	} catch (error) {
		console.log(error);
	}
}

function* onSelectBillingCard({payload}: ActionType) {
	try {
		if (payload?.id) {
			yield put(types.setPrimaryBillingCardRequest(payload.id));
		}
	} catch (error) {
		console.log(error);
	}
}

const patientSagas: Effect[] = [
	takeLatest(types.addShippingAddressRequest, onAddShippingAddress),
	takeLatest(types.addBillingCardRequest, onAddBillingCard),
	takeLatest(types.setPrimaryShippingAddressRequest, onSetPrimaryShippingAddress),
	takeLatest(types.setPrimaryBillingCardRequest, onSetPrimaryBillingCard),
	takeLatest(types.verifyPromoCodeRequest, onVerifyPromoCode),
	takeLatest(types.updateProfileRequest, onUpdateProfile),
	takeLatest(types.uploadIdRequest, onUploadId),
	takeLatest(types.uploadInsuranceRequest, onUploadInsurance),
	takeLatest(types.selectShippingAddress, onSelectShippingAddress),
	takeLatest(types.selectBillingCard, onSelectBillingCard)
];

export default patientSagas;
