import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import "core-js";
import "url-search-params-polyfill";
import 'whatwg-fetch';

import configureStore from "./redux/configureStore";
import AppRouter from "./router";

import "./styles.scss";

const store = configureStore();

console.log(process.env.REACT_APP_PROFILE, "- REACT_APP_PROFILE ENV");
console.log(process.env.REACT_APP_SERVER_API_URL, "- SERVER_API_URL");

ReactDOM.render(
	<Provider store={store}>
		<AppRouter />
	</Provider>,
	document.getElementById("root")
);
