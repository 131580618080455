import React, {useEffect, useState} from "react";
import { Link} from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import 'bootstrap/dist/css/bootstrap.min.css';
import stars from "../../static/images/review-stars.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import canadian_flag_small from "../../static/images/canadian-flag-small.jpg";
import canadian_flag_medium from "../../static/images/canadian-flag-medium.jpg";
import canadian_flag_large from "../../static/images/canadian-flag-large.jpg";
import quality_medi from "../../static/images/quality-medication5x.png";
import as_seen from "../../static/images/as-seen.png";
import quickEasyProcess from "../../static/images/quick-and-easy-process5x.png";
import secureAndPrivate from "../../static/images/secure-and-private5x.png";
import googleLogo from "../../static/images/google-logo.svg";

import "./styles.scss";
import {Title} from "react-meta-elements";

declare global {
	interface Window {
		saq: any;
	}
}
// const SlickButtonFix = ({currentSlide, slideCount, children, ...props}: any) => (
// 	<button {...props}>{children}</button>
// );
// const sliderSettings = {
// 	dots: false,
// 	infinite: true,
// 	speed: 500,
// 	slidesToShow: 3,
// 	slidesToScroll: 1,
// 	className: "slick-carousel",
// 	arrows: true,
// 	prevArrow: (
// 		<SlickButtonFix>
// 			<img src={leftArrow} alt="" />
// 		</SlickButtonFix>
// 	),
// 	nextArrow: (
// 		<SlickButtonFix>
// 			<img src={rightArrow} alt="" />
// 		</SlickButtonFix>
// 	),
// 	responsive: [
// 		{
// 			breakpoint: 1000,
// 			settings: {
// 				slidesToShow: 2
// 			}
// 		},
// 		{
// 			breakpoint: 767,
// 			settings: {
// 				slidesToShow: 1,
// 				centerMode: true,
// 				centerPadding: "70px"
// 			}
// 		},
// 		{
// 			breakpoint: 450,
// 			settings: {
// 				slidesToShow: 1,
// 				centerMode: true,
// 				centerPadding: "40px"
// 			}
// 		}
// 	]
// };

const Face = () => {
	// const dispatch = useDispatch();
	// const history = useHistory();

	useEffect(() => {
		window.saq("ts", "4X8l353YU3PFJlChGXxKqQ");
	}, []);

	
	// Getting utm source and medium from query params
	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
			console.log(search.split('?')[1]);
		}
	}, [])

	// const renderProductCategories = () => {
	// 	return ServedProductCategories.map((category) => (
	// 		<div className="category-item" key={category} onClick={handleCategoryClick(category)}>
	// 			<div className="info">
	// 				<div className="image">
	// 					<img src={getProductImage(ProductCategoryImageForm[category])} alt="" />
	// 				</div>
	// 				<div className="bottom-info">
	// 					<div className="name">{ProductCategoryName[category]}</div>
	// 					<Link
	// 						to={`/article/${ProductCategoryLearnMoreLink[category]}`}
	// 						onClick={(e) => {
	// 							e.stopPropagation();
	// 						}}
	// 					>
	// 						<div className="learn-more-link">Learn more</div>
	// 					</Link>
	// 				</div>
	// 			</div>
	// 			{ProductCategoryCircles[category]}
	// 		</div>
	// 	));
	// };
	
	  const [open, setOpen] = useState(false);
	  const [open1, setOpen1] = useState(false);
	//   const [open2, setOpen2] = useState(false);
	//   const [open3, setOpen3] = useState(false);
	//   const [open4, setOpen4] = useState(false);


	return (
		<>
	
		<Title> Winston|The Premier Men's Health Virtual Clinic</Title>
		<div className="face-component" id="face">
			<div className="section">
				<div className="container-10 w-container">
				<div className="w-row">
					<div className="column-123 w-col w-col-8">
					<h1 className="heading-98"><strong className="bold-text-13">Men’s healthcare made easy from virtually, anywhere.</strong></h1>
					<p className="paragraph-15">We prescribe and deliver men’s health medications at the lowest price, directly to you. Convenient, safe and discreet.</p>
					<Link to="/shop" className="button-19 w-button"><strong>Get Started</strong></Link>
					</div>
					<div className="w-col w-col-4"></div>
				</div>
				<div className="div-block-69">
					<div className="text-block-13">As seen in</div><img src={as_seen} alt="as-seen" className="image-23" />
				</div>
				</div>
			</div>
			<div className="section-16">
				<div className="container-37 w-container">
				<h1 className="heading-115"><strong>What We Treat</strong></h1>
				</div>
				<div className="w-layout-grid grid-7">
				<Link to="/erectile-dysfunction">
					<div className="div-block-72">
						<h1 className="heading-99"><strong>Erectile Dysfunction</strong> </h1>
					</div>
				</Link>
				<Link to="/hair-loss">
					<div className="div-block-73">
						<h1 className="heading-100"><strong>Hair Loss</strong> </h1>
					</div>
				</Link>
				<Link to="/cold-sores">
					<div className="div-block-74">
						<h1 className="heading-102"><strong>Cold Sores</strong></h1>
					</div>
				</Link>
				<Link to="/acne">
					<div className="div-block-75">
						<h1 className="heading-101"><strong>Acne</strong> </h1>
					</div>
				</Link>
				<Link to="/allergies">
					<div className="div-block-76">
						<h1 className="heading-101"><strong>Allergies</strong> </h1>
					</div>
				</Link>

				</div>
				<div className="w-row">
				<div className="column-149 w-col w-col-3"></div>
				<div className="column-148 w-col w-col-3"></div>
				<div className="column-150 w-col w-col-3"></div>
				<div className="column-151 w-col w-col-3"></div>
				</div>
			</div>
			<div className="section-15"></div>
			<div className="section-5">
				<div className="container-32 w-container">
				<h1 className="heading-75"><strong>How Winston Works</strong><br/></h1>
				<p className="paragraph-13"><strong className="bold-text-12">Our goal is to make healthcare accessible and medications affordable in 3 easy steps.</strong><br/></p>
				</div>
			</div>
			<div className="section-23">
				<div className="w-row">
				<div className="column-159 w-col w-col-6">
					<div className="columns-81 w-row">
					<div className="column-160 w-col w-col-1"></div>
					<div className="w-col w-col-10">
						<h1 className="heading-123"><span className="text-span-12"><span className="bold-text-21">1</span></span><strong className="bold-text-20"> <br/>Choose a product</strong></h1>
						<p className="paragraph-11 g">Pick from our collection of safe and effective medication based on your symptoms and perceived health needs.<br/></p>
					</div>
					<div className="w-col w-col-1"></div>
					</div>
				</div>
				<div className="column-158 w-col w-col-6">
					<div className="columns-80 w-row">
					<div className="column-161 w-col w-col-1"></div>
					<div className="w-col w-col-10">
						<h1 className="heading-55 a"><span className="text-span-11"><span className="bold-text-15">2</span></span><strong className="bold-text-14"> <br/>Connect with a doctor</strong><br/></h1>
						<p className="paragraph-11">A licensed doctor at Winston will talk to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br/></p>
					</div>
					<div className="w-col w-col-1"></div>
					</div>
				</div>
				</div>
			</div>
			<div className="section-18">
				<div className="container-34 w-container">
				<div className="columns-77 w-row">
					<div className="column-155 w-col w-col-2"></div>
					<div className="w-col w-col-8">
					<h1 className="heading-55 g"><span className="text-span-9"><span className="bold-text-18">3</span></span><strong className="bold-text-17"> <br/>Take control</strong><br/></h1>
					<p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br/></p>
					</div>
					<div className="w-col w-col-2"></div>
				</div>
				</div>
			</div>
			<div className="section-13">
				<div className="container-18 w-container">
				<div className="columns-67 w-row">
					<div className="column-120 w-col w-col-6">
					<h1 className="heading-104"><strong>Canadian made.<br/>Convenient and affordable. </strong></h1>
					<p className="paragraph-16">Proudly providing Canadians with the <span><strong>lowest price</strong></span> on the market. Delivering Health Canada approved medications from Canadian Certified Doctors, straight to your door discreetly whenever you need it.</p>
					<Link to="/shop" className="button-9 w-button"><strong>View products</strong></Link>
					</div>
					<div className="column-121 w-col w-col-6"><img src={canadian_flag_small} sizes="100vw"
						srcSet={`${canadian_flag_large} 1024w, ${canadian_flag_medium} 767w, ${canadian_flag_small} 479w`} 
						alt="canadian-flag" className="image-22" /></div>
				</div>
				</div>
			</div>
			<div className="section-11">
				<div className="container-4 w-container">
				<h1 className="heading-53"><strong>Treatment plans catered to you!</strong><br/></h1>
				<div className="columns-25 w-row">
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={quality_medi}  width="44" alt="quality" className="image-18" />
						<p className="paragraph-6">Quality Medications</p>
					</div>
					</div>
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={quickEasyProcess}  width="41" alt="clinical" />
						<h1 className="heading-44">No Clinical Visits</h1>
					</div>
					</div>
					<div className="column-49 w-col w-col-4">
					<div className="div-block-49"><img src={secureAndPrivate}  width="41" alt="secure" />
						<h1 className="heading-44">100% Secure and Private</h1>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="section-7">
				<div className="container-5 w-container">
				<h1 className="heading-39"><strong>Frequently Asked Questions</strong><br/></h1>
				<div className="div-block-46 imgBlock" onClick={() => setOpen(!open)}  aria-expanded={open}>
					<h1 className="heading-34">How long does shipping take?<br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon" />
				</div>
				<Collapse in={open}>
				<div>
					<p className="paragraph-17">Once a prescription has been issued by the doctor, orders typically arrive within 2-3 days.<br/></p>
				</div>
				</Collapse>
				
				<div className="div-block-34 imgBlock" onClick={() => setOpen1(!open1)}  aria-expanded={open1}>
					<h1 className="heading-37 ">Can I just make a one time purchase?<br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon" />
				</div>
				<Collapse in={open1}>
				<div className="div-block-31">
					<p className="paragraph-18">Yes. Our service is designed to provide automatic refills for your convenience. However, you can login to your account and pause or skip your refills at any time.<br/></p>
				</div>
				</Collapse>
				
				
				<Link to="/faq" className="button-17 w-button"><strong>See more FAQs</strong></Link>
				</div>
			</div>
			<div className="section-2">
				<div className="container-31 w-container">
				<div className="w-row">
					<div className="column-141 w-col w-col-6">
					<h1 className="heading-46"><strong>Not Sure What Product You Need?</strong><br/></h1>
					<p className="paragraph-14">Book a free consultation call with a Canadian doctor to advise you!<br/></p>
					<Link to="/contact-us"
						// target="_blank"
						// rel="noopener noreferrer"
 						className="button-18 w-button"><strong>Let us help you</strong><br/></Link>
					</div>
					<div className="w-col w-col-6"></div>
				</div>
				</div>
			</div>
			<div className="section-17">
				<div className="w-layout-grid grid-9">
				<div className="div-block-83 x f">
					<h1 className="heading-26 f">Incredible operation they&#x27;ve got going here - good product at really good prices.<br/></h1><img src={stars}  width="83" alt="stars" />
					<div className="text-block-18">Winston member, Feb 2021</div>
				</div>
				<div className="div-block-93">
					<div className="div-block-94"><img src={googleLogo}  width="195" alt="google" /></div>
					<span className="link-16">Reviews</span>
					<div className="div-block-92">
					<div className="text-block-21">5.0</div><img src={stars}  alt="stars" />
					</div>
				</div>
				<div className="div-block-83 x b">
					<h1 className="heading-26 b">Easy process to follow and priced much better than what I’ve seen elsewhere. Great job!!<br/></h1><img src={stars}  width="83" alt="stars" />
					<div className="text-block-20">Winston member, Jan 2021</div>
				</div>
				<div className="div-block-83 s">
					<h1 className="heading-26 d">Great online experience! The website is easy to navigate and received tremendous service from the health professionals. Products arrived promptly and in discreet packaging. Will definitely purchase again!<br/></h1><img src={stars}  width="83" alt="stars" />
					<div className="text-block-17">Winston member, Nov 2020</div>
				</div>
				<div className="div-block-83 x g">
					<h1 className="heading-26 v">After researching and comparing other websites and readings reviews I went with 
					<a target="_blank" rel="noopener noreferrer" href="http://askwinston.ca/">askwinston.ca</a> and I&#x27;m glad I did. I had a great experience with the doctor and the platform was easy to use. Highly recommended.<br/></h1><img src={stars}  width="83" alt="stars" />
					<div className="text-block-19">Winston member, Dec 2020</div>
				</div>
				<div className="div-block-83 x f dd">
					<h1 className="heading-26 f gg">Happy this service/product finally made it to Canada. Product is as advertised. Professional buying experience. Look forward to buying from them again.<br/></h1><img src={stars}  width="83" alt="stars" />
					<div className="text-block-18 ss">Winston member, Feb 2021</div>
				</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default Face;
