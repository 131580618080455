import React, {useState} from "react";
import {Link , useHistory,useLocation} from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Title } from "react-meta-elements";
import stars from "../../static/images/stars.png";
import arrow_icon from "../../static/images/arrow-icon.png";
import insurance from "../../static/images/insurance.png";
import virtual from "../../static/images/free-virtual.png";
import canadian from "../../static/images/Canadian-produced-products-icon.png";
import handWave_small from "../../static/images/hand-waveing-small.png";
import handWave_large from "../../static/images/hand-waveing-large.png";
import choose_product from "../../static/images/choose-product.jpg";
import connect_doctor from "../../static/images/connect-doctor.jpg";
import take_control from "../../static/images/take-control.jpg";
import licenced_doctor from "../../static/images/licenced_doctor.png";
import refill from "../../static/images/refill.png";
import standard_shipping from "../../static/images/standard_shipping.png";
import whiteDoctor from "../../static/images/white-doctor-circle.png";
import close from "../../static/images/close.png";
//acne images
import pores from "../../static/images/pores.png";
import bacteria from "../../static/images/bacteria.png";
import hormones from "../../static/images/hormones.png";
import medications from "../../static/images/medication.png";
import cosmetics from "../../static/images/cosmetics.png";
import oil from "../../static/images/oil.png";
import sweating from "../../static/images/sweating.png";
import stressIcon from "../../static/images/stress-icon.png";
import diet1 from "../../static/images/diet.png";
import facne from "../../static/images/facne-blog.jpg";
import {useDispatch, useSelector} from "react-redux";
import {StateType} from "../../redux/types";
import { actions as cartActions } from "../../redux/cart";
import {actions as appActions} from "../../redux/app";
import Product from "../../model/Product";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import {getProductImage, formatProductNameToURL} from "../../utils/helpers";


function ProductACNE() {
	const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
    const location = useLocation();

	const handleAddProductToCart = (product: Product) => () => {
		const y = window.scrollY;
		const {pathname} = location;

		dispatch(appActions.setCloseSidebarDirection({pathname, y}));

		history.push("/cart");

		dispatch(cartActions.addProductRequest({product}));
	};

	const { products } = useSelector(({ product, app }: StateType) => ({
		products: product.productItems,
		// shopScrollTarget: app.shopScrollTarget
	}));

	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	
	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);
	

	const [ingredient, setIngredient] = useState<string>("");
	const handleShow1 = (text:string) => {
		setShow1(true)
		setIngredient(text);
	}		
	const [safeInfo, setSafeInfo] = useState<string>("");
	const handleShow2 = (text:string) => {
		setShow2(true)
		setSafeInfo(text);
	}

	// Getting utm source and medium from query params
	let search = window.location.search;
	if(search!=""){
		window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		console.log(search.split('?')[1]);
	}
	
    return(
        <>
				<Title>Acne treatment  |Winston</Title>
				<div id="factPageACNE">
			<div className="page-101">

			{/* modal starts here  */}
			
			{/* modal ends here */}

			<div className="section">
				<div className="container-12 w-container">
				<h1 className="heading">Skincare is healthcare<br/></h1>
				<div className="w-row">
					<div className="column-109 w-col w-col-8">
					<p className="paragraph">Acne Vulgaris, popularly called acne, is one of the most common skin conditions affecting men. Get your skin back with personalized and safe acne medication.<br/></p>
					</div>
					<div className="w-col w-col-4"></div>
				</div>
				<div className="w-row">
					<div className="column-115 w-col w-col-8">
					<div className="columns w-row">
						<div className="column w-col w-col-4"><img src={insurance}  width="40" alt="insurance" className="image-21" />
						<h1 className="heading-3"><strong>Insurance<br/>accepted</strong><br/></h1>
						</div>
						<div className="column w-col w-col-4"><img src={virtual}  width="40" alt="virtual" className="image-24" />
						<h1 className="heading-3"><strong>Free virtual <br/>consultation</strong><br/></h1>
						</div>
						<div className="column w-col w-col-4"><img src={canadian} width="40" alt="canadian" className="image-24" />
						<h1 className="heading-3"><strong>Canadian produced<br/>medications</strong><br/></h1>
						</div>
					</div>
					</div>
					<div className="w-col w-col-4"></div>
				</div>
				</div>
			</div>
			<div className="section-11">
				<div className="container-11 w-container">
				<h1 className="heading-5">About Our Acne Medications<br/></h1>
				<p className="paragraph-14">We prescribe two treatment options proven to effectively treat acne - Tretinoin 0.025% and Clindamycin + Benzoyl Peroxide. Our acne medications work by either reducing sebum (oil) production or by treating bacterial infections. Both treatment options are usually prescribed to treat mild to moderate acne. These medications are gentle on your skin and, when used as prescribed, have little to no side effects.<br/></p>
				</div>
			</div>
			{
       products.filter(product=> product.problemCategory.includes('AC')).map(productitems=>
        (
			<section id="1st-section" className="section-1" key={productitems.id}>
				<div className="container-12 w-container">
				<div className="w-row">
					<div className="column-109 w-col w-col-6"><img src={getProductImage(productitems.name)} width="394"  alt="product" className="image-3" /></div>
					<div className="column-109 w-col w-col-6">
					<h1 className="heading-14">{productitems.name}<br/></h1>
					<h1 className="heading-11"><strong>{productitems.subName}</strong><br/></h1>
					<div className="paragraph-14 m-30px-b" dangerouslySetInnerHTML={{__html:productitems.landingPageText}} />
					<div className="w-row">
						<div className="w-col w-col-6">
						<span  onClick={()=>handleShow1(productitems.ingredient)} className="button-14 w-button"><strong className="bold-text">Ingredients</strong></span>
						<Modal show={show1} onHide={handleClose1} animation={false} id="factPageACNE">
			               <div className="div-block-55">
			             	 <div className="div-block-56">
			                 	<div className="div-block-45" onClick={handleClose1}>
									<img src={close}  width="25" alt="close" />
								</div>
								<h1 className="heading-96">Ingredients</h1>
				                <div dangerouslySetInnerHTML={{__html:ingredient}}/>
				             </div>
		                	</div>
			            </Modal>
						</div>
						<div className="w-col w-col-6">
						<span  onClick={()=>handleShow2(productitems.safetyInfo)} className="button-8 w-button"><strong>Safety Info</strong></span>
						<Modal show={show2} onHide={handleClose2} animation={false} id="factPageACNE">
		                	<div className="div-block-43">
				              <div className="div-block-44">
				                <div className="div-block-45" onClick={handleClose2}><img src={close}  width="25" alt="close" /></div>
								<h1 className="heading-60"><strong>Safety information details</strong><br/></h1>
				                <div dangerouslySetInnerHTML={{__html:safeInfo}}/>
				              </div>
			                </div>	
			            </Modal>
						</div>
					</div>
					<Link to={`/${formatProductNameToURL(productitems.name)}`} className="button-15 w-button" >
                        <span className="">Learn more</span>
                    </Link>
					<div className="div-block-19">
						<h1 className="heading-12">Monthly supply starting at  ${Number(productitems.startingAtPrice).toFixed() || 0}<br/></h1>
						<div className="text-block-7">Auto-refills that can be paused, skipped or cancelled anytime.</div>
						<span onClick={handleAddProductToCart(productitems)} className="button-9 w-button"><strong>Add to cart</strong></span>
					</div>
					</div>
				</div>
				</div>
			</section>
		)
	   )
		}
			
			<div className="section-2 canada">
				<div className="container-12 w-container">
				<div className="w-row">
					<div className="w-col w-col-9">
					<h1 className="heading-6">Our treatments are proudly sourced from trusted Canadian manufacturers.<br/></h1>
					
					<h1 className="heading-8"><strong>TARO</strong><br/></h1>
					</div>
					<div className="column-118 w-col w-col-3"><img className="image-25" src={handWave_small} sizes="100vw"
						srcSet={`${handWave_large} 1024w, ${handWave_large} 767w, ${handWave_small} 479w`} width="86"  alt="hand" /></div>
				</div>
				</div>
			</div>
			<div className="section-12">
				<div className="container-8 w-container">
				<h1 className="heading-75">What Causes Acne?<br/></h1>
				<p className="paragraph-8">The most common risk factors include:<br/></p>
				<div className="columns-60 w-row">
					<div className="column w-col w-col-6"><img src={stressIcon}  width="40" alt="stress" />
					<h1 className="heading-74">Stress</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={pores}  width="40" alt="pores" />
					<h1 className="heading-74">Clogged pores</h1>
					</div>
				</div>
				<div className="columns-64 w-row">
					<div className="column-107 w-col w-col-6"><img src={bacteria}  width="40" alt="bacteria" />
					<h1 className="heading-74">Bacteria that lives on your skin<br/></h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={hormones}  width="40" alt="hormones" />
					<h1 className="heading-74">Hormones<br/></h1>
					</div>
				</div>
				<div className="columns-64 w-row">
					<div className="column-103 w-col w-col-6"><img src={diet1}  width="40" alt="diet" />
					<h1 className="heading-74">Diet</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={medications}  width="40" alt="medications" />
					<h1 className="heading-74">Medications</h1>
					</div>
				</div>
				<div className="columns-64 w-row">
					<div className="column-107 w-col w-col-6"><img src={cosmetics} width="40" alt="cosmetics" />
					<h1 className="heading-74">Cosmetics</h1>
					</div>
					<div className="column-107 w-col w-col-6"><img src={oil}  width="40" alt="oil" />
					<h1 className="heading-74">Excessive sweating<br/></h1>
					</div>
				</div>
				<div className="columns-64 w-row">
					<div className="column-107 w-col w-col-6"><img src={sweating}  width="40" alt="sweating" />
					<h1 className="heading-74">Excess oil production<br/></h1>
					</div>
					<div className="column-107 w-col w-col-6"></div>
				</div>
				</div>
			</div>
			<div className="section-5">
				<div className="container-3 w-container">
				<h1 className="heading-75">How Winston Works<br/></h1>
				<p className="paragraph-13">Our goal is to make healthcare accessible and medications affordable in 3 easy steps. <br/></p>
				<div className="w-row">
					<div className="column-51 w-col w-col-4">
					<div className="div-block-59"><img src={choose_product}  alt="product" />
						<h1 className="heading-95">1. Choose a product<br/></h1>
						<p className="paragraph-12">Pick from our collection of safe and effective medication based on your symptoms and health needs.</p>
					</div>
					</div>
					<div className="column-51 w-col w-col-4">
					<div className="div-block-25"><img src={connect_doctor} alt="doctor" className="image-16" />
						<h1 className="heading-55">2. Connect with a doctor<br/></h1>
						<p className="paragraph-11">A licensed doctor will speak to you about your medical history and symptoms. If appropriate, the doctor will prescribe your medication.<br/></p>
					</div>
					</div>
					<div className="column-109 w-col w-col-4">
					<div className="div-block-26"><img src={take_control}  alt="control" />
						<h1 className="heading-55">3. Take control<br/></h1>
						<p className="paragraph-9">You will discreetly receive your prescription right to your desired address. Follow the dosing instructions and take back control.<br/></p>
					</div>
					</div>
				</div>
				</div>
			</div>
			<div className="section-6">
				<div className="container-4 w-container">
				<h1 className="heading-6">With Winston’s Auto-Refill You Get:<br/></h1>
				<h1 className="heading-45 mob-hide">Let Winston be your wingman and he’ll always deliver!</h1>
				<div className="columns-25 w-row">
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={licenced_doctor}  alt="doctor" className="image-18" />
						<p className="paragraph-6">Custom treatment plan by licensed doctors</p>
					</div>
					</div>
					<div className="column-48 w-col w-col-4">
					<div className="div-block-38"><img src={refill}  alt="refill" />
						<h1 className="heading-44">Skip, pause or cancel your refills at any time</h1>
					</div>
					</div>
					<div className="column-49 w-col w-col-4">
					<div className="div-block-40"><img src={standard_shipping}  alt="shipping" />
						<h1 className="heading-44">Free standard shipping right to your door</h1>
					</div>
					</div>
				</div>
				<h1 className="heading-45 mob-show">Let Winston be your wingman and he’ll always deliver!</h1>
				</div>
			</div>

			{/* slider starts her  */}

			<div className="slider w-slider">
			<Carousel>
					<Carousel.Item interval={200}>
						
						<Carousel.Caption>
							<div className="columns-65 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-14 w-col w-col-8">
								<h1 className="heading-26">Amazing concept and super easy process. End to end!<br/></h1><img src={stars}  alt="stars" className="image-14" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={4000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Dr. very friendly, answered all my concerns.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, In Consultation, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={6000}>
						<Carousel.Caption>
							<div className="w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">Works well and is more classy and discreet compared to the blue pill.<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, on Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={8000}>
						<Carousel.Caption>
							<div className="columns-2 w-row">
							<div className="w-col w-col-2"></div>
							<div className="column-16 w-col w-col-8">
								<h1 className="heading-26">I’m so happy with the results, the doctor and the convenience!<br/></h1><img src={stars}  alt="stars" />
								<div className="text-block">- Winston member, On Google, November 18, 2020</div>
							</div>
							<div className="w-col w-col-2"></div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>

					</Carousel>
			</div>

			{/* slider ends here */}
			
			<div className="section-6">
				<div className="container-5 w-container">
				<h1 className="heading-39">Common Questions about Acne<br/></h1>
				<div onClick={() => setOpen(!open)}  aria-expanded={open} className="div-block-46 imgBlock">
					<h1 className="heading-34"><strong>What is acne?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35"  alt="arroe-icon"/>
				</div>
				<Collapse in={open}>
				<div>
					<p className="paragraph-13">Acne is the general term used to describe the formation of pimples, blackheads, and whiteheads when the pores in the skin get clogged by dead skin cells, oil, and debris. Refer to Acne 101 to learn more about acne.<br/></p>
				</div>
				</Collapse>

				<div onClick={() => setOpen1(!open1)}  aria-expanded={open1} className="div-block-34 imgBlock">
					<h1 className="heading-37"><strong>How long do acne treatments typically take to work?</strong><br/></h1><img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon"/>
				</div>			
				<Collapse in={open1}>
				<div  className="div-block-31">
					<p className="paragraph-14">The duration of your treatment depends on the product you choose and your skin type. Treatments can take effect as early as 2 weeks and as long as 6 weeks. Consult with one of our doctors to determine the best product for you.<br/></p>
				</div>
				</Collapse>

				<div onClick={() => setOpen2(!open2)}  aria-expanded={open2} className="div-block-30 imgBlock">
					<h1 className="heading-37"><strong>What treatment options do you currently provide for acne?</strong><br/></h1><img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon"/>
				</div>
				<Collapse in={open2}>
				<div >
					<p className="paragraph-14">We currently offer 2 topical treatments: Clindamycin/Benzoyl Peroxide (the active ingredient in Clindoxyl and Benzaclin) and Tretinoin (the active ingredient in Retin-A).</p>
				</div>
				</Collapse>

				<div onClick={() => setOpen3(!open3)}  aria-expanded={open3}  className="div-block-48 imgBlock">
					<h1 className="heading-37"><strong>How will the doctors be able to determine the best treatment option for me?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon"/>
				</div>
				<Collapse in={open3}>
				<div  className="div-block-31">
					<p className="paragraph-14">Prior to your consultation, you will complete a brief questionnaire, and attach relevant pictures to assist our doctors with making their diagnosis.</p>
					<span className="text-span-2"><strong className="bold-text-2">Please review the drug safety information for the complete list of possible side effects</strong></span>
				</div>
				</Collapse>
				
				<div onClick={() => setOpen4(!open4)}  aria-expanded={open4} className="div-block-48 imgBlock">
					<h1 className="heading-37"><strong>Do I require a prescription for these treatments?</strong><br/></h1>
					<img className="imgRotate" src={arrow_icon}  width="35" alt="arrow-icon"/>
				</div>
				<Collapse in={open4}>
				<div  className="div-block-31">
					<p className="paragraph-14">Yes, our doctors can provide a prescription to one of our accredited pharmacy partners that can allow for quick and discreet delivery.</p>
				</div>
				</Collapse>

				<Link to="/faq"  className="button-17 w-button"><strong>See more FAQs</strong></Link>
				</div>
			</div>

			<div className="section-2">
				<div className="container-15 w-container">
				<div className="columns-5 w-row">
					<div className="column-114 w-col w-col-9">
					<h1 className="heading-46">Not Sure What Product You Need?<br/></h1>
					<p className="paragraph-14">Book a free consultation call with a Canadian doctor to advise you!<br/></p>
					<Link to="/contact-us"
						// target="_blank"
						// rel="noopener noreferrer"
						 className="button-18 w-button" ><strong>Let us help you</strong><br/></Link>
					</div>
					<div className="column-113 w-col w-col-3"><img src={whiteDoctor}  width="212"  alt="white-doctor" className="image-20" /></div>
				</div>
				</div>
			</div>
			<div className="section-10">
				<div className="container-8 w-container">
				<h1 className="heading-52">Blog Articles<br/></h1>
				<div className="columns-66 w-row">
					<div className="column-71 w-col w-col-6">
					<div className="div-block-60 blogBackground" ></div>
					</div>
					<div className="column-109 w-col w-col-6">
					<div className="div-block-17">
						<h1 className="heading-48">Acne 101<br/></h1>
						<p className="paragraph-4">According to the Canadian Dermatology Association, acne affects 5.6 million Canadians, which is about 20% of the population.<br/><br/>Acne Vulgaris, also known as Acne, is one of the most common conditions affecting young adults. It occurs when pores in the skin get clogged with dead skin cells, oil, and debris.<br/></p>
						<div className="div-block-23">
						<a href="https://learn.askwinston.ca/acne-101/" className="button-13 w-button">
                         <strong>Learn more</strong><br/>
                        </a>
						<div className="text-block-6">5 min read</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
			</div>
			</>
    )
}
export default ProductACNE;
