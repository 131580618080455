import React, 
   {
    ChangeEvent,
    forwardRef,
    useRef,
    useState,
	useImperativeHandle,
    Ref} from "react";
import ValidationService from "../../../service/Validation.service";
import MessageConst from "../../../const/Message.const";
import "./styles.scss";
import { Link,useLocation,useHistory } from "react-router-dom";
import { actions as authActions } from "../../../redux/auth";
import { useDispatch,useSelector } from "react-redux";
import {StateType} from "../../../redux/types"
interface GoogleLinkProps{
    handleLoginModeClick: () => void;
}
export interface GoogleLinkRefObject {
	doReLogin: () => Promise<void>;
}


const GoogleLink = forwardRef((props:GoogleLinkProps, ref: Ref<GoogleLinkRefObject>)=>{
    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const {profileget} = useSelector(
		({ auth }: StateType) => ({
            profileget:auth.profileget
		})
	);
    const passwordInput = useRef<HTMLInputElement>(null);
    const isLinkToGoogle = Boolean(true)
    // const isLoginMode = useRef <boolean>(true)
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [password, setPassword] = useState<string>("");

     const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setPassword(event.target.value);
	};
    const email = profileget?.email
    useImperativeHandle(ref, () => ({
		
		doReLogin
	}));

    const doReLogin = async (): Promise<void> => {   
        sessionStorage.setItem("emtyPopup", "open");
        if (!ValidationService.validatePassword(password)) {
			if (passwordInput.current) {
				passwordInput.current.focus();
			}
			setValidationMessage(MessageConst.validation.passwordError);
			return;
		}
        let utmSource = window.sessionStorage.getItem("utm_source");
       
        let callback = null;
		if(sessionStorage.getItem("checkout") != "false"){
			callback = () => {
				history.push("/account");				
			}			
		}
        dispatch(
			authActions.loginRequest({
				email,
				password,
				setValidationMessage,
				history,
                isLinkToGoogle,
                utmSource,
                isLoginMode: pathname === "/google-link",
                callback
			})
		);

    }
   

    return(
        <div id="re-login">
            
            <div className="section">
							
                <div className="w-container">
               
                    <div className="w-row">
                        <div className="w-col w-col-3"></div>
                            <div className="column-69 w-col w-col-6">
                            <Link to="/login" className="link-1">
								<strong>← Return To Login</strong>
							</Link>
                                <h1 className="heading-1">Link an existing account</h1>	
                                <h2 className="heading-2">Welcome</h2>
                                <h3 className="mail-text">{profileget?.email}</h3>
                                <form>
                                <Link to="/login" className="link-1">Change</Link>

                               
                                    <div className="account-form-block">
                                        <p className="account-info-text">An Askwinston account with this email already exists. Please enter your askwinston password to link it to your Google account.</p>

                                        <input
                                           type="password"
                                           className="text-input w-input"
                                           placeholder="winston password"
                                           ref={passwordInput}
                                           value={password}
								    	   onChange={handlePasswordChange}
										//    onKeyPress={handleKeyPress}
                                            />
                                    </div>
                                    <div className={validationMessage?"w-form-fail-show":"w-form-fail"}>
                                    	<div dangerouslySetInnerHTML={{ __html: validationMessage }}></div>
                                     </div>	 
                                    <div className="button-col">
                                        <span onClick={doReLogin}  className="button-1 w-button"><strong>Link your accounts</strong></span>
                                    </div>
                                    <div className="other-link-col">
                                    <Link to="/forgot-password" className="link-1">Reset your password</Link>
                                    </div>
                                    </form>
                               
                            </div>
                        <div className="w-col w-col-3"></div>    
                    </div>
                </div>
            </div>
            {/* </form> */}
        </div>
    )
})
export default GoogleLink;