import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { actions as appActions } from "../../redux/app";
import { StateType } from "../../redux/types";

import transferRxBottles from "../../static/images/transferRxBottles.png";
import transferRxPills from "../../static/images/how-it-works-step1.png";
import transferRxManNotebook from "../../static/images/transferRxManNotebook.png";
import transferRxSubmitPicture from "../../static/images/transferRxSubmitPicture.png";

import "./styles.scss";
import { Meta, Title } from "react-meta-elements";

const TransferRxPage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { profile } = useSelector(({ auth }: StateType) => ({
		profile: auth.profile
	}));

	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
	}, [])
	
	const handleStartTransferClick = () => {
		dispatch(appActions.setProfilePageToOpen("/profile/transfer-rx"));

		if (profile) {
			history.push("/profile/transfer-rx");
		} else {
			const y = window.scrollY;
			const { pathname } = location;

			dispatch(appActions.setCloseSidebarDirection({ pathname, y }));

			history.push("/login");
		}
	};

	return (
		<>
			<Title>
				Winston | transfers prescriptions
				</Title>
			<Meta name="title" content="Winston | transfers prescriptions" />
			<Meta
				name="description"
				content="Transfer prescriptions for common men's treatment options at Winston. Access a pharmacist from anywhere. The best value for men's prescriptions in Canada."
			/>
			<Meta name="googlebot" content="index,follow" />
			<Meta name="robots" content="noodp,noydir" />


			<div className="transfer-rx-component prefiles" id="">
				<div className="start">
					<div className="left-part">
						<div className="title">
							<h1>Transfer a Prescription</h1>
							<div className="horizontal-line"></div>
						</div>
						<div className="content">
							<p>
								Winston accepts transfers of prescriptions for all of our treatment options. Our
								customers benefit from the lowest medication prices on the market, quick and discreet
								delivery, as well as access to doctors, specialists, and pharmacists from the privacy
								of their home.
						</p>
							<p className="padding-top">
								Isn't it time your pharmacy worked for you? Transfer your prescription to Winston and
								let us do the work.
						</p>
							<button onClick={handleStartTransferClick} className="start-transfer-btn">
								Start a Transfer
						</button>
						</div>
					</div>
					<div className="right-part">
						<img src={transferRxBottles} alt="transfers prescriptions -Winston" />
					</div>
				</div>
				<div className="steps">
					<div className="step">
						<div className="left-part">
							<img src={transferRxManNotebook} alt="transfers prescriptions -Winston" />
						</div>
						<div className="right-part">
							<div className="title">
								<div className="number">
									<p>01</p>
								</div>
								<div className="text">
									<h3>Create<br/>
									an Account</h3>
								</div>
							</div>
							<div className="content">
								<p>
									To start a transfer you will need to create a Winston account or login to your
									existing account.
							</p>
							</div>
						</div>
					</div>
					<div className="step-even">
						<div className="left-part">
							<div className="title">
								<div className="number">
									<p>02</p>
								</div>
								<div className="text">
									<h3>Prescription<br/>
									Details</h3>
								</div>
							</div>
							<div className="content">
								<p>Next, provide your current pharmacy and prescription details.</p>
							</div>
						</div>
						<div className="right-part">
							<img src={transferRxPills} alt="transfers prescriptions -Winston" />
						</div>
					</div>
					<div className="step">
						<div className="left-part">
							<img src={transferRxSubmitPicture} alt="transfers prescriptions -Winston" />
						</div>
						<div className="right-part">
							<div className="title">
								<div className="number">
									<p>03</p>
								</div>
								<div className="text">
									<h3>Hit<br/>
									Submit!</h3>
								</div>
							</div>
							<div className="content">
								<p>Submit your transfer request and Winston will take care of the rest.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="start-transfer-section">
					<h2 className="title">Start transferring a prescription. </h2>
					<button onClick={handleStartTransferClick}>Start a Transfer</button>
				</div>
			</div>
			
			
			</>
	);
};

export default TransferRxPage;
