import React, {useEffect, useState} from "react";
import { Title } from "react-meta-elements";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {SlideDown} from "react-slidedown";

import {StateType} from "../../redux/types";

import minusSvg from "../../static/images/minus.svg";
import plusSvg from "../../static/images/plus.svg";

import "./styles.scss";

type AccordionItemType = {
	header: string;
	content: JSX.Element;
	isShown: boolean;
};

const FAQ = () => {
	const {profile} = useSelector(({auth}: StateType) => ({
		profile: auth.profile
	}));
	
	useEffect(()=>{
		let search = window.location.search;
		if(search!=""){
			window.sessionStorage.setItem("utm_source", search.split('?')[1]);
		}
	}, [])

	const [accordionItems, setAccordionItems] = useState<AccordionItemType[]>([
		{
			header: "How does Winston work?",
			content: (
				<p>
					Winston is a discreet men’s health telemedicine platform that prescribes Health Canada
					approved medication for some of the most common issues impacting men today and
					conveniently delivers it directly to your home. <Link to="/how-it-works">Click here</Link>{" "}
					to visit our How It Works section.
				</p>
			),
			isShown: false
		},
		{
			header: "Do I need a prescription to use Winston?",
			content: (
				<p>
					Absolutely not. Winston is your one stop shop. Our Canadian board certified doctors will
					write you a prescription so you can get the medication you need, when you need it.
				</p>
			),
			isShown: false
		},
		{
			header: "Is the doctor visit covered by my provincial health plan?",
			content: (
				<p>
					For our Ontario customers, your doctor visit is covered by the Ontario Health Insurance
					Plan (OHIP). For all other provinces, your doctor visit is free with every purchase.
				</p>
			),
			isShown: false
		},
		{
			header: "Is my prescription covered by my insurance?",
			content: (
				<>
					<p>
						Insurance coverage is based on your insurance policy and is dependent on your selected
						medication. We recommend that you upload your insurance card during registration so that
						our Pharmacists can confirm your coverage for the prescribed medication.
					</p>
					<p>
						Alternatively, you can wait until your order is received and submit your receipt to your
						insurance provider for reimbursement.
					</p>
				</>
			),
			isShown: false
		},
		{
			header: "Do I have to pay for shipping?",
			content: <p>No. Shipping is free with all Winston orders.</p>,
			isShown: false
		},
		{
			header: "Is this legal?",
			content: (
				<p>
					Absolutely. Telemedicine is one of the fastest growing forms of healthcare in Canada. Your
					prescription will be issued by a Canadian licensed physician and filled by an accredited
					Canadian pharmacy. Our platform is in compliance with all regulatory bodies and security
					by-laws, keeping all of your personal and medical information secure. To learn more about
					Winston, please <Link to="/about-us">click here</Link> to visit our About Us section.
				</p>
			),
			isShown: false
		},
		{
			header: "Can I speak to a doctor?",
			content: (
				<p>
					Yes. You can speak to a doctor prior to choosing a medication, if you need advice on what
					medication to choose. Click on any “Start a Free Consultation” or “Talk to a Doctor”
					button to start a consultation. Alternatively, after you select the medication that is
					right for you, you will be prompted to schedule a consultation with one of our doctors.
					The discreet consultation provides you with the opportunity to comfortably ask any
					questions that you might have.
				</p>
			),
			isShown: false
		},
		{
			header: "Where is my order?",
			content: (
				<>
					<p>
						The most common reason why your order might be delayed is because the prescription drug
						consultation with the pharmacist was not completed. In order for your medication to
						ship, you must either provide consent to waive the pharmacy consultation, which would
						have been included in the welcome email, or you must complete the consultation with the
						pharmacist.
					</p>
					<p>
						If your product(s) has shipped, you can use the shipping tracking feature in your
						account to locate your package.
					</p>
				</>
			),
			isShown: false
		},
		{
			header: "How do I contact you?",
			content: (
				<p>
					You can contact us at <a href="mailto:WeListen@askwinston.ca">WeListen@askwinston.ca</a>.
					If you are contacting us about an existing order, please remember to include your order
					number.
				</p>
			),
			isShown: false
		},
		{
			header: "Which pharmacy is my prescription coming from?",
			content: (
				<p>
					All Winston orders are dispensed by our network of approved and accredited Canadian
					pharmacies.
				</p>
			),
			isShown: false
		},
		{
			header: "What if I have a question about my medication?",
			content: (
				<div>
					<p>
						If you have any questions about your medication, please contact our pharmacy partner.
					</p>
					<div>
						<div className="faq-contact-item">
							<span>Medione Dundas427 Medical Pharmacy Inc.</span>
							<span>
								Email: <a href="mailto:pharmacy@askwinston.ca">pharmacy@askwinston.ca</a>
							</span>
							<span>
								Toll Free Phone: <a href="tel:1(888)404-4002">1 (888) 404-4002</a>
							</span>
							<span>Hours: Monday – Friday: 9 am – 6 pm & Saturday: 9 am – 3 pm EST</span>
							<span>Ontario College of Pharmacists Certificate of Accreditation No. 304299</span>
						</div>
					</div>
				</div>
			),
			isShown: false
		},
		{
			header: "How do I skip or cancel auto refill on my prescription?",
			content: (
				<>
					<p>
						Any changes to your auto refill must be submitted at least 7 days before your next
						refill is due to ship. If changes are submitted 6 days or less of shipment, then the
						changes will not take effect until the following month. To find your refill ship date
						please go to the order section in your account.
					</p>
					<p>
						To skip your auto refill for one month{" "}
						<Link to={profile ? "/profile/order-history" : "/login"}>click here</Link> or to cancel
						email <a href="mailto:WeListen@askwinston.ca">WeListen@askwinston.ca</a>
					</p>
				</>
			),
			isShown: false
		}
	]);

	const handleAccordionClick = (clickedIndex: number): void => {
		const newAccordionItems = [...accordionItems];

		newAccordionItems.forEach((item, itemIndex) => {
			if (itemIndex === clickedIndex) {
				if (item.isShown === false) {
					item.isShown = true;
				} else {
					item.isShown = false;
				}
			} else {
				item.isShown = false;
			}
		});

		setAccordionItems(newAccordionItems);
	};

	return (
		<>
			<Title>
				Winston discreet men’s health telemedicine| FAQ
			</Title>

		<div className="faq-component prefiles">
			<div className="main">
				<div className="title">
					<h1>FAQ's</h1>
				</div>
				<div className="content-box">
					<div className="accordion">
						{accordionItems.map((question, index) => (
							<div key={index}>
								<div
									className={"content-header" + (question.isShown ? " shown" : "")}
									onClick={() => {
										handleAccordionClick(index);
									}}
								>
									<img className="plus-image" src={plusSvg} alt="Winston discreet men’s health telemedicine-FAQ" />
									<img className="minus-image" src={minusSvg} alt="Winston discreet men’s health telemedicine-FAQ" />
									<div className="question">
										<h3>{question.header}</h3>
									</div>
								</div>
								<SlideDown>
									{question.isShown && <div className="content">{question.content}</div>}
								</SlideDown>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default FAQ;
