import React from "react";
import {Router, Route, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";

import Main from "../components/Main";

const history = createBrowserHistory();

export const AppRouter = () => (
	<Router history={history}>
		<Switch>
			<Route component={Main} />
		</Switch>
	</Router>
);

export default AppRouter;
