import React, {useState, ChangeEvent} from "react";
import {useSelector, useDispatch} from "react-redux";

import {actions as patientActions} from "../../../../redux/patient";
import {StateType} from "../../../../redux/types";

import {ServedProvinceList, ProvinceName} from "../../../../model/Province";

import ValidationService from "../../../../service/Validation.service";

import MessageConst from "../../../../const/Message.const";

import CustomSelect from "../../../CustomSelect";
import CustomInput from "../../../CustomInput";
import LoaderComponent from "../../../Loader";
import CustomCheckbox from "../../../CustomCheckbox";

import "./styles.scss";

interface AddShippingAddressProps {
	setNavVisibility?: (b: boolean) => void;
	setValidationMessage?: (s: string) => void;
}

type ProvinceOption = {
	label: string;
	value: string;
};

const provinceOptions: ProvinceOption[] = ServedProvinceList.map((province) => ({
	label: ProvinceName[province],
	value: province
}));

const AddShippingAddress = ({setNavVisibility, setValidationMessage}: AddShippingAddressProps) => {
	const dispatch = useDispatch();
	const {loading, profile} = useSelector(({patient, auth}: StateType) => ({
		loading: patient.updatingProfile,
		profile: auth.profile
	}));

	const [addAddressMode, setAddAddressMode] = useState<boolean>(false);

	const [newAddressStreet, setNewAddressStreet] = useState<string>("");
	const [newAddressSuite, setNewAddressSuite] = useState<string>("");
	const [newAddressCity, setNewAddressCity] = useState<string>("");
	const [newAddressProvince, setNewAddressProvince] = useState<ProvinceOption>();
	const [newAddressCountry] = useState<string>("Canada");
	const [newAddressPostal, setNewAddressPostal] = useState<string>("");
	const [newAddressToPrimary, setNewAddressToPrimary] = useState<boolean>(
		true
	);

	const [addValidationMessage, setAddValidationMessage] = useState<string>("");

	const handleChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
		const addressPostalCode = ValidationService.postalCodeConverter(e.target.value);

		setNewAddressPostal(addressPostalCode);
	};

	const handleCancelAddAddress = () => {
		setNewAddressStreet("");
		setNewAddressSuite("");
		setNewAddressCity("");
		setNewAddressProvince(undefined);
		setNewAddressPostal("");
		setNewAddressToPrimary(false);
		setAddAddressMode(false);
		setAddValidationMessage("");
		if (setNavVisibility) {
			setNavVisibility(true);
		}
	};

	const submit = async (): Promise<void> => {
		if (!ValidationService.validateRequired(newAddressStreet)) {
			setAddValidationMessage(MessageConst.validation.address.streetError);

			return;
		}

		if (!ValidationService.validateRequired(newAddressCity)) {
			setAddValidationMessage(MessageConst.validation.address.cityError);

			return;
		}

		if (!newAddressProvince?.value) {
			setAddValidationMessage(MessageConst.validation.provinceError);

			return;
		}

		if (!ValidationService.validateRequired(newAddressPostal)) {
			setAddValidationMessage(MessageConst.validation.address.postalCodeError);

			return;
		}

		if (!ValidationService.validatePostalCode(newAddressPostal)) {
			setAddValidationMessage(MessageConst.validation.address.postalCodeError);

			return;
		}

		const newShippingAddress = {
			addressLine1: newAddressStreet,
			addressLine2: newAddressSuite,
			addressCity: newAddressCity,
			addressProvince: newAddressProvince.value,
			addressPostalCode: newAddressPostal,
			addressCountry: newAddressCountry
		};

		dispatch(
			patientActions.addShippingAddressRequest({
				...newShippingAddress,
				setPrimary: newAddressToPrimary,
				mode: "profile"
			})
		);

		setNewAddressStreet("");
		setNewAddressSuite("");
		setNewAddressCity("");
		setNewAddressProvince(undefined);
		setNewAddressPostal("");
		setNewAddressToPrimary(true);
		setAddAddressMode(false);
		setAddValidationMessage("");

		if (setValidationMessage) {
			setValidationMessage("");
		}

		if (setNavVisibility) {
			setNavVisibility(true);
		}
	};

	return (
		<div className="add-shipping-address-block">
			{loading && <LoaderComponent showShadow={true} />}

			{addAddressMode ? (
				<div>
					<div className="add-address-form">
						<div className="street">
							<CustomInput
								label="Street Address"
								type="text"
								placeholder="Enter street"
								value={newAddressStreet}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewAddressStreet(e.target.value)}
							/>
						</div>
						<div className="suite">
							<CustomInput
								label="Suite / Apt"
								type="text"
								placeholder="Enter suite / apt"
								value={newAddressSuite}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewAddressSuite(e.target.value)}
							/>
						</div>
						<div className="city">
							<CustomInput
								label="City"
								type="text"
								placeholder="Enter city"
								value={newAddressCity}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewAddressCity(e.target.value)}
							/>
						</div>
						<div className="province">
							<CustomSelect
								label="Province"
								placeholder="Select province"
								options={provinceOptions}
								value={newAddressProvince}
								onChange={(selected: ProvinceOption) => {
									if (!Array.isArray(selected) && selected) {
										setNewAddressProvince(selected);
									}
								}}
							/>
						</div>
						<div className="country">
							<CustomInput label="Country" type="text" disabled value={newAddressCountry} />
						</div>
						<div className="postal">
							<CustomInput
								label="Postal Code"
								placeholder="Enter postal code"
								type="text"
								value={newAddressPostal}
								onChange={handleChangePostalCode}
							/>
						</div>
					</div>
					{!!profile?.shippingAddresses.length && (
						<div className="is-set-to-primary">
							<CustomCheckbox
								checked={newAddressToPrimary}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setNewAddressToPrimary(e.target.checked)
								}
							/>

							<div className="is-set-to-primary-text"><span>PLEASE NOTE : </span>New address will be set as default primary address</div>
						</div>
					)}
					{addValidationMessage && <div className="validation-error">{addValidationMessage}</div>}
					<div className="action-buttons">
						<button onClick={handleCancelAddAddress}>Cancel</button>
						<button onClick={submit}>Save</button>
					</div>
				</div>
			) : (
				<div
					className="add-address-button"
					onClick={() => {
						if (setNavVisibility) {
							setNavVisibility(false);
						}
						setAddAddressMode(true);
					}}
				>
					<div className="plus">+</div>
					<div className="">Add New Address</div>
				</div>
			)}
		</div>
	);
};

export default AddShippingAddress;
