import {ReactElement} from "react";

import {ActionType} from "../types";
import MessageConst from "../../const/Message.const";
import {ProductCategory} from "../../model/ProductCategory";

export interface AppState {
	loading: boolean;
	infoStrip: ReactElement;
	shopScrollTarget: ProductCategory | null;
	isSubscribed: string | null;
	profilePageToOpen: string | null;
	closeSidebarDirection: {pathname: string, y: number} | null;
	isCheckout:boolean;
}

const initialState: AppState = {
	loading: true,
	infoStrip: MessageConst.infoStrip,
	shopScrollTarget: null,
	isSubscribed: localStorage.getItem("isSubscribed"),
	profilePageToOpen: null,
	closeSidebarDirection: null,
	isCheckout: true
};

export const setLoading = (state: AppState, {payload}: ActionType) => ({
	...state,
	loading: payload
});

export const showPopup = (state: AppState, {payload}: ActionType) => ({
	...state,
	loading: payload
});

export const setIsSubscribed = (state: AppState, {payload}: ActionType) => {
	localStorage.setItem("isSubscribed", payload);

	return {
		...state,
		isSubscribed: payload
	};
};

export const setShopScrollTarget = (state: AppState, {payload}: ActionType) => ({
	...state,
	shopScrollTarget: payload
});

export const setProfilePageToOpen = (state: AppState, {payload}: ActionType) => {
	return {
		...state,
		profilePageToOpen: payload
	};
};
export const setCloseSidebarDirection = (state: AppState, {payload}: ActionType) => {
	return {
		...state,
		closeSidebarDirection: payload
	};
};

export const setIsCheckout = (state: AppState, {payload}: ActionType) => {
	return {
		...state,
		isCheckout: payload
	};
};

export default initialState;
