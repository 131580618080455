import {ActionType} from "../types";

import ShippingAddress from "../../model/ShippingAddress";
import BillingCard from "../../model/BillingCard";
import PromoCode from "../../model/PromoCode";

export interface PatientState {
	selectedShippingAddress: ShippingAddress | null;
	selectedBillingCard: BillingCard | null;
	selectedPromoCode: PromoCode | null;
	lastUsedShippingAddress: ShippingAddress | null;
	lastUsedBillingCard: BillingCard | null;
	lastUsedPromoCode: PromoCode | null;
	addingShippingAddress: boolean;
	addingBillingCard: boolean;
	verifyingPromoCode: boolean;
	updatingProfile: boolean;
}

const initialState: PatientState = {
	selectedShippingAddress: null,
	selectedBillingCard: null,
	selectedPromoCode: null,
	lastUsedShippingAddress: null,
	lastUsedBillingCard: null,
	lastUsedPromoCode: null,
	addingShippingAddress: false,
	addingBillingCard: false,
	verifyingPromoCode: false,
	updatingProfile: false
};

export const addShippingAddressRequest = (state: PatientState) => ({
	...state,
	addingShippingAddress: true
});

export const addShippingAddressSuccess = (state: PatientState) => ({
	...state,
	addingShippingAddress: false
});

export const addShippingAddressFailure = (state: PatientState) => ({
	...state,
	addingShippingAddress: false
});

export const addBillingCardRequest = (state: PatientState) => ({
	...state,
	addingBillingCard: true
});

export const addBillingCardSuccess = (state: PatientState) => ({
	...state,
	addingBillingCard: false
});

export const addBillingCardFailure = (state: PatientState) => ({
	...state,
	addingBillingCard: false
});

export const setPrimaryShippingAddressRequest = (state: PatientState) => ({
	...state
});

export const setPrimaryShippingAddressSuccess = (state: PatientState) => ({
	...state
});

export const setPrimaryShippingAddressFailure = (state: PatientState) => ({
	...state
});

export const setPrimaryBillingCardRequest = (state: PatientState) => ({
	...state
});

export const setPrimaryBillingCardSuccess = (state: PatientState) => ({
	...state
});

export const setPrimaryBillingCardFailure = (state: PatientState) => ({
	...state
});

export const verifyPromoCodeRequest = (state: PatientState) => ({
	...state,
	verifyingPromoCode: true
});

export const verifyPromoCodeSuccess = (state: PatientState, {payload}: ActionType) => ({
	...state,
	verifyingPromoCode: false,
	selectedPromoCode: payload
});

export const verifyPromoCodeFailure = (state: PatientState) => ({
	...state,
	verifyingPromoCode: false,
	selectedPromoCode: null
});

export const updateProfileRequest = (state: PatientState) => ({
	...state,
	updatingProfile: true
});

export const updateProfileSuccess = (state: PatientState) => ({
	...state,
	updatingProfile: false
});

export const updateProfileFailure = (state: PatientState) => ({
	...state,
	updatingProfile: false
});

export const uploadIdRequest = (state: PatientState) => ({
	...state,
	updatingProfile: true
});

export const uploadIdSuccess = (state: PatientState) => ({
	...state,
	updatingProfile: false
});

export const uploadIdFailure = (state: PatientState) => ({
	...state,
	updatingProfile: false
});

export const uploadInsuranceRequest = (state: PatientState) => ({
	...state,
	updatingProfile: true
});

export const uploadInsuranceSuccess = (state: PatientState) => ({
	...state,
	updatingProfile: false
});

export const uploadInsuranceFailure = (state: PatientState) => ({
	...state,
	updatingProfile: false
});

export const selectShippingAddress = (state: PatientState, {payload}: ActionType) => ({
	...state,
	selectedShippingAddress: payload,
	lastUsedShippingAddress: null === payload ?
		Object.assign({}, state.selectedShippingAddress)
		: null,
});

export const selectBillingCard = (state: PatientState, {payload}: ActionType) => ({
	...state,
	selectedBillingCard: payload,
	lastUsedBillingCard: null === payload ?
		Object.assign({}, state.selectedBillingCard)
		: null,
});

export const selectPromoCode = (state: PatientState, {payload}: ActionType) => ({
	...state,
	selectedPromoCode: payload,
	lastUsedPromoCode: null === payload ?
		(
			null !== state.selectedPromoCode ?
			Object.assign({}, state.selectedPromoCode) :
			null
		)
		: null,
});

export default initialState;
