import {ActionType} from "../types";
import Product from "../../model/Product";

export interface ProductState {
	loadingProducts: boolean;
	productItems: Product[];
	error: string;
}

const initialState: ProductState = {
	loadingProducts: false,
	productItems: [],
	error: ""
};

export const getProductsRequest = (state: ProductState) => ({
	...state,
	loadingProducts: true
});

export const getProductsSuccess = (state: ProductState, {payload}: ActionType) => ({
	...state,
	loadingProducts: false,
	productItems: payload
});

export const getProductsFailure = (state: ProductState, {payload}: ActionType) => ({
	...state,
	loadingProducts: false,
	error: payload
});

export const changeProductQuantity = (state: ProductState, {payload}: ActionType) => {
	const {product, selectedQuantityIndex} = payload;
	const {productItems} = state;
	const newProductItems = [...productItems];
	const productIndex = newProductItems.findIndex((curProduct) => curProduct.id === product.id);

	if (productIndex !== -1) {
		newProductItems[productIndex].selectedQuantityIndex = selectedQuantityIndex;
	}

	return {
		...state,
		productItems: newProductItems
	};
};

export default initialState;
