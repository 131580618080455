import {combineReducers} from "redux";

import auth from "./auth";
import app from "./app";
import modal from "./modal";
import product from "./product";
import patient from "./patient";
import cart from "./cart";

const rootReducer = combineReducers({
	app,
	modal,
	auth,
	product,
	patient,
	cart
});

export default rootReducer;
