import {handleActions, createActions} from "redux-actions";

import initialState, * as handlers from "./handlers";

export const actions = createActions({
	GET_PROFILE_REQUEST: undefined,
	GET_PROFILE_SUCCESS: undefined,
	GET_PROFILE_FAILURE: undefined,

	LOGIN_REQUEST: undefined,
	LOGIN_SUCCESS: undefined,
	LOGIN_FAILURE: undefined,

	REGISTER_REQUEST: undefined,
	REGISTER_SUCCESS: undefined,
	REGISTER_FAILURE: undefined,

	FORGOT_PASSWORD_REQUEST: undefined,
	FORGOT_PASSWORD_SUCCESS: undefined,
	FORGOT_PASSWORD_FAILURE: undefined,

	UPDATE_PROFILE: undefined,

	LOGOUT: undefined,

	REMOVE_PROFILE: undefined,

	SET_TOKEN: undefined,
	REMOVE_TOKEN: undefined
});

const reducer = handleActions(
	{
		[`${actions.getProfileRequest}`]: handlers.getProfileRequest,
		[`${actions.getProfileSuccess}`]: handlers.getProfileSuccess,
		[`${actions.getProfileFailure}`]: handlers.getProfileFailure,

		[`${actions.loginRequest}`]: handlers.loginRequest,
		[`${actions.loginSuccess}`]: handlers.loginSuccess,
		[`${actions.loginFailure}`]: handlers.loginFailure,

		[`${actions.registerRequest}`]: handlers.registerRequest,
		[`${actions.registerSuccess}`]: handlers.registerSuccess,
		[`${actions.registerFailure}`]: handlers.registerFailure,

		[`${actions.forgotPasswordRequest}`]: handlers.forgotPasswordRequest,
		[`${actions.forgotPasswordSuccess}`]: handlers.forgotPasswordSuccess,
		[`${actions.forgotPasswordFailure}`]: handlers.forgotPasswordFailure,

		[`${actions.updateProfile}`]: handlers.updateProfile,

		[`${actions.logout}`]: handlers.logout,

		[`${actions.removeProfile}`]: handlers.removeProfile,

		[`${actions.setToken}`]: handlers.setToken,
		[`${actions.removeToken}`]: handlers.removeToken
	},
	initialState
);
export default reducer;
